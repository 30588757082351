import { Widgets } from "@mui/icons-material";

import LoginCallbackScreen from "../../@modules/auth/LoginCallbackScreen";
import LoginLanding from "../../@modules/auth/LoginLanding";
import LoginScreen from "../../@modules/auth/LoginScreen";
import i18n from "../../i18n/config";

const t = i18n.t.bind(i18n);

/**
 * path: Last subpath (URL = <layout> + <path>)
 * name: Display name for route
 * mini: Mini display name for route
 * icon: Display icon for route
 * component: Which component to render on route
 * layout: Which layout the route should be rendered in
 * requireAuth: Specifies whether the route should require the user to be authenticated
 * userRoute: A function which takes user as an argument and returns a route based on the user. Will be set as an array of views descending from the route.
 *
 */

// The routes
const CustomRoutes = [
  {
    path: "/login",
    name: t("Login"),
    icon: Widgets,
    slug: "login",
    mini: "LI",
    component: LoginLanding,
    layout: "/auth",
  },
  {
    path: "/tenants",
    name: t("Tenants"),
    icon: Widgets,
    slug: "tenants",
    mini: "LI",
    component: LoginScreen,
    layout: "/auth",
  },
  {
    path: "/callback",
    name: t("Login"),
    icon: Widgets,
    slug: "login",
    mini: "LI",
    component: LoginCallbackScreen,
    layout: "/auth",
  },
];

export default CustomRoutes;
// AppLocalisation
