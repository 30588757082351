import { Grid, Stack } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import AutoSuggestFormDropDown from "components/AutoSuggestSelect/AutoSuggestFormDropDown";
import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import OnOffFormSwitch from "components/OnOffSwitch/OnOffFormSwitch";
import SMFormDiscreteSlider from "components/SMFormComponents/SMFormDiscreteSlider";
import SMFormTimePicker from "components/SMFormComponents/SMFormTimePicker";
import { IDevice } from "interfaces/device.interface";
import { patchDevice } from "store/actions/administration/deviceActions";
import {
  ButtonAsLabelStyledWithBorder,
  ShortSpace,
  SubTitleStyled,
  TextStyled,
} from "styled/CommonStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import TIMEZONES from "utils/timezones";

function DeviceSettingsComponent({ device }: { device: IDevice }) {
  const dispatch = useDispatch();

  const [showTimePicker, setShowTimePicker] = useState<boolean>(
    device?.settings?.user?.schedule[0]?.enabled || false,
  );
  const [toggle, setToggle] = useState<{ [key: string]: boolean }>({});
  const { t, i18n } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    // getValues,
    reset,
  } = useForm<IDevice>({
    defaultValues: {
      ...device,
    },
    reValidateMode: device.id ? "onChange" : "onSubmit",
    mode: device.id ? "onChange" : "onSubmit",
  });

  // updated device change
  useEffect(() => {
    reset({ ...device });
  }, [device, reset]);

  const updateForm = (data: IDevice) => {
    if (
      !errors.name &&
      !errors.serial_number &&
      !errors.zone_id &&
      data.settings
    ) {
      const requestData = {
        ...data.settings.user,
      };
      requestData.time.timezone =
        data.settings.user.time.timezone === "Automatic"
          ? ""
          : data.settings.user.time.timezone;
      if (requestData?.audio) {
        requestData.audio.enabled = `${requestData.audio.enabled}` === "true";
        requestData.time.timezone = requestData.time.timezone || "";
      }

      dispatch(
        patchDevice(data.id, {
          type: "vitalthings-somnofy-sm100",
          settings: { user: requestData },
        }),
      );
    }
  };

  const onToggle = (name: string) => {
    setToggle({ ...toggle, [name]: !toggle[name] });
  };

  const getDisablesSleepTrackingLabel = () => {
    return device.settings &&
      device.settings?.user?.schedule &&
      device?.settings?.user?.schedule[0].enabled
      ? `${device?.settings?.user?.schedule[0].timespan?.start?.slice(
          0,
          -3,
        )} - ${device?.settings?.user?.schedule[0].timespan?.end?.slice(0, -3)}`
      : "";
  };

  const handleClickAway = (name: string) => {
    setToggle({ ...toggle, [name]: false });
  };

  const getLocalizedDistance = (value: string | number | undefined) => {
    if (!value) {
      return value;
    }
    if (i18n.language === "en") {
      return value;
    }
    return value.toString().replace(".", ",");
  };

  function onTimePickerUpdate() {
    handleSubmit(updateForm)();
  }
  return (
    <BoxSubSectionStyled height="auto">
      <SubTitleStyled>{t("Device Settings")}</SubTitleStyled>
      <form onSubmit={handleSubmit(updateForm)}>
        <BoxSubSectionContentStyled>
          <Grid container rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <TextStyled> {t("Disable Sleep Tracking")} </TextStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <ClickAwayListener
                onClickAway={() => handleClickAway("disabled_sleep_traacking")}
              >
                <>
                  {!toggle.disabled_sleep_traacking && (
                    <ButtonAsLabelStyledWithBorder
                      onClick={() => onToggle("disabled_sleep_traacking")}
                    >
                      {device.settings?.user?.schedule[0].enabled
                        ? getDisablesSleepTrackingLabel()
                        : t("No")}
                    </ButtonAsLabelStyledWithBorder>
                  )}
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      display: toggle.disabled_sleep_traacking
                        ? "block"
                        : "none",
                    }}
                  >
                    <Controller
                      // @ts-ignore
                      name="settings.user.schedule[0].enabled"
                      control={control}
                      render={({ field }) => (
                        <OnOffFormSwitch
                          field={field}
                          onUpdate={(checked: boolean) => {
                            setShowTimePicker(checked || false);
                            handleSubmit(updateForm)();
                          }}
                        />
                      )}
                    />
                    {showTimePicker && (
                      <>
                        <Controller
                          // @ts-ignore
                          name="settings.user.schedule[0].timespan.start"
                          control={control}
                          render={({ field, formState }) => (
                            <SMFormTimePicker
                              // checked
                              field={field}
                              formState={formState}
                              onUpdate={onTimePickerUpdate}
                            />
                          )}
                        />
                        <Controller
                          // @ts-ignore
                          name="settings.user.schedule[0].timespan.end"
                          control={control}
                          render={({ field, formState }) => (
                            <SMFormTimePicker
                              // checked
                              field={field}
                              formState={formState}
                              onUpdate={onTimePickerUpdate}
                            />
                          )}
                        />
                      </>
                    )}
                  </Stack>
                </>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextStyled> {`${t("Distance")} (${t("meter")})`} </TextStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <ClickAwayListener
                onClickAway={() => handleClickAway("distance_limit")}
              >
                <>
                  {!toggle.distance_limit && (
                    <ButtonAsLabelStyledWithBorder
                      onClick={() => onToggle("distance_limit")}
                    >
                      {getLocalizedDistance(
                        device?.settings?.user?.radar.distance_limit,
                      )}
                      <ShortSpace>&nbsp;</ShortSpace>m
                    </ButtonAsLabelStyledWithBorder>
                  )}

                  {toggle.distance_limit && (
                    <Controller
                      name="settings.user.radar.distance_limit"
                      control={control}
                      render={({ field }) => (
                        <SMFormDiscreteSlider
                          field={field}
                          steps={0.1}
                          min={0.5}
                          displayFormater={(val: number) =>
                            `${getLocalizedDistance(val)} m`
                          }
                          onChangeCommitted={() => {
                            handleSubmit(updateForm)();
                          }}
                        />
                      )}
                    />
                  )}
                </>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextStyled> {t("Display Intensity")} </TextStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <ClickAwayListener
                onClickAway={() => handleClickAway("intensity")}
              >
                <>
                  {!toggle.intensity && (
                    <ButtonAsLabelStyledWithBorder
                      onClick={() => onToggle("intensity")}
                    >
                      {device?.settings?.user?.display.intensity}
                    </ButtonAsLabelStyledWithBorder>
                  )}

                  {toggle.intensity && (
                    <Controller
                      name="settings.user.display.intensity"
                      control={control}
                      render={({ field }) => (
                        <SMFormDiscreteSlider
                          field={field}
                          steps={1}
                          min={1}
                          max={100}
                          displayFormater={(val: number) => `${val}`}
                          onChangeCommitted={() => {
                            handleSubmit(updateForm)();
                          }}
                        />
                      )}
                    />
                  )}
                </>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextStyled> {t("Display Mode")} </TextStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="settings.user.display.mode"
                control={control}
                render={({ field, fieldState, formState }) => (
                  <SMFormDropDown
                    field={field}
                    fieldState={fieldState}
                    formState={formState}
                    placeholder=""
                    options={[
                      {
                        id: "normal",
                        name: t("Clock and circle"),
                        value: "normal",
                      },
                      {
                        id: "circle",
                        name: t("Circle only"),
                        value: "circle",
                      },
                      {
                        id: "off",
                        name: t("Off"),
                        value: "off",
                      },
                    ]}
                    width="100%"
                    onUpdate={() => {
                      handleSubmit(updateForm)();
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextStyled> {t("Audio")} </TextStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="settings.user.audio.enabled"
                control={control}
                render={({ field, fieldState, formState }) => (
                  <SMFormDropDown
                    field={field}
                    fieldState={fieldState}
                    formState={formState}
                    placeholder=""
                    options={[
                      {
                        id: "1",
                        name: t("On"),
                        value: "true",
                      },
                      {
                        id: "2",
                        name: t("Off"),
                        value: "false",
                      },
                    ]}
                    width="100%"
                    onUpdate={() => {
                      handleSubmit(updateForm)();
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextStyled> {t("Time Format")} </TextStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="settings.user.time.format"
                control={control}
                render={({ field, fieldState, formState }) => (
                  <SMFormDropDown
                    field={field}
                    fieldState={fieldState}
                    formState={formState}
                    placeholder=""
                    options={[
                      {
                        id: "1",
                        name: t("AM/PM"),
                        value: "12",
                      },
                      {
                        id: "2",
                        name: t("24 Hour"),
                        value: "24",
                      },
                    ]}
                    width="100%"
                    onUpdate={() => {
                      handleSubmit(updateForm)();
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextStyled> {t("time_zones")} </TextStyled>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="settings.user.time.timezone"
                control={control}
                render={({ field, fieldState }) => (
                  <AutoSuggestFormDropDown
                    field={field}
                    options={TIMEZONES}
                    fieldState={fieldState}
                    onUpdate={() => {
                      handleSubmit(updateForm)();
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </BoxSubSectionContentStyled>
      </form>
    </BoxSubSectionStyled>
  );
}
export default DeviceSettingsComponent;
