import DeleteIcon from "@mui/icons-material/Delete";
import CampaignIcon from "@mui/icons-material/Campaign";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Accordion,
  AccordionSummary,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  TableBody,
  Tooltip,
  TooltipProps,
  checkboxClasses,
  tableCellClasses,
  tableRowClasses,
  tooltipClasses,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, styled } from "@mui/system";

import { IColorValue } from "interfaces/color.interface";
import { CSSProperties } from "react";

export const TextCenterStyled = styled("div")(() => {
  return {
    height: "calc(100vh - 60px)",
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 34,
    padding: 0,
  };
});

export const TextCenter = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    fontSize: 14,
    color: theme.palette.neutral[80],
    margin: 0,
    padding: 0,
    textAlign: "center",
    ...sx,
  };
});

export const PositionRelative = styled("div")(() => {
  return {
    position: "relative",
  };
});

export const PositionAbsolute = styled("div")(
  ({
    top,
    right,
    left,
    bottom,
  }: {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
  }) => {
    return {
      position: "absolute",
      top,
      right,
      left,
      bottom,
    };
  },
);

export const ButtonStyled = styled(Button)(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    position: "relative",
    background: theme.palette.main[70],
    padding: "6px 24px 6px 24px",
    color: theme.palette.white,
    textTransform: "none",
    fontSize: 16,
    maxHeight: 28,
    ...sx,
    "&:hover": {
      background: theme.palette.main[80],
      color: theme.palette.white,
    },
  };
});

export const ButtonAsLabelStyled = styled(Button)(
  ({ sx }: { sx?: CSSProperties }) => {
    const theme = useTheme();
    return {
      position: "relative",
      color: theme.palette.main[70],
      textTransform: "none",
      ...sx,
      "&:hover": {},
    };
  },
);

export const ButtonAsLabelStyledWithBorder = styled(Button)(() => {
  const theme = useTheme();
  return {
    position: "relative",
    color: theme.palette.neutral[70],
    fontSize: 16,
    border: `1px solid ${theme.palette.neutral[5]}`,
    padding: "10px 8px",
    width: "100%",
    justifyContent: "start",
    borderRadius: 2,
    height: 40,
    textTransform: "none",
    fontWeight: "normal",
  };
});

export const TitleStyled = styled("div")(() => {
  return {
    fontStyle: "normal",
    fontSize: 22,
  };
});

export const SubTitleStyled = styled("div")(
  ({ sx }: { sx?: CSSProperties }) => {
    const theme = useTheme();
    return {
      fontSize: 16,
      fontWeight: 500,
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      background: theme.palette.neutral[1],
      padding: "14px 16px",
      boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
      textTransform: "uppercase",
      borderRadius: "8px 8px 0px 0px",
      ...sx,
    };
  },
);

export const MainTitleStyled = styled("div")(
  ({
    sx,
    bg,
    borderbottom,
  }: {
    sx?: CSSProperties;
    bg?: "light" | "dark";
    borderbottom?: "light" | "dark";
  }) => {
    const theme = useTheme();
    return {
      fontStyle: "normal",
      fontSize: 24,
      justifyContent: "space-between",
      fontWeight: 500,
      color: theme.palette.neutral[70],
      display: "flex",
      alignItems: "center",
      background: bg === "light" ? theme.palette.neutral[2] : "",
      borderbottom:
        borderbottom === "light" ? `1px solid ${theme.palette.neutral[4]}` : "",
      ...sx,
    };
  },
);

export const TableTitle = styled("div")(
  ({
    sx,
    bg,
    border,
  }: {
    sx?: CSSProperties;
    bg?: "light" | "dark" | "white";
    border?: "light" | "dark" | "white";
  }) => {
    const theme = useTheme();
    const borderBottom =
      border === "light" ? `1px solid ${theme.palette.neutral[4]}` : "none";
    let background: IColorValue | string = "";
    if (bg === "light") {
      background = theme.palette.neutral[2];
    }
    if (bg === "white") {
      background = "#FFF";
    }
    return {
      fontStyle: "normal",
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.neutral[70],
      textTransform: "uppercase",
      borderBottom,
      background,
      ...sx,
    };
  },
);

export const BackButtonStyled = styled(Button)(() => {
  const theme = useTheme();
  return {
    fontStyle: "normal",
    fontSize: 13,
    color: theme.palette.main[70],
    textTransform: "none",
    paddingLeft: 8,
    paddingRight: 12,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#FFF",
    },
  };
});

export const LabelStyled = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    fontSize: 14,
    fontStyle: "normal",
    color: theme.palette.neutral[70],
    letterSpacing: "0.4px",
    ...sx,
  };
});

export const TextStyled = styled("div")(() => {
  const theme = useTheme();
  return {
    fontSize: 16,
    fontStyle: "normal",
    color: theme.palette.neutral[70],
    textTransform: "none",
  };
});

export const TableDataStyled = styled("div")(({ dark }: { dark?: number }) => {
  const theme = useTheme();
  return {
    fontSize: 14,
    fontStyle: "normal",
    color: dark ? theme.palette.neutral[70] : theme.palette.neutral[40],
  };
});

export const SMTableWrapperBox = styled("div")(
  ({ sx }: { sx?: CSSProperties }) => {
    return {
      marginBottom: 20,
      boxShadow: "0px 0px 1px 1px rgb(0 0 0 / 10%)",
      borderRadius: "8px",
      overflow: "hidden",
      ...sx,
    };
  },
);

export const InputLabelStyled = styled("div")(
  ({ maginbottom, sx }: { maginbottom?: number; sx?: CSSProperties }) => {
    const theme = useTheme();
    return {
      color: theme.palette.neutral[70],
      fontSize: 14,
      letterSpacing: "0.4px",
      fontWeight: 500,
      marginBottom: maginbottom || 0,
      ...sx,
    };
  },
);

export const SettingsIconStyled = styled(SettingsIcon)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    position: "absolute",
    lineHeight: "10px",
  };
});

export const DeleteIconStyled = styled(DeleteIcon)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    position: "absolute",
    lineHeight: "10px",
  };
});

export const AlertIconStyled = styled(CampaignIcon)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    position: "absolute",
    lineHeight: "10px",
  };
});

export const IconButtonStyled = styled(IconButton)(
  ({
    width,
    padding,
  }: {
    width?: string | number;
    padding?: string | number;
  }) => {
    const theme = useTheme();
    return {
      width: width || 35,
      height: width || 35,
      padding,
      "&:hover": {
        background: theme.palette.main[2],
      },
    };
  },
);

export const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)(
  ({ active }: { active: boolean }) => {
    const theme = useTheme();
    return {
      color: theme.palette.main[70],
      transform: active ? "rotate(180deg)" : "",
    };
  },
);
export const ChipRectNoBorderStyled = styled(Chip)(
  ({
    minWidth,
    width,
    bg,
    dark,
    height,
  }: {
    minWidth?: number;
    width?: number;
    bg?: string;
    dark?: number;
    height?: string;
  }) => {
    const theme = useTheme();
    let background: IColorValue;
    switch (bg) {
      case "blue":
        background = theme.palette.blue[5];
        break;
      case "blue-dark":
        background = theme.palette.blue[40];
        break;
      case "green":
        background = theme.palette.green[5];
        break;
      case "green-dark":
        background = theme.palette.green[40];
        break;
      case "orange":
        background = theme.palette.orange[10];
        break;
      case "orange-dark":
        background = theme.palette.orange[40];
        break;
      case "red-dark":
        background = theme.palette.red[40];
        break;
      case "purple":
        background = theme.palette.purple[5];
        break;
      case "purple-dark":
        background = theme.palette.purple[40];
        break;
      case "red":
        background = theme.palette.red[5];
        break;
      case "teal":
        background = theme.palette.teal[5];
        break;
      case "teal-dark":
        background = theme.palette.teal[40];
        break;
      default:
        background = theme.palette.neutral[10];
    }
    return {
      minWidth,
      background,
      borderRadius: 2,
      width: width || 96,
      fontSize: 14,
      paddingTop: 4,
      paddingBottom: 4,
      letterSpacing: "0.4px",
      color: dark ? theme.palette.neutral[0] : theme.palette.neutral[80],
      height: height || 24,
      border: `1px solid ${background}`,
    };
  },
);

// Todo: @fasalu
export const ChipRectangularStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "minWidth",
})(
  ({
    minWidth,
    width,
    bg,
    borderRadius,
    height,
    capitalize,
  }: {
    minWidth?: number;
    width?: number;
    bg?: string;
    borderRadius?: number;
    height?: string;
    capitalize?: number;
  }) => {
    const theme = useTheme();
    let background;
    let border;

    switch (bg) {
      case "blue":
        background = theme.palette.blue[10];
        border = theme.palette.blue[20];
        break;
      case "grey":
        background = theme.palette.neutral[10];
        border = theme.palette.neutral[5];
        break;
      case "white":
        background = theme.palette.neutral[0];
        border = theme.palette.neutral[5];
        break;
      case "lightgrey":
        background = theme.palette.neutral[5];
        border = theme.palette.neutral[10];
        break;
      case "darkgrey":
        background = theme.palette.neutral[3];
        border = theme.palette.neutral[10];
        break;
      case "green":
        background = theme.palette.green[10];
        border = theme.palette.green[20];
        break;
      case "teal":
        background = theme.palette.teal[5];
        border = theme.palette.teal[10];
        break;
      case "lightgrey-no-bg":
        background = "none";
        border = theme.palette.neutral[10];
        break;
      case "plain":
        background = null;
        border = null;
        break;
      default:
        background = null;
        border = null;
    }
    return {
      minWidth,
      background: background || "none",
      borderRadius: borderRadius || 16,
      width: width || 62,
      fontSize: 14,
      letterSpacing: "0.4px",
      color: theme.palette.neutral[80],
      height: height || 24,
      paddingTop: 4,
      paddingBottom: 4,
      border: border ? `1px solid ${border}` : "none",
      textTransform: capitalize ? "capitalize" : "none",
    };
  },
);

export const ChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "disableMouseover",
})(
  ({
    bg,
    sx,
    disableMouseover,
    minWidth,
  }: {
    bg?: string;
    sx?: CSSProperties;
    disableMouseover?: number;
    minWidth?: number;
  }) => {
    const theme = useTheme();
    let background;
    let txtColor = "#FFF";
    let borderColor = "#B5B5B5";
    let hoverColor = "#F8F8F8";
    switch (bg) {
      case "disabled":
        background = theme.palette.neutral[5];
        borderColor = theme.palette.neutral[10];
        txtColor = theme.palette.neutral[50];
        hoverColor = theme.palette.neutral[5];
        break;
      case "green":
        background = theme.palette.green[10];
        borderColor = theme.palette.green[20];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.green[10];
        break;
      case "red":
        background = theme.palette.red[10];
        borderColor = theme.palette.red[20];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.red[10];
        break;
      case "grey":
        background = theme.palette.neutral[0];
        txtColor = theme.palette.neutral[50];
        borderColor = theme.palette.neutral[5];
        hoverColor = theme.palette.neutral[3];
        break;
      case "pink":
        background = theme.palette.red[5];
        borderColor = theme.palette.red[10];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.red[1];
        break;
      case "orange":
        background = theme.palette.orange[10];
        borderColor = theme.palette.orange[20];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.orange[1];
        break;
      case "yellow":
        background = theme.palette.yellow[10];
        borderColor = theme.palette.yellow[30];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.yellow[1];
        break;
      case "purple":
        background = theme.palette.purple[5];
        borderColor = theme.palette.purple[10];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.purple[1];
        break;
      case "teal":
        background = theme.palette.teal[5];
        borderColor = theme.palette.teal[10];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.teal[1];
        break;
      case "blue":
        background = theme.palette.blue[5];
        borderColor = theme.palette.blue[10];
        txtColor = theme.palette.neutral[80];
        hoverColor = theme.palette.blue[1];
        break;
      default:
        background = theme.palette.blue[40];
    }
    return {
      background,
      minWidth,
      borderRadius: 16,
      color: txtColor,
      fontSize: 14,
      height: 24,
      border: borderColor ? `1px solid ${borderColor}` : "",
      padding: "4px 12px",
      "& span": {
        padding: 0,
      },
      "&:hover": {
        background: disableMouseover ? background : hoverColor,
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        opacity: 1,
      },
      ...sx,
    };
  },
);

export const DivCircle = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    display: "flex",
    alignItems: "center",
    "& > div": {
      borderRadius: "100%",
      padding: 4,
      background: theme.palette.main[40],
      height: 28,
      width: 28,
      fontSize: 13,
      color: "#FFF",
      letterSpacing: "0.5px",
      textAlign: "center",
      marginRight: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
    },
    "& span": {
      marginTop: 1,
    },

    ...sx,
  };
});

export const ButtonAsTextStlyled = styled(Button)(
  ({
    height,
    fontsize,
    disabledstyle,
  }: {
    height?: string | number;
    fontsize?: number;
    disabledstyle?: number;
  }) => {
    const theme = useTheme();
    return {
      fontSize: fontsize || 16,
      fontWeight: 500,
      opacity: disabledstyle ? 0.3 : 1,
      color: disabledstyle ? theme.palette.neutral[70] : theme.palette.main[70],
      cursor: "pointer",
      textTransform: "none",
      height,
      "&:hover": {
        background: theme.palette.main[2],
      },
    };
  },
);

export const InputEndAdornment = styled("div")(() => {
  const theme = useTheme();
  return {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.neutral[80],
    marginRight: 8,
  };
});

export const InlineButtonsStyled = styled("div")(() => {
  const theme = useTheme();
  return {
    marginTop: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    position: "absolute",
    width: "100%",
    right: 0,
    "& button": {
      background: `${theme.palette.neutral[1]}`,
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
      borderRadius: 4,
      height: 32,
      width: 32,
      marginLeft: 6,
    },
  };
});

export const ShortSpace = styled("span")(() => {
  return {
    wordSpacing: "-0.125em",
  };
});

export const FlexCenter = styled("div")(
  ({ columnCenter = 1, sx }: { columnCenter?: number; sx?: CSSProperties }) => {
    return {
      display: "flex",
      justifyContent: columnCenter ? "center" : "normal",
      alignItems: "center",
      ...sx,
    };
  },
);

export const MonitorGrid = styled("div")(
  ({ tilescale }: { tilescale: number }) => {
    let width: number | null = null;
    width = tilescale * 4 * 1.4;
    if (tilescale > 120) {
      width = null;
    }
    return {
      display: "grid",
      gridTemplateColumns: width ? `repeat(auto-fit, ${width}px)` : "1fr",
      gridColumnGap: "16px",
      rowGap: "16px",
    };
  },
);

// `repeat(auto-fit,minmax(${width}px,1fr))`
export const FormControlLabelStyled = styled(FormControlLabel)(
  ({ sx }: { sx?: CSSProperties }) => {
    const theme = useTheme();
    return {
      color: theme.palette.neutral[80],
      fontSize: 16,
      padding: 6,
      margin: 0,
      textTransform: "none",
      "& .MuiFormControlLabel-label": {
        fontSize: 16,
      },
      [`& .${checkboxClasses.root}`]: {
        color: theme.palette.main[70],
        width: 12,
        height: 12,
        marginLeft: 1,
        marginRight: 10,
        p: 0,
        borderRadius: 0,
      },
      ...sx,
    };
  },
);

export const SubTitle = styled("h2")(() => {
  const theme = useTheme();
  return {
    margin: 0,
    padding: 0,
    color: theme.palette.neutral[80],
    fontSize: 18,
    textTransform: "none",
    letterSpacing: "0.1px",
    fontWeight: 500,
  };
});

export const SMBox = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    padding: 12,
    borderRadius: 2,
    border: `1px solid ${theme.palette.neutral[10]}`,
    ...sx,
  };
});

export const SMColmnText = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    color: theme.palette.neutral[80],
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.25px",
    minHeight: "40px",
    ...sx,
  };
});

export const SMLabel = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[80],
    display: "flex",
    alignItems: "center",
    paddingTop: 4,
    paddingBottom: 4,
    ...sx,
  };
});

export const SMSecondaryLabel = styled("div")(() => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[30],
    fontSize: "14px",
  };
});

export const BoxPrimary = styled(Box)(() => {
  const theme = useTheme();
  return {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.main[70],
    cursor: "pointer",
    padding: "0 5px",
    textTransform: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  };
});

export const SMChipButton = styled(Button)(
  ({
    width = "46px",
    bg,
    disablemouseover,
  }: {
    width?: string | number;
    bg: string;
    disablemouseover?: number;
  }) => {
    const theme = useTheme();
    let background;
    let borderColor;
    let hoverBg;
    switch (bg) {
      case "green":
        background = theme.palette.green[10];
        borderColor = theme.palette.green[20];
        hoverBg = theme.palette.green[1];
        break;
      case "red":
        background = theme.palette.red[10];
        borderColor = theme.palette.red[20];
        hoverBg = theme.palette.red[1];
        break;
      case "grey":
        background = theme.palette.neutral[10];
        borderColor = theme.palette.neutral[20];
        hoverBg = theme.palette.red[1];
        break;
      default:
        background = theme.palette.neutral[10];
        borderColor = theme.palette.neutral[20];
        hoverBg = theme.palette.red[1];
        break;
    }
    return {
      width: width || 46,
      border: `1px solid ${borderColor}`,
      borderRadius: 4,
      height: 24,
      background,
      color: theme.palette.neutral[80],
      textTransform: "capitalize",
      "&:hover": {
        background: disablemouseover ? background : hoverBg,
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        color: theme.palette.neutral[80],
      },
    };
  },
);

export const TooltipWithLock = styled(({ ...props }: TooltipProps) => (
  <Tooltip
    placement="top"
    title={
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LockIcon />
        <Box sx={{ paddingTop: "3px" }}> {props.title}</Box>{" "}
      </Box>
    }
  >
    {props.children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
  },
}));

export const AccordionSummaryStyled = styled(AccordionSummary)(
  ({ borderRadius }: { borderRadius?: string | number }) => {
    const theme = useTheme();
    return {
      fontSize: 16,
      fontWeight: 500,
      justifyContent: "space-between",
      display: "flex",
      alignItems: "center",
      background: theme.palette.neutral[1],
      padding: "14px 16px",
      boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
      textTransform: "uppercase",
      borderRadius: borderRadius || "8px 8px 0px 0px",
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    };
  },
);

export const AccordionStyled = styled(Accordion)(() => {
  const theme = useTheme();
  return {
    margin: 0,
    background: "#FFF",
    border: `1px solid ${theme.palette.neutral[5]}`,
    borderRadius: "8px !important",
    clear: "both",
  };
});

export const DarkColumnText = styled("div")(() => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[80],
    fontSize: 16,
  };
});

// TODO: consider renaming since duplicate name with component in TableElementStyled.tsx.
export const TableBodyStyled = styled(TableBody)(
  ({
    disable_row_hover,
    dynamic_padding,
  }: {
    disable_row_hover?: number;
    dynamic_padding?: number;
  }) => {
    const theme = useTheme();
    return {
      [`& .${tableRowClasses.root}`]: {
        padding: "16px 20px",
        fontSize: 14,
        cursor: disable_row_hover ? "default" : "pointer",
        textAlign: "left",
        background: theme.palette.white,
        borderBottom: `1px solid ${theme.palette.neutral[5]}`,
        color: theme.palette.neutral[40],
        [`& .${tableCellClasses.root}`]: {
          border: "none",
          verticalAlign: "top",
        },
        "&:last-child": {
          borderBottom: "none",
        },
        "& td": {
          color: theme.palette.neutral[40],
          fontSize: 16,
          [theme.breakpoints.down("lg")]: {
            padding: dynamic_padding ? "16px 5px" : "16px",
          },
        },
        "& td:first-of-type": {
          color: theme.palette.neutral[70],
          fontSize: 16,
        },
        "&:hover": {
          background: disable_row_hover ? "none" : theme.palette.main[2],
        },
      },
    };
  },
);

export const DateRangePickerWrapper = styled("div")(() => {
  const theme = useTheme();
  return {
    [`& .rdrStaticRangeLabel`]: {
      color: theme.palette.neutral[70],
    },
  };
});
export const TextWithIconButtonStyled = styled(Button)(() => {
  const theme = useTheme();
  return {
    marginRight: 4,
    color: theme.palette.main[70],
    fontWeight: 500,
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: 16,
    paddingRight: 12,
    paddingLeft: 12,
    marginBottom: 4,
    "&:hover": {
      background: "#FFF",
    },
    "> span": {
      marginLeft: 0,
    },
    "& svg": {
      width: 24,
      height: 24,
    },
  };
});
