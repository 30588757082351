import { NightReport } from "interfaces/nightReport.interface";
import { nightReportServices } from "services/dashboard/nightReportService";

import * as types from "../../actionTypes/dashboard/nightReportActionTypes";

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

export const clearNightReportAction = (): types.ClearNightReportActionType => {
  return {
    type: types.CLEAR_NIGHT_REPORT,
  };
};

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 */
export const fetchNightReportAction = (): types.FetchNightReportActionType => {
  return {
    type: types.FETCH_NIGHT_REPORT,
  };
};

/**
 * Redux monitor success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const fetchNightReportSuccessAction = (
  report: NightReport,
): types.FetchNightReportSuccessActionType => {
  return {
    type: types.FETCH_NIGHT_REPORT_SUCCESS,
    payload: report,
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

/**
 *  Function to fetch all the subjects
 *
 * @param none
 * @return all the subjects in the system
 */
export function fetchNightReport(subjects: string[], report_date: string) {
  return function (dispatch: (arg0: any) => void) {
    nightReportServices
      .fetchNightReport(subjects, report_date)
      .then((response: { data: any }) => {
        if (response?.data && response?.data?.length) {
          response.data.forEach((report: NightReport) => {
            dispatch(fetchNightReportSuccessAction(report));
          });
        }
      });
  };
}

export function clearNightReport() {
  return function (dispatch: (arg0: any) => void) {
    dispatch(clearNightReportAction());
  };
}
