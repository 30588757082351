import { styled } from "@mui/system";

const PREFIX = "LandingComponent";

const classes = {
  root: `${PREFIX}-root`,
  video: `${PREFIX}-video`,
  overlay: `${PREFIX}-overlay`,
};

const SomnofyVideoSrc =
  "https://somnofy.ams3.cdn.digitaloceanspaces.com/Film/TheSomnofy_1280x720.mp4";

const StyledRoot = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const StyledVideo = styled("div")({
  height: "100vh",
  objectFit: "cover",
});

// TODO: check if there has been a class for overlay or if this was legacy?
const LandingComponent = () => {
  return (
    <StyledRoot className={classes.root}>
      <div className={classes.overlay}>
        <StyledVideo className={classes.video} autoPlay muted loop>
          <source src={SomnofyVideoSrc} />
        </StyledVideo>
      </div>
    </StyledRoot>
  );
};

export default LandingComponent;
