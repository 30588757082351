import { useMemo, useState } from "react";

import { Order } from "interfaces/componentsTypes/tableComponentsTypes";
import { ISubject } from "interfaces/subject.interface";
import { IZone } from "interfaces/zone.interface";

type ISortConfig = {
  key: keyof ISubject | string;
  direction: Order;
};

type KeyOfISubject = keyof Omit<ISubject, "metadata" | "devices">;
export const useSortableData = (
  items: ISubject[],
  config: ISortConfig | null,
  zoneEntity: Record<string, IZone>,
) => {
  const [sortConfig, setSortConfig] = useState<ISortConfig | null>(config);

  const sortedItems = useMemo(() => {
    const getValue = (item: ISubject): number | string => {
      if (sortConfig !== null) {
        switch (sortConfig.key) {
          case "device_status":
            return item.devices?.data && item.devices?.data?.length > 0
              ? Number(item?.devices?.data[0]?.online)
              : -1;
          case "name":
            return item?.devices?.data[0]?.name?.toLowerCase() || "";
          case "serial_number":
            return item.devices?.data[0]?.serial_number?.toLowerCase() || "";
          case "zone_id":
            return zoneEntity[item.zone_id]?.name?.toLowerCase() || "";

          default: {
            const val =
              (item[sortConfig.key as KeyOfISubject] as string | number) || "";
            if (typeof val === "string") {
              return val.toLowerCase();
            }
            return val;
          }
        }
      }
      return "";
    };

    const sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a: ISubject, b: ISubject) => {
        const left = getValue(a);
        const right = getValue(b);
        if (left < right) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (left > right) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig, zoneEntity]);

  const requestSort = (key: keyof ISubject | string) => {
    let direction: Order = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
