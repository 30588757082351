import { Redirect, Route } from "react-router-dom";

import { LOGIN_PAGE } from "constants/constants";
import UseAuthenticatedUser from "hooks/UseAuthenticatedUser";
import { ComponentType } from "react";

function PrivateRouteV1({
  path,
  component,
}: {
  path: string;
  component: ComponentType<any>;
}) {
  const { authUser } = UseAuthenticatedUser();
  if (authUser === undefined) {
    return <div />;
  }

  if (authUser && authUser.id) {
    return <Route path={path} component={component} />;
  }

  return <Redirect to={`${LOGIN_PAGE}`} />;
}
export default PrivateRouteV1;
