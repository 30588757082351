import { styled } from "@mui/system";
import { Dispatch, MouseEvent, RefObject, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { IZone } from "interfaces/zone.interface";
import { updatePropertyById } from "utils/zone.util";

import TreeBranch from "./TreeBranch";

const TreeViewWrapper = styled("div")({
  padding: "0px 12px",
  overflowY: "auto",
  width: "fit-content",
  display: "inline-block",
  height: "100%",
});

const TreeViewComponent = ({
  data,
  handleChange,
  setZones,
  activeZone,
  authorizedZones,
  treeRef,
}: {
  data: IZone[];
  setZones: Dispatch<SetStateAction<IZone[]>>;
  handleChange: (e: MouseEvent<HTMLElement>, clicked: IZone) => void;
  activeZone: string;
  authorizedZones: string[];
  treeRef: RefObject<HTMLDivElement>;
}) => {
  const { t } = useTranslation();
  const onCollapse = (item: IZone, collapse: boolean) => {
    const current = updatePropertyById(item.id, data[0], "collapsed", collapse);
    setZones([current]);
  };

  const checkIfUseHasAccess = (node: IZone) => {
    const nodePaths = node.path.split(".");
    for (let i = 0; i < authorizedZones.length; i += 1) {
      if (nodePaths.includes(authorizedZones[i])) {
        return true;
      }
    }
    return false;
  };

  const isDisableNode = (node: IZone) => {
    return !checkIfUseHasAccess(node);
  };

  return (
    <TreeViewWrapper ref={treeRef}>
      {data.map((item: IZone) => (
        <TreeBranch
          key={item.id}
          item={item}
          level={0}
          handleChange={handleChange}
          levelPadding={32}
          activeZone={activeZone}
          onCollapse={onCollapse}
          isDisableNode={isDisableNode}
          disabled_tooltip={t("no_permission_on_this_zone")}
        />
      ))}
    </TreeViewWrapper>
  );
};

export default TreeViewComponent;
