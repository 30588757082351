// Todo (fr@vitalthings.com)
import { Link, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import UserIcon from "@mui/icons-material/Person";

import { LOGIN_PAGE } from "constants/constants";
import Landing from "@modules/landing/Landing";

const Home = () => {
  const { t } = useTranslation();
  const Icon = UserIcon;
  return (
    <div style={{ backgroundColor: "#121217" }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          color: "white",
          boxShadow: "unset !important"
        }}
      >
        <Toolbar
          sx={{
            color: "white",
            "& :last-child": {
              marginLeft: "auto"
            }
          }}
        >
          <Button component={Link} to={`${LOGIN_PAGE}`} color="inherit">
            {" "}
            {t("Log in")}
            <div style={{ marginLeft: "12px !important" }}>
              <Icon />
            </div>
          </Button>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route path="/home" component={Landing} key="home" />
      </Switch>
    </div>
  );
};
export default Home;
