import { IZone } from "interfaces/zone.interface";
import { zoneServices } from "services/administration/zoneServices";

import * as types from "../../actionTypes/administration/zoneActionTypes";

export const restDeviceCurrentAction = (): types.zonesActionTypes => {
  return {
    type: types.RESET_ZONE_ACTIONS,
  };
};

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 */
export const getAllZoneAction = (): types.zonesActionTypes => {
  return {
    type: types.FETCH_ZONES,
  };
};

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 *         payload - zones
 */
export const getAllZoneActionSuccess = (
  data: IZone[],
): types.zonesActionTypes => {
  return {
    type: types.FETCH_ZONES_SUCCESS,
    payload: data,
  };
};

/**
 * Create  zone ation
 *
 * @return type - redux action type |
 *
 */
export const createZoneAction = (): types.zonesActionTypes => {
  return {
    type: types.CREATE_ZONE,
  };
};

/**
 * Create  zone success action
 *
 * @return type - redux action type |
 *         payload - param
 */
export const createZoneSuccesAction = (data: IZone): types.zonesActionTypes => {
  return {
    type: types.CREATE_ZONE_SUCCESS,
    payload: data,
  };
};

/**
 * updateZoneAction
 * @return type - redux action type
 *
 */
export const updateZoneAction = (): types.zonesActionTypes => {
  return {
    type: types.UPDATE_ZONE,
  };
};

/**
 * updateZoneSuccessAction
 * @return type - redux action type
 *         response - Created zone
 */
export const updateZoneSuccessAction = (
  data: IZone,
): types.zonesActionTypes => {
  return {
    type: types.UPDATE_ZONE_SUCCESS,
    payload: data,
  };
};

/**
 * deleteZoneAction
 * @return type - redux action type
 *
 */
export const deleteZoneAction = (): types.zonesActionTypes => {
  return {
    type: types.DELETE_ZONE,
  };
};

/**
 * deleteZoneSuccessAction
 * @return type - redux action type
 *
 */
export const deleteZoneSuccessAction = (id: string): types.zonesActionTypes => {
  return {
    type: types.DELETE_ZONE_SUCCESS,
    payload: id,
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

/**
 *  Function to fetch all the zones
 *
 * @param none
 * @return all the zones in the system
 */
export function getAllZones() {
  return function (dispatch: (arg0: any) => void) {
    dispatch(getAllZoneAction);
    zoneServices
      .fetchZones()
      .then((data: { object: "list"; data: IZone[] }) => {
        if (data?.data) {
          dispatch(getAllZoneActionSuccess(data.data));
        }
      });
  };
}

/**
 *  Function to create zone
 *
 * @param name - zone name | parent_id [optional] - parent zone id | description [optional] - description
 * @return created zone
 */
export function createZone(data: IZone, callBack?: (item: IZone) => void) {
  return function (dispatch: (arg0: any) => void) {
    dispatch(createZoneAction);
    zoneServices.createZone(data).then((response: { data?: IZone }) => {
      if (response?.data) {
        dispatch(createZoneSuccesAction(response.data));
        if (callBack) {
          // shpw toast message
          callBack(response.data);
        }
      }
    });
  };
}

/**
 *  Function to update zone
 *
 * @param name - zone name | parent_id [optional] - parent zone id | description [optional] - description
 * @return updated zone
 */
export function updateZone(data: IZone, updateZoneCallBack?: () => void) {
  return function (dispatch: (arg0: any) => void) {
    dispatch(updateZoneAction);
    zoneServices.updateZone(data).then((response: { data?: IZone }) => {
      if (response?.data) {
        dispatch(updateZoneSuccessAction(response.data));
      } else if (updateZoneCallBack) {
        updateZoneCallBack();
      }
    });
  };
}

/**
 *  Function to delete zone
 *
 * @param Id:string - zone id
 * @return updated zone
 */
export function deleteZone(
  zone: IZone,
  callBack?: (item: IZone) => void,
  errorCallback?: (err: any) => void,
) {
  return function (dispatch: (arg0: any) => void) {
    dispatch(deleteZoneSuccessAction);
    zoneServices.deleteZone(zone.id).then((response: any) => {
      // no response means it deleted
      if (!response) {
        dispatch(deleteZoneSuccessAction(zone.id));
        if (callBack) {
          callBack(zone);
        }
      } else if (response.status === 400 && errorCallback) {
        errorCallback(response?.data);
      }
    });
  };
}
