import { ITenant } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import * as types from "../../actionTypes/authActionTypes";

export interface AuthState {
  user?: IUser;
  tenants?: ITenant[] | null;
  authorizedZones?: IZone[];
}

const initialState: AuthState = {};

export default function authReducerV1(
  state: AuthState = initialState,
  action: types.authActionTypes,
) {
  switch (action.type) {
    case types.FETCH_TENANTS_SUCCESS:
      return {
        ...state,
        tenants: action.payload,
      };

    case types.GET_AUTH_USER_SUCCESS_ACTION:
      return {
        ...state,
        user: action.payload,
      };

    case types.GET_AUTH_ZONES_SUCCESS_ACTION:
      return {
        ...state,
        authorizedZones: action.payload,
      };

    case types.UPDATE_AUTH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case types.RESET_TENANTS:
      return {
        ...state,
        tenants: null,
      };

    case types.RESET_AUTH_STATE:
      return initialState;

    default:
      return state;
  }
}
