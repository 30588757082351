import AdminMainContainer from "@modules/administration/components/AdminMainContainer";
import { useTheme } from "@mui/material";
import { SplitPanel } from "components/ResizablePanel/SplitPanel";
import { SplitPanelDivider } from "components/ResizablePanel/SplitPanelDivider";
import { IZone } from "interfaces/zone.interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllZones } from "store/actions/administration/zoneActions";
import { IRootState } from "store/reducers";
import * as LOCAL_STORAGE from "utils/localStorage";
import { canAccessZone } from "utils/zone.util";
import { AdministrationContext } from "./AdministrationContext";
import ZoneSidebar from "./components/ZoneSidebar";

type THistory = ReturnType<typeof useHistory>;

function AdministrationScreen() {
  const dispatch = useDispatch();
  const history: THistory = useHistory<THistory>();

  const [currentPath, setCurrentPath] = useState<{
    id: string;
    name: string;
  }>();

  const theme = useTheme();

  const [selectedZone, setSelectedZone] = useState<string>(
    LOCAL_STORAGE.getZone() || "",
  );
  const [zoneEntity, setZoneEntity] = useState<{ [key: string]: IZone }>({});

  const zoneReducer = useSelector(
    (state: IRootState) => state?.zoneReducer ?? {},
  );

  const authUser = useSelector(
    (state: IRootState) => state.authReducerV1?.user,
  );

  useEffect(() => {
    // update selected zone in localstore
    LOCAL_STORAGE.setZone(selectedZone);
  }, [selectedZone]);

  // Fetch all zones
  useEffect(() => {
    dispatch(getAllZones());
  }, [dispatch]);

  useEffect(() => {
    const authZones =
      authUser?.zones?.data?.map((item: IZone) => item.id) || [];
    if (zoneEntity && zoneEntity[selectedZone]) {
      const selectedZonePath = zoneEntity[selectedZone].path || "";
      if (!canAccessZone(authZones, selectedZonePath.split("."))) {
        // current selected is not authorized, so select the authorized zone
        setSelectedZone(authZones[0]);
      }
    }
  }, [authUser, zoneEntity, selectedZone]);

  useEffect(() => {
    const entity: { [key: string]: IZone } = {};
    if (zoneReducer.zones && Array.isArray(zoneReducer.zones)) {
      zoneReducer.zones.forEach((item: IZone) => {
        entity[item.id] = item;
      });
      setZoneEntity(entity);
    }
  }, [zoneReducer.zones]);

  useEffect(() => {
    return history.listen(() => {
      const path = history.location?.pathname?.split("/")?.reverse();
      if (
        path &&
        path.length &&
        (path[0] === "subjects" || path[0] === "devices" || path[0] === "users")
      ) {
        if (setCurrentPath) {
          setCurrentPath({ id: "", name: "" });
        }
      }
    });
  }, [history]);

  return (
    <AdministrationContext.Provider
      value={{
        zones: zoneReducer.zones || [],
        currentPath,
        setCurrentPath,
        selectedZone,
        setSelectedZone,
        zoneEntity,
      }}
    >
      <SplitPanel flexDirection="row" bgColor={theme.palette.neutral[3]}>
        {authUser && authUser.id && (
          <ZoneSidebar zoneList={zoneReducer.zones || []} authUser={authUser} />
        )}
        <SplitPanelDivider cursor="ew-resize" />
        <AdminMainContainer />
      </SplitPanel>
    </AdministrationContext.Provider>
  );
}

export default AdministrationScreen;
