import { INotificationHandler } from "interfaces/notification.interface";
import { IRule, IRulePreset } from "interfaces/rules.interface";
import * as types from "../../actionTypes/rules/rulesActionTypes";

export default function rulesReducer(
  state: {
    notifications?: INotificationHandler[];
    presets?: Record<string, IRulePreset[]>;
    rules?: IRule[];
  } = {},
  action: types.rulesActionTypes,
) {
  switch (action.type) {
    case types.FETCH_RULES_SUCCESS:
      return {
        ...state,
        rules: action.payload,
      };

    case types.CREATE_RULES_SUCCESS:
      return {
        ...state,
        rules: state.rules
          ? [...state.rules, action.payload]
          : [action.payload],
      };

    case types.DELETE_RULES_SUCCESS:
      return {
        ...state,
        rules: state.rules?.filter((rule: IRule) => rule.id !== action.payload),
      };

    case types.UPDATE_RULES_HANDLER_SUCCESS:
      return {
        ...state,
        rules: state.rules?.map((rule: IRule) =>
          rule.id === action.payload.id ? { ...action.payload } : rule,
        ),
      };

    case types.FETCH_RULES_PRESET_SUCCESS:
      return {
        ...state,
        presets: {
          ...state.presets,
          [action.payload.type]: action.payload.data,
        },
      };

    case types.RESET_RULES_STATE:
      return {
        notifications: undefined,
        presets: undefined,
        rules: undefined,
      };

    default:
      return state;
  }
}
