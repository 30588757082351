import { Box } from "@mui/system";

import { SMTableWrapperBox, TableTitle } from "styled/CommonStyled";
import { StyledP } from "styled/StylesStyled";

function SMNoDataBox({
  title,
  message,
  bodyBg,
}: {
  title: string | JSX.Element;
  message: string;
  bodyBg?: "white" | "grey";
}) {
  return (
    <SMTableWrapperBox>
      <TableTitle
        bg="light"
        border="light"
        sx={{
          padding: "16.5px 20px",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        {title}
      </TableTitle>
      <Box
        sx={{ padding: 2, background: bodyBg === "white" ? "#FFF" : "none" }}
      >
        <StyledP>{message}</StyledP>
      </Box>
    </SMTableWrapperBox>
  );
}
export default SMNoDataBox;
