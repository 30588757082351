import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

import { SortAscIcon, SortDescIcon, UnsortedIcon } from "components/icons";
import { MouseEvent, RefObject, memo } from "react";
import { IconButtonStyled } from "styled/CommonStyled";
import {
  TableHeadDataStyled,
  TableHeadRowStyled,
} from "styled/TableElementStyled";
/**
 * Sort order
 */
type Order = "asc" | "desc";

export type HeadCell = {
  id: string;
  label: string;
  hideSort?: boolean;
  textCenter?: boolean;
  hideColumn?: boolean;
};

type EnhancedTableProps = {
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  stylesPropHead?: { [key: string]: { [key: string]: string } };
  dynamic_padding?: number;
  headerRef?: RefObject<HTMLTableSectionElement>;
};

const SMTableHeader = memo(function SMTableHeader(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    stylesPropHead,
    dynamic_padding,
    headerRef,
  } = props;
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead component={"thead"} ref={headerRef}>
      <TableHeadRowStyled
        disable_row_hover={0}
        dynamic_padding={dynamic_padding}
      >
        {headCells
          .filter((headCell: HeadCell) => !headCell.hideColumn)
          .map((headCell: HeadCell) => (
            <TableCell
              key={headCell.label}
              sortDirection={orderBy === headCell.id ? order : false}
              style={
                stylesPropHead
                  ? stylesPropHead[`${headCell.id.toString()}`]
                  : undefined
              }
            >
              <TableHeadDataStyled
                onClick={
                  !headCell.hideSort ? createSortHandler(headCell.id) : () => {}
                }
                cursordefault={headCell.hideSort ? 1 : 0}
                txtcenter={headCell.textCenter ? 1 : 0}
              >
                <div>
                  <div> {headCell.label} </div>
                  {!headCell.hideSort && (
                    <IconButtonStyled padding={0} width="16px">
                      {orderBy === headCell.id ? (
                        order === "asc" ? (
                          <SortAscIcon />
                        ) : (
                          <SortDescIcon />
                        )
                      ) : (
                        <UnsortedIcon />
                      )}
                    </IconButtonStyled>
                  )}
                </div>
              </TableHeadDataStyled>
            </TableCell>
          ))}
      </TableHeadRowStyled>
    </TableHead>
  );
});

export default SMTableHeader;
