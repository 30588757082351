import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { MenuItem, SvgIcon, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import {
  createRef,
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import SplitPanelContext, {
  ContextInterface,
} from "components/ResizablePanel/SplitPanelContext";
import { FlexOne } from "components/ResizablePanel/SplitPanelStyledComponents";
import TreeViewComponent from "components/TreeView/TreeViewComponent";
import UseAdminContext from "hooks/UseAdminContext";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import { resetDeviceState } from "store/actions/administration/deviceActions";
import { resetSubjectState } from "store/actions/administration/subjectActions";
import { gotTo } from "utils/history.util";
import * as LOCAL_STORAGE from "utils/localStorage";
import { makeZoneTree } from "utils/zone.util";

const MenuItemStyled = styled(MenuItem)(
  ({ disable_mouse_over }: { disable_mouse_over?: number }) => {
    const theme = useTheme();
    return {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.25",
      paddingLeft: 14,
      color: theme.palette.neutral[80],
      "&:hover": {
        background: disable_mouse_over ? "none" : "inherit",
      },
    };
  },
);

const SvgIconStyled = styled(SvgIcon)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[80],
    marginRight: 14,
  };
});

export const ZoneSidebar = ({
  zoneList,
  authUser,
}: {
  zoneList: IZone[];
  authUser: IUser;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [zones, setZones] = useState<IZone[]>([]);
  const { selectedZone, setSelectedZone } = UseAdminContext();
  useMemo(() => {
    const zoneTree = makeZoneTree(zoneList);
    if (zoneTree) {
      setZones(zoneTree);
    }
  }, [zoneList]);

  const handleChange = (e: MouseEvent<HTMLElement>, zoneType: IZone) => {
    if (zoneType.id) {
      // reset state, for to clear all existing values in the state
      dispatch(resetSubjectState());
      dispatch(resetDeviceState());

      LOCAL_STORAGE.setZone(zoneType.id);
      if (setSelectedZone) {
        setSelectedZone(zoneType.id);
      }
      const currentPath = history.location.pathname.split("/")?.reverse();
      if (
        currentPath.length &&
        currentPath[0] !== "subjects" &&
        currentPath[0] !== "devices" &&
        currentPath[0] !== "users"
      ) {
        if (currentPath.length > 0 && currentPath[0] === "settings") {
          gotTo("adminstration/settings");
        } else if (currentPath.length > 0 && currentPath[1] === "subjects") {
          gotTo("adminstration/zones/subjects");
        } else if (currentPath.length > 0 && currentPath[1] === "devices") {
          gotTo("adminstration/zones/devices");
        } else if (currentPath.length > 0 && currentPath[1] === "users") {
          // gotTo("adminstration/zones/users");
          gotTo("adminstration/zones/users");
        } else {
          gotTo("adminstration/zones/devices");
        }
      }
    }
  };

  const topRef = createRef<HTMLDivElement>();
  const secondRef = createRef<HTMLDivElement>();
  const treeRef = createRef<HTMLDivElement>();
  const PanelContext = useContext<ContextInterface | null>(SplitPanelContext);

  useEffect(() => {
    PanelContext?.setTreeRef(treeRef?.current);
  }, [treeRef, PanelContext]);

  useEffect(() => {
    if (!topRef.current || !secondRef.current) return;
    if (!PanelContext) {
      return;
    }
    if (!PanelContext.clientWidth) {
      PanelContext.setClientWidth(topRef.current.clientWidth / 2);
      return;
    }
    topRef.current.style.minWidth = `${PanelContext.clientWidth}px`;
    topRef.current.style.maxWidth = `${PanelContext.clientWidth}px`;
    secondRef.current.style.minWidth = `${PanelContext.clientWidth}px`;
    secondRef.current.style.maxWidth = `${PanelContext.clientWidth}px`;
  }, [PanelContext, secondRef, topRef]);

  useEffect(() => {
    if (treeRef?.current === undefined || treeRef?.current === null) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!treeRef?.current || !PanelContext?.clientWidth) return;

      const treeWidth = treeRef?.current?.clientWidth;
      if (treeWidth > PanelContext.clientWidth)
        PanelContext?.setClientWidth(treeWidth);
    });

    resizeObserver.observe(treeRef.current);
  });

  return (
    <div
      style={{
        flexDirection: "column",
        backgroundColor: "white",
        display: "flex",
      }}
    >
      <FlexOne
        ref={topRef}
        style={{
          minWidth: 256,
          overflow: "hidden auto",
          maxHeight: "calc(100vh - 55px)",
          position: "fixed",
        }}
      >
        <MenuItemStyled
          sx={{ height: "40px", marginTop: 2, cursor: "default" }}
          disable_mouse_over={1}
        >
          <SvgIconStyled>
            <AccountTreeIcon fontSize="small" />
          </SvgIconStyled>
          <Typography variant="inherit">{t("Zones")}</Typography>
        </MenuItemStyled>
        <TreeViewComponent
          data={zones}
          handleChange={handleChange}
          setZones={setZones}
          activeZone={selectedZone || ""}
          authorizedZones={authUser.zones?.data.map((item: IZone) => item.id)}
          treeRef={treeRef}
        />
      </FlexOne>
      <FlexOne style={{ minWidth: 256 }} ref={secondRef} />
    </div>
  );
};

export default ZoneSidebar;
