import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails } from "@mui/material";
import { SwitchStyled } from "components/OnOffSwitch/OnOffFormSwitch";
import SMConfirmDialog from "components/SMDialogs/SMConfirmDialog";
import SMFormTimePicker from "components/SMFormComponents/SMFormTimePicker";
import UseAdminContext from "hooks/UseAdminContext";
import {
  DEFAULT_NIGHT_REPORT_END,
  DEFAULT_NIGHT_REPORT_START,
} from "interfaces/nightReport.interface";
import { IZone } from "interfaces/zone.interface";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateZone } from "store/actions/administration/zoneActions";
import { IRootState } from "store/reducers";
import {
  AccordionStyled,
  AccordionSummaryStyled,
  BoxPrimary,
  InputLabelStyled,
} from "styled/CommonStyled";
import getErrorInputElement from "utils/common/getErrorInputElement";

export const findAncestorWithNightReportTimes = (
  zone: IZone,
  zones: IZone[],
): IZone => {
  if (!zone.parent_id) return zone;

  const parentZone = zones.find((z) => z.id === zone.parent_id);

  if (parentZone === undefined) return zone;

  if (parentZone.settings?.night_report_times) {
    return parentZone;
  }
  return findAncestorWithNightReportTimes(parentZone, zones);
};

const NightReportSettings = ({
  selectedZone,
  disabled,
}: {
  selectedZone: IZone;
  disabled: boolean;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { setSelectedZone } = UseAdminContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const zoneReducer: IZone[] = useSelector(
    (state: IRootState) => state.zoneReducer.zones ?? [],
  );

  const [zone, setZone] = useState<IZone>(selectedZone);
  const [parentZone, setParentZone] = useState<IZone | null>(null);

  useEffect(() => {
    const newZone =
      zoneReducer.find((z) => z.id === selectedZone.id) || zoneReducer[0];
    if (newZone === zone) return;

    setZone(newZone);
  }, [zoneReducer, selectedZone, zone]);

  useEffect(() => {
    setParentZone(findAncestorWithNightReportTimes(zone, zoneReducer));
  }, [zone, zoneReducer]);

  const hasCustomTimes = useCallback(() => {
    const parentTimes = parentZone?.settings?.night_report_times;
    const zoneTimes = zone?.settings?.night_report_times;
    if (zoneTimes === undefined) return false;
    if (parentTimes !== undefined) return true;
    return (
      zoneTimes.start !== DEFAULT_NIGHT_REPORT_START ||
      zoneTimes.end !== DEFAULT_NIGHT_REPORT_END
    );
  }, [parentZone, zone]);

  const [allowEditing, setAllowEditing] = useState<boolean>(hasCustomTimes());

  useEffect(
    () => setAllowEditing(hasCustomTimes() || !zone.parent_id),
    [hasCustomTimes, zone],
  );

  const defaultTimes = useMemo(
    () =>
      zone?.settings?.night_report_times ??
      parentZone?.settings?.night_report_times ?? {
        start: DEFAULT_NIGHT_REPORT_START,
        end: DEFAULT_NIGHT_REPORT_END,
      },
    [zone, parentZone],
  );

  const defaultValues = useMemo(
    () => ({
      ...zone,
      settings: {
        ...zone.settings,
        night_report_times: {
          start: defaultTimes.start,
          end: defaultTimes.end,
        },
      },
    }),
    [zone, defaultTimes],
  );

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm<IZone>({
    ...defaultValues,
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const resetNightReportPeriod = () => {
    reset({
      ...getValues(),
      settings: undefined,
    });
    handleSubmit(onSubmit)();
  };

  const onSubmit: SubmitHandler<IZone> = (data) => {
    const dataToSend: IZone = {
      ...zone,
      ...data,
    };
    dispatch(updateZone(dataToSend, resetNightReportPeriod));
  };

  const errorFocusOrder: { [key: string]: string } = {
    name: "a",
    parent_id: "b",
  };

  const onErrors = (err: FieldErrors<IZone>) => {
    const { elem } = getErrorInputElement<IZone>(err, errorFocusOrder);
    if (elem !== null) elem.focus();
  };

  return (
    <AccordionStyled
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      disableGutters
      sx={{ boxShadow: "none" }}
    >
      <AccordionSummaryStyled
        expandIcon={<ExpandMoreIcon />}
        sx={{ borderRadius: expanded ? "8px 8px 0px 0px" : "8px" }}
      >
        {t("night_report_settings")}
      </AccordionSummaryStyled>
      <AccordionDetails sx={{ padding: 0, margin: 1 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <div style={{ margin: 8 }}>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <InputLabelStyled maginbottom={6}>
                  {t("night_report_time")}
                </InputLabelStyled>
                {!allowEditing && parentZone && setSelectedZone && (
                  <div
                    style={{
                      marginLeft: 8,
                      fontSize: 12,
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    {t("inherited_from")}:
                    <BoxPrimary
                      onClick={() => {
                        setSelectedZone(parentZone.id);
                      }}
                    >
                      {parentZone.name}
                    </BoxPrimary>
                  </div>
                )}
              </div>
              <div style={{ display: "flex", gap: 8 }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {t("From")}
                  <Controller
                    name="settings.night_report_times.start"
                    rules={{
                      required: t("timeframe_start_value_error"),
                      validate: {
                        isValidValue: (v) =>
                          !v || v === ""
                            ? t("timeframe_start_value_error")
                            : undefined,
                      },
                    }}
                    control={control}
                    render={({ field, formState }) => (
                      <SMFormTimePicker
                        field={{
                          ...field,
                          value: field.value || defaultTimes.start,
                        }}
                        disabled={disabled || !allowEditing}
                        formState={formState}
                      />
                    )}
                  />
                  {t("To")}
                  <Controller
                    name="settings.night_report_times.end"
                    rules={{
                      required: t("timeframe_end_value_error"),
                      validate: {
                        isValidValue: (v) =>
                          !v || v === ""
                            ? t("timeframe_end_value_error")
                            : undefined,
                      },
                    }}
                    control={control}
                    render={({ field, formState }) => (
                      <SMFormTimePicker
                        field={{
                          ...field,
                          value: field.value || defaultTimes.end,
                        }}
                        disabled={disabled || !allowEditing}
                        formState={formState}
                      />
                    )}
                  />
                </div>
                <SMConfirmDialog
                  title={t("submit")}
                  dialogDesc={t("change_night_report_times")}
                  onDelete={() => handleSubmit(onSubmit)()}
                  buttonOk={t("submit")}
                  buttonCancel={t("Cancel")}
                  bgColor="green"
                  disableWarning
                  disableTitle
                  disableDelete={
                    !allowEditing ||
                    dirtyFields.settings === undefined ||
                    dirtyFields.settings.night_report_times === undefined
                  }
                />
              </div>
            </div>
          </form>
          <div style={{ flexGrow: 1 }} />
          {zone.parent_id && (
            <div>
              <span style={{ marginRight: 4 }}>
                {t("custom_night_report_times")}
              </span>
              <SwitchStyled
                checked={allowEditing}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setAllowEditing(event.target.checked);
                  if (!event.target.checked) resetNightReportPeriod();
                }}
              />
            </div>
          )}
        </div>
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default NightReportSettings;
