import axios from "axios";

import { ISubject } from "interfaces/subject.interface";
import { axiosGET } from "services/axios/axios";

import { API_SERVICES } from "../../constants/constants";
import {
  handleError,
  handleResponse,
  showErrorFeedback,
} from "../responseHandler";

interface PromiseFulfilledResult<T> {
  status: "fulfilled";
  value: T;
}

export const subjectServices = {
  fetchSubjects,
  createSubject,
  updateSubject,
  deleteSubject,
  fetchSubject,
  getAllSubjectsExcludeAssigned,
};

/**
 * This function fetch all the subject
 * @param none
 * @return all the subjects in the system
 */
async function fetchSubjects(
  path?: string,
  exclude_assigned_to_device_type?: boolean,
) {
  const url = `${API_SERVICES.subjects}${
    path ? `?path=${path}&embed=devices` : "?embed=devices"
  }${
    exclude_assigned_to_device_type
      ? "&exclude_assigned_to_device_type=vitalthings-somnofy-sm100"
      : ""
  }`;
  return axiosGET(url);
}

//
async function getAllSubjectsExcludeAssigned(path: string, exception: string) {
  //
  const getUnassignedSubjects = fetchSubjects(path, true);
  const currentAssigned = exception
    ? fetchSubject(exception)
    : Promise.resolve();
  return Promise.allSettled([currentAssigned, getUnassignedSubjects]).then(
    (results) =>
      results.filter(
        (item) => item.status !== "rejected",
      ) as PromiseFulfilledResult<ISubject[]>[],
  );
}

/**
 * This function fetch a subject
 * @param none
 * @return all the subjects in the system
 */
async function fetchSubject(id?: string) {
  const url = `${API_SERVICES.subjects}/${id}`;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

/**
 * This function create a subject
 * @param name - subject object
 * @return created subject
 */
async function createSubject(data: ISubject) {
  const url = API_SERVICES.subjects;
  return axios({
    method: "post",
    url,
    timeout: 30000,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

/**
 * This function to update a subject
 * @param name - subject object
 * @return created subject
 */
async function updateSubject(data: ISubject) {
  const url = `${API_SERVICES.subjects}/${data.id}`;
  return axios({
    method: "patch",
    url,
    timeout: 30000,

    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

/**
 * This function to delete a subject
 * @param name - subject object
 * @return success response
 */
async function deleteSubject(data: ISubject) {
  const url = `${API_SERVICES.subjects}/${data.id}`;
  return axios({
    method: "delete",
    url,
    timeout: 30000,

    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}
