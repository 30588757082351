import { MouseEvent, TouchEvent, useContext } from "react";

import SplitPaneContext from "./SplitPanelContext";
import { DividerRow } from "./SplitPanelStyledComponents";

export const SplitPanelDivider = ({
  cursor,
}: {
  cursor?: "row-resize" | "col-resize" | "ew-resize";
}) => {
  const PaneContext = useContext(SplitPaneContext);
  return (
    <DividerRow
      onMouseDown={(e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        PaneContext?.onMouseHoldDown(e);
      }}
      onTouchStart={(e: TouchEvent<HTMLDivElement>) => {
        PaneContext?.onMouseHoldDown(e);
      }}
      style={{ cursor }}
    >
      <div />
    </DividerRow>
  );
};
