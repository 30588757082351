import axios from "axios";

import { API_SERVICES } from "../../constants/constants";
import { handleError, handleResponse } from "../responseHandler";

export const nightReportServices = {
  fetchNightReport,
};

/**
 * This function fetch live monitor data
 * @param none
 * @return all the monitor data
 */
async function fetchNightReport(subjects: string[], report_date: string) {
  let subjectIDs = [...subjects];
  if (subjectIDs === undefined || subjectIDs.length === 0) subjectIDs = [""];
  // Construct the base URL
  let url = `${API_SERVICES.reports}?`;
  // Append each subject to the URL
  subjectIDs.forEach((subject) => {
    url += `&subjects=${encodeURIComponent(subject)}`;
  });
  url += `&report_date=${report_date}`;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => handleResponse(response))
    .catch((error: any) => handleError(error));
}
