import { ITenant } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";

export const FETCH_TENANTS = "FETCH_TENANTS";
export const FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS";
export const GET_AUTH_USER_SUCCESS_ACTION = "GET_AUTH_USER_SUCCESS_ACTION";
export const GET_AUTH_USER = "GET_AUTH_USER";
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const UPDATE_AUTH_USER_SUCCESS = "UPDATE_AUTH_USER_SUCCESS";
export const GET_AUTH_ZONES_SUCCESS_ACTION = "GET_AUTH_ZONES_SUCCESS_ACTION";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";

export const RESET_TENANTS = "RESET_TENANTS";

export interface resetAuthStateActionType {
  type: typeof RESET_AUTH_STATE;
}
export interface resetTenantsStateActionType {
  type: typeof RESET_TENANTS;
}

export interface fetchTenantsActionType {
  type: typeof FETCH_TENANTS;
}
export interface fetchTenantsSuccessActionType {
  type: typeof FETCH_TENANTS_SUCCESS;
  payload: ITenant[];
}

export interface getAuthUserActionType {
  type: typeof GET_AUTH_USER;
}
export interface getAuthUserSuccessActionType {
  type: typeof GET_AUTH_USER_SUCCESS_ACTION;
  payload: IUser;
}

export interface getAuthUserZonesSuccessActionType {
  type: typeof GET_AUTH_ZONES_SUCCESS_ACTION;
  payload: IZone[];
}

export interface updateAuthUserActionType {
  type: typeof UPDATE_AUTH_USER;
}
export interface updateAuthUserSuccessActionType {
  type: typeof UPDATE_AUTH_USER_SUCCESS;
  payload: IUser;
}

export type authActionTypes =
  | fetchTenantsActionType
  | fetchTenantsSuccessActionType
  | getAuthUserActionType
  | getAuthUserSuccessActionType
  | updateAuthUserActionType
  | updateAuthUserSuccessActionType
  | resetAuthStateActionType
  | resetTenantsStateActionType
  | getAuthUserZonesSuccessActionType;
