import DehazeIcon from "@mui/icons-material/Dehaze";
import { Box, IconButton } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { MouseEvent, useRef, useState } from "react";

import { IColorValue } from "interfaces/color.interface";
import { SMMenuProps } from "interfaces/menu.interface";
import { ButtonStyled } from "styled/CommonStyled";

export default function CustomizedMenus({
  onClick,
  menus,
  menuLabel,
  manuLabelComponent,
  hoverColor,
  title,
}: {
  onClick: (route: SMMenuProps) => void;
  menus: SMMenuProps[];
  menuLabel?: string;
  manuLabelComponent?: JSX.Element;
  hoverColor?: IColorValue;
  title?: string;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | SVGSVGElement>(
    null,
  );
  const open = Boolean(anchorEl);
  const ref = useRef<any>();
  const handleClick = (
    event: MouseEvent<HTMLElement> | MouseEvent<SVGSVGElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const handleClose = (e: MouseEvent<HTMLElement>, routeValue: string) => {
    if (routeValue !== "backdropClick") {
      const route = menus.find(
        (item: SMMenuProps) => item.value === routeValue,
      );
      if (route) {
        onClick(route);
      }
    }
    setAnchorEl(null);
  };

  const getMenuButtonComponent = () => {
    if (menuLabel) {
      return (
        <ButtonStyled
          aria-haspopup="true"
          variant="contained"
          disableElevation
          onClick={handleClick}
        >
          {menuLabel || ""}
        </ButtonStyled>
      );
    }
    return (
      <IconButtonStyled onClick={handleClick}>
        <DehazeIcon />
      </IconButtonStyled>
    );
  };
  return (
    <div ref={ref}>
      {!manuLabelComponent ? (
        getMenuButtonComponent()
      ) : (
        <DivStyled onClick={handleClick} aria-hidden>
          {manuLabelComponent}
        </DivStyled>
      )}
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        container={ref?.current}
        hover={hoverColor}
      >
        {title && (
          <MenuItem
            disableRipple
            sx={{
              "&:hover": {
                background: "none",
              },
            }}
          >
            <Box sx={{ width: "32px", height: "26px" }} />
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {title}
            </Box>
          </MenuItem>
        )}
        {menus
          .filter((item: SMMenuProps) => !item.hide)
          ?.map((item: SMMenuProps) => {
            return (
              <div key={item.value}>
                <MenuItem
                  onClick={(e: MouseEvent<HTMLElement>) =>
                    handleClose(e, item.value)
                  }
                  disableRipple
                >
                  {item?.icon ? <span>{item.icon}</span> : ""} {item.label}
                </MenuItem>
              </div>
            );
          })}
      </StyledMenu>
    </div>
  );
}

const IconButtonStyled = styled(IconButton)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    cursor: "pointer",
    padding: 5,
    borderRadius: 4,
    height: "auto",

    "&:hover": {
      background: "#FFF",
    },
  };
});

const DivStyled = styled("div")(() => {
  return {
    position: "relative",
    minHeight: 34,
  };
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ hover }: { hover?: IColorValue }) => {
  const theme = useTheme();
  return {
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 180,
      color: "rgb(55, 65, 81)",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        paddingLeft: 16,
        minHeight: 36.5,
        fontSize: 14,
        fontFamily: "Roboto",
        "&:active": {},
        "&:hover": {
          background: hover ?? theme.palette.neutral[3],
        },
      },
      "& svg": {
        marginRight: 8,
        marginTop: 2,
        float: "left",
      },
      "& .MuiList-root": {
        padding: 0,
      },
    },
  };
});
