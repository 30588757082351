import { IColor } from "interfaces/color.interface";

const Blue: Omit<
  IColor,
  0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
> = {
  1: "#FAFDFF",
  5: "#E3EFFF",
  10: "#C4D9F8",
  20: "#8AAEE4",
  30: "#5787D0",
  40: "#2B65BC",
  50: "#0747A8",
};
export default Blue;
