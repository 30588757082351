import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SMFormDropDown from "components/DropDownSelect/SMFormDropDown";
import SMFormTextField from "components/SMTextField/SMFormTextField";
import SMTextField from "components/SMTextField/SMTextField";
import SubTitle from "components/SMTitles/SubTitle";
import SMUpdateCancel from "components/SMUpdateCancel/SMUpdateCancel";
import UseAdminContext from "hooks/UseAdminContext";
import { IZone } from "interfaces/zone.interface";
import {
  createZone,
  updateZone,
} from "store/actions/administration/zoneActions";
import { InputLabelStyled } from "styled/CommonStyled";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import { ApplyMargin } from "styled/StylesStyled";
import getErrorInputElement from "utils/common/getErrorInputElement";
import { goBack, gotTo } from "utils/history.util";
import { setZone } from "utils/localStorage";
import { toastSuccessPositionCenter } from "utils/toast.util";
import { getZoneDropDownOptions } from "utils/zone.util";
import { IRootState } from "store/reducers";

function ZoneInformation({
  create = false,
  zone,
  disabled = false,
}: {
  create?: boolean;
  zone: IZone;
  disabled?: boolean;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zones, setSelectedZone, selectedZone } = UseAdminContext();

  const authUserZones: IZone[] = useSelector(
    (state: IRootState) => state.authReducerV1?.authorizedZones ?? [],
  );

  const defaultValues = useMemo(
    () => ({
      ...zone,
      parent_id: create ? selectedZone || "" : zone.parent_id,
    }),
    [zone, selectedZone, create],
  );

  const { handleSubmit, control, resetField, reset } = useForm<IZone>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onTouched",
  });

  const resetFormFields = useCallback(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  useEffect(() => {
    resetFormFields();
  }, [zone, resetFormFields]);

  const createZoneCallback = (item: IZone) => {
    toastSuccessPositionCenter(t("Zone created successfully"));

    if (item && item.id) {
      setZone(item.id);
      if (setSelectedZone) setSelectedZone(item.id);
      gotTo("adminstration/settings");
    }
  };

  const updateZoneErrorCallBack = () => {
    resetFormFields();
  };
  const onSubmit: SubmitHandler<IZone> = (data) => {
    if (data.id) {
      dispatch(updateZone(data, updateZoneErrorCallBack));
    } else {
      dispatch(createZone(data, createZoneCallback));
    }
  };

  const errorFocusOrder: { [key: string]: string } = {
    name: "a",
    parent_id: "b",
  };

  function onErrors(err: FieldErrors<IZone>) {
    const { elem } = getErrorInputElement<IZone>(err, errorFocusOrder);
    if (elem !== null) elem.focus();
  }

  return (
    <div>
      <BoxSubSectionStyled>
        <SubTitle text={t("Zone Information")} />
        <BoxSubSectionContentStyled padding="16px 20px 34px 16px">
          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
            <Grid container rowSpacing={0} columnSpacing={2.5}>
              <Grid item xs={12} md={4}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Zone name")}
                  </InputLabelStyled>
                  <Controller
                    rules={{ required: `${t("error_message_zone_name")}` }}
                    name="name"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <SMFormTextField
                        field={field}
                        formState={formState}
                        fieldState={fieldState}
                        inlineEdit={!create}
                        disabled={!!disabled}
                        placeholder={t("zone_name_placeholder")}
                        onCancel={() => {
                          resetField("name");
                        }}
                        onUpdate={() => {
                          if (fieldState.isDirty) {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>

              <Grid item xs={12} md={4}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Description (optional)")}
                  </InputLabelStyled>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState, formState }) => (
                      <SMFormTextField
                        field={field}
                        formState={formState}
                        fieldState={fieldState}
                        inlineEdit={!create}
                        disabled={!!disabled}
                        placeholder={
                          disabled ? "" : t("zone_description_placeholder")
                        }
                        onCancel={() => {
                          resetField("description");
                        }}
                        onUpdate={() => {
                          if (fieldState.isDirty) {
                            handleSubmit(onSubmit)();
                          }
                        }}
                      />
                    )}
                  />
                </ApplyMargin>
              </Grid>

              <Grid item xs={12} md={4}>
                <ApplyMargin top={16}>
                  <InputLabelStyled maginbottom={6}>
                    {t("Parent zone")}
                  </InputLabelStyled>

                  {!zone.parent_id && !create ? (
                    <SMTextField disabled defaultValue={t("none")} />
                  ) : (
                    <Controller
                      name="parent_id"
                      rules={{
                        required:
                          create && `${t("error_message_zone_parent_zone")}`,
                        validate: {
                          isParentIDSelected: (value) =>
                            create && value === "0"
                              ? `${t("error_message_zone_parent_zone")}`
                              : undefined,
                        },
                      }}
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <SMFormDropDown
                          disabled={!create || !!disabled}
                          field={field}
                          fieldState={fieldState}
                          formState={formState}
                          placeholder={t("Select a zone")}
                          options={getZoneDropDownOptions(
                            zones,
                            authUserZones.map((item) => item.id),
                          )}
                          width="100%"
                          onUpdate={() => {
                            if (!create && fieldState.isDirty)
                              handleSubmit(onSubmit)();
                          }}
                        />
                      )}
                    />
                  )}
                </ApplyMargin>
              </Grid>
            </Grid>
          </form>
        </BoxSubSectionContentStyled>
      </BoxSubSectionStyled>
      {create && (
        <SMUpdateCancel
          onCancel={() => goBack()}
          onUpdate={handleSubmit(onSubmit, onErrors)}
          create
        />
      )}
    </div>
  );
}

export default ZoneInformation;
