import { Box, Checkbox, Grid } from "@mui/material";
import {
  MouseEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AuthorizationContext,
  UserPermissionEnum,
} from "authorization/AuthorizationContext";
import { can } from "authorization/authorization.util";
import BoxWithPadding from "components/BoxWithPadding/BoxWithPadding";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import SMDynamicTable from "components/SMDynamicTable/SMDynamicTable";
import SMNoDataBox from "components/SMDynamicTable/SMNoDataBox";
import { basePath } from "constants/constants";
import { getZoneUserRolesAsEntity } from "constants/userContstants";
import UseAdminContext from "hooks/UseAdminContext";
import { IRole, IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import { getAllUsers } from "store/actions/administration/userActions";
import { getAuthUser } from "store/actions/auth/authActions";
import { IRootState } from "store/reducers";
import {
  ChipRectangularStyled,
  ChipStyled,
  DivCircle,
  FormControlLabelStyled,
  SMTableWrapperBox,
} from "styled/CommonStyled";
import { getAvatar } from "utils/administration/user.util";
import * as LOCAL_STORAGE from "utils/localStorage";

interface HeadCell {
  id: keyof Data;
  label: string;
  textCenter?: boolean;
  hideSort?: boolean;
}
interface Data {
  user: string[];
  email: string;
  zone: string;
  roles: string[];
  profession: string;
  _id: string;
}

const UserLandingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { permissions } = useContext(AuthorizationContext);
  const [zoneUsers, setZoneUsers] = useState<IUser[]>();

  const { setCurrentPath, selectedZone, zoneEntity } = UseAdminContext();

  const usersState = useSelector(
    (state: IRootState) => state.usersReducer ?? [],
  );

  const adminSettings = LOCAL_STORAGE.getAdminSettings() || {};
  const [showFullAccess, setShowFullAccess] = useState<boolean>(
    adminSettings.showFullAccess ?? true,
  );

  useEffect(() => {
    if (setCurrentPath) {
      setCurrentPath(undefined);
    }
    dispatch(getAuthUser());
    dispatch(getAllUsers());

    return () => {};
  }, [dispatch, setCurrentPath]);

  useEffect(() => {
    if (!usersState.users || !zoneEntity || !selectedZone) {
      return setZoneUsers([]);
    }
    const currentZone = zoneEntity[selectedZone];
    if (!currentZone) return;

    const currentZonePaths = currentZone.path.split(".");
    const filtered: IUser[] = [];
    usersState.users.forEach((user: IUser) => {
      if (
        !user.zones.data.length &&
        !currentZone.parent_id &&
        !showFullAccess
      ) {
        filtered.push({
          ...user,
          metadata: { ...user.metadata, zone_access: "none" },
        });
      }
      for (let i = 0; i < user.zones.data?.length; i += 1) {
        const item: IZone = user.zones.data[i];
        const paths = item.path.split(".");

        if (item.id === currentZone.id) {
          filtered.push({
            ...user,
            metadata: { ...user.metadata, zone_access: "full" },
          });
          break;
        }
        if (paths.indexOf(currentZone.id) !== -1 && !showFullAccess) {
          filtered.push({
            ...user,
            metadata: { ...user.metadata, zone_access: "partial" },
          });
          break;
        }

        if (currentZonePaths.indexOf(item.id) !== -1) {
          filtered.push({
            ...user,
            metadata: { ...user.metadata, zone_access: "full" },
          });
          break;
        }
      }
    });
    setZoneUsers(filtered);
  }, [usersState?.users, selectedZone, zoneEntity, showFullAccess]);

  if (!usersState) {
    return <div />;
  }

  const handleClick = (event: MouseEvent<unknown>, row: Data) => {
    if (can(permissions, UserPermissionEnum["user.update"])) {
      history.push(`${basePath}/adminstration/zones/users/${row._id}`);
    }
  };

  const getHeadCells = (): HeadCell[] => {
    return [
      {
        id: "user",
        label: t("employee"),
      },
      {
        id: "profession",
        label: t("job_title"),
      },
      {
        id: "email",
        label: t("Email"),
      },
      {
        id: "zone",
        label: t("zone_access"),
        textCenter: true,
      },
      {
        id: "roles",
        label: t("Role(s)"),
        hideSort: true,
      },
    ];
  };

  const getUserZoneText = (user: IUser) => {
    if (user.metadata?.zone_access === "full") {
      return "full";
    }
    if (user.metadata?.zone_access === "partial") {
      return "partial";
    }
    if (user.metadata?.zone_access === "none") {
      return "none";
    }
    return "\u00A0";
  };

  const getCheckBoxComponent = (): JSX.Element => {
    return (
      <Grid container justifyContent="flex-end" sx={{ marginBottom: "8px" }}>
        <FormControlLabelStyled
          checked
          onChange={(e: SyntheticEvent<Element, Event>, checked: boolean) => {
            const settings = LOCAL_STORAGE.getAdminSettings();
            LOCAL_STORAGE.setAdminSettings({
              ...settings,
              showFullAccess: checked,
            });
            setShowFullAccess(checked);
          }}
          control={<Checkbox checked={showFullAccess} />}
          label={t("show_only_users_with_full_zone_access") as string}
        />
      </Grid>
    );
  };

  const getRows = (users: IUser[]): Data[] => {
    const usersRow: Data[] = [];
    if (users && Array.isArray(users)) {
      users.forEach((user: IUser) => {
        usersRow.push({
          user: [
            `${getAvatar(user.first_name || "")}${getAvatar(
              user.last_name || "",
            )}`,
            `${user.first_name || ""} ${user.last_name || ""}`,
          ],
          profession: user.profession || "\u00A0",
          email: user.email || "",
          zone: getUserZoneText(user),
          roles: user.roles,
          _id: user.id,
        });
      });
    }
    return usersRow;
  };

  const renderComponentForValue = {
    user: (username: string[]): JSX.Element => {
      const [avatar, name] = username;
      return (
        <>
          <DivCircle>
            <div> {avatar}</div>
            <span>{name}</span>
          </DivCircle>
        </>
      );
    },
    zone: (name: string): JSX.Element => {
      let background: string = "grey";
      if (name === "full") {
        background = "green";
      } else if (name === "partial") {
        background = "blue";
      }
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ChipRectangularStyled
            label={t(name)}
            bg={background}
            minWidth={73}
          />
          <Box sx={{ visibility: "hidden" }} width="24px" />
        </Box>
      );
    },
    roles: (rolesParam: string[]) => {
      const roleEntity: { [key: string]: IRole } = getZoneUserRolesAsEntity(t);
      const selectedRoles: IRole[] = [];
      rolesParam.forEach((role) => {
        if (roleEntity[role]) {
          selectedRoles.push(roleEntity[role]);
        }
      });
      selectedRoles.sort((a: IRole, b: IRole) => a.order - b.order);
      return (
        <>
          {selectedRoles.map((role, index: number) => {
            return (
              <ChipStyled
                bg={role.activeChipTheme as string}
                label={role.name}
                key={index}
                sx={{
                  marginRight: "12px",
                  marginBottom: "8px",
                }}
                disableMouseover={1}
              />
            );
          })}
        </>
      );
    },
  };

  if (zoneUsers === undefined) {
    return <div />;
  }
  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="User Landing Page" />

      <BoxWithPadding pad="0px 24px 24px 4px">
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <div>{getCheckBoxComponent()}</div>
        </Box>
        <Grid container>
          <Grid item xs={12} md={12} columnGap={3}>
            {zoneUsers.length ? (
              <SMTableWrapperBox>
                <div style={{ padding: 1 }}>
                  <SMDynamicTable<Data>
                    headCells={getHeadCells()}
                    rows={getRows(zoneUsers)}
                    onRowClick={(event: MouseEvent<unknown>, row: Data) =>
                      handleClick(event, row)
                    }
                    renderComponentForValue={renderComponentForValue}
                    defaultOrderBy="user"
                    localSettingKey={"users_sort_config"}
                  />
                </div>
              </SMTableWrapperBox>
            ) : (
              ""
            )}

            {!zoneUsers.length && (
              <SMNoDataBox
                message={t("there_are_no_employees_in_this_zone")}
                title={(zoneEntity && zoneEntity[selectedZone]?.name) || ""}
                bodyBg="white"
              />
            )}
          </Grid>
        </Grid>
      </BoxWithPadding>
    </>
  );
};

export default UserLandingPage;
