import { createContext } from "react";

import { IWIPMessage } from "interfaces/wipMessage.interface";

export type AppGlobalContextType = {
  appWarningMessage: IWIPMessage | null;
};

export const GlobalContext = createContext<AppGlobalContextType | null>({
  appWarningMessage: null,
});
