import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { REDIRECT_URL } from "constants/auth.constants";
import { OVERVIEW_PAGE } from "constants/constants";
import { IAccessTokenPayload, IAuthResponse } from "interfaces/auth.interface";
import {
  clearCookies,
  getTokenParams,
  shouldRefreshAccessToken,
} from "utils/auth/auth.util";
import { gotTo } from "utils/history.util";
import * as LOCAL_STORAGE from "utils/localStorage";

const UseAuth = (tokenUrl: string) => {
  const { search } = useLocation();
  const [loginError, setLoginError] = useState<boolean>(false);

  const getToken = useCallback(
    (dataStringified: string) => {
      axios({
        method: "post",
        url: tokenUrl,
        timeout: 30000,
        data: dataStringified,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response: AxiosResponse<IAuthResponse>) => {
          // set login error as false
          setLoginError(false);
          if (response.data?.access_token) {
            const decodedToken = jwtDecode<IAccessTokenPayload>(
              response.data.access_token,
            );
            const accessTokenLifetime = response.data.expires_in || 0;
            const accessTokenExpiryTime = new Date();
            accessTokenExpiryTime.setTime(
              accessTokenExpiryTime.getTime() + accessTokenLifetime * 1000,
            );
            Cookies.set("access_token", response.data.access_token, {
              expires: accessTokenExpiryTime,
            });
            Cookies.set("userId", response.data.userId, {
              expires: accessTokenExpiryTime,
            });

            if (shouldRefreshAccessToken(decodedToken.roles)) {
              Cookies.set("refresh_token", response.data.refresh_token);
            }

            // landing page
            gotTo(OVERVIEW_PAGE);
          }
        })
        .catch(() => {
          clearCookies();
          setLoginError(true);
        });
    },
    [tokenUrl],
  );

  useEffect(() => {
    const code = new URLSearchParams(search).get("code");
    const responseState = new URLSearchParams(search).get("state");
    const userState = new URLSearchParams(search).get("userState");

    const state = sessionStorage.getItem("state");
    const code_verifier = sessionStorage.getItem("code_verifier");
    const client_id = LOCAL_STORAGE.getClientId();
    if (
      userState &&
      responseState &&
      state &&
      code &&
      client_id &&
      code_verifier &&
      !Cookies.get("access_token") &&
      userState === "Authenticated" &&
      state === responseState
    ) {
      const params: any = getTokenParams({
        code,
        client_id,
        code_verifier,
        redirectUri: REDIRECT_URL,
      });

      const dataStringified: string = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");
      getToken(dataStringified);
    } else {
      gotTo("auth/login", true);
    }
  }, [getToken, search]);

  return { loginError };
};
export default UseAuth;
