import { Box, Grid, Stack } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import SMButtonNew from "components/SMButton/SMButtonNew";
import SMTimePicker from "components/SMFormComponents/SMTimePicker";

function TimeRange({
  onSelect,
  selected,
}: {
  onSelect: (option: string) => void;
  selected: string;
}) {
  const defaultValue = selected.split("_");
  const [from, to] = defaultValue?.length > 1 ? defaultValue : ["", ""];
  const [timeRange, setTimeRange] = useState<{ from: string; to: string }>({
    from,
    to,
  });
  const { t } = useTranslation();

  const applyFilter = () => {
    if (timeRange.from && timeRange.to) {
      onSelect(`${timeRange.from}_${timeRange.to}`);
    }
  };

  return (
    <Box>
      <Grid container rowSpacing="16px" columnSpacing="32px">
        <Grid item xs={12} md={6}>
          <SMTimePicker
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTimeRange((prev) => ({ ...prev, from: e.target.value }))
            }
            value={timeRange.from}
            placeholder={t("e.g. 21:30")}
            width={152}
            allowKeyDown
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SMTimePicker
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTimeRange((prev) => ({ ...prev, to: e.target.value }))
            }
            value={timeRange.to}
            placeholder={t("e.g. 07:00")}
            width={152}
            allowKeyDown
          />
        </Grid>
      </Grid>
      <Stack direction="row-reverse" sx={{ marginTop: "20px" }}>
        <SMButtonNew
          text={t("apply_filter")}
          onClick={applyFilter}
          disabled={!(timeRange.from && timeRange.to)}
        />
      </Stack>
    </Box>
  );
}

export default TimeRange;
