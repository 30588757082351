export interface ISession {
  id: string;
  state: string;
  subject_id: string;
  device_serial_number: string;
  epoch_count: number;
  session_start: Date;
  relative_session_start: number;
  session_end: Date;
  time_at_last_epoch: Date;
  time_at_intended_sleep: Date;
  time_at_intended_wakeup: Date;
  time_in_undefined: number;
  time_at_sleep_onset: Date;
  time_at_wakeup: Date;
  time_at_midsleep: Date;
  sleep_onset_latency: number;
  sleep_period: number;
  time_in_bed: number;
  time_asleep: number;
  sleep_efficiency: number;
  time_in_light_sleep: number;
  time_in_rem_sleep: number;
  time_in_deep_sleep: number;
  time_in_no_presence: number;
  number_of_times_no_presence: number;
  time_wake_after_sleep_onset: number;
  number_of_times_awake: number;
  number_of_times_awake_long: number;
  time_wake_pre_post_sleep: number;
  time_from_sleep_onset_to_first_rem: number;
  movement_fast_during_sleep_period_mean: number;
  rpm_non_rem_filtered_mean: number;
  rpm_non_rem_mean_var: number;
  rpm_non_rem_baseline: number;
  rpm_non_rem_baseline_std: number;
  heart_rate_non_rem_filtered_mean: number;
  heart_rate_non_rem_mean: number;
  external_heart_rate_non_rem_filtered_mean: number;
  epochs_with_movement_pct: number;
  epochs_with_movement_during_time_in_bed_pct: number;
  time_with_movement_pct: number;
  time_with_movement_during_time_in_bed_pct: number;
  sleep_cycles_count: number;
  is_workday: boolean;
  chronotype: number;
  social_jet_lag: number;
  jet_lag: number;
  regularity: number;
  sleep_score_standard: number;
  sleep_fragmentation: number;
  external_spo2_mean: number;
  external_spo2_min: number;
  distance_during_sleep_mean: number;
  distance_during_sleep_std: number;
  air_pressure_filtered_mean: number;
  light_ambient_filtered_mean: number;
  light_ambient_at_wakeup_mean: number;
  sound_amplitude_filtered_mean: number;
  sound_amplitude_during_sleep_mean: number;
  sound_amplitude_during_sleep_filtered_for_noise_mean: number;
  sound_amplitude_during_sleep_filtered_for_movement_mean: number;
  sound_amplitude_at_wakeup: number;
  epochs_with_sound_spikes_during_sleep_count: number;
  awakenings_caused_by_sound_count: number;
  temperature_filtered_mean: number;
  indoor_air_quality_filtered_mean: number;
  air_humidity_filtered_mean: number;
  epoch_data?: ISleepEpoch;
}

export interface IPlotData {
  ix: number;
  x: number;
  y: number | null;
  sessionId?: string;
}

export interface IHistoryStagesPlotData {
  timeInDeepSleep: IPlotData[];
  timeInLightSleep: IPlotData[];
  timeInREMSleep: IPlotData[];
  timeWakeAfterSleepOnset: IPlotData[];
  sleepOnset: IPlotData[];
  timeAsleep: IPlotData[];
  timeWakePrePostSleep: IPlotData[];
  relativeSessionStart: IPlotData[];
  wake_after_sleep: IPlotData[];
}
export interface IHistoryVitalsPlotData {
  timeAsleep: IPlotData[];
  sleepOnset: IPlotData[];
  sleepEfficiency: IPlotData[];
  sleepScore: IPlotData[];
  numberOfTimesAwakeLong: IPlotData[];
  numberOfTimesNoPresence: IPlotData[];
  nonREMMeanRespirationRate: IPlotData[];
  nonREMMeanHeartRate: IPlotData[];
  nonREMMeanExternalHeartRate: IPlotData[];
  externalSpO2Mean: IPlotData[];
  epochsWithMovementPct: IPlotData[];
  lightAmbientMean: IPlotData[];
  soundAmplitudeMean: IPlotData[];
}

export type SleepStagesOptionsType = {
  series: IHistoryStagesPlotData;
  threshold: number;
};

export interface IHistoryStagePiePlotData {
  timeAwakeAfterSleepOnset: number;
  timeInREMSleep: number;
  timeInLightSleep: number;
  timeInDeepSleep: number;
  timeInNoPresence: number;
  timeAwake: number;
}

export interface ISleepEpoch {
  timestamp: string[];
  respiration_rate_mean: number[];
  respiration_rate_var: number[];
  movement_fast_mean: number[];
  movement_fast_nonzero_pct: number[];
  heart_rate_mean: number[];
  heart_rate_var: number[];
  external_heart_rate_mean: number[];
  external_heart_rate_var: number[];
  external_spo2_mean: number[];
  external_spo2_var: number[];
  external_spo2_min: number[];
  distance_mean: number[];
  motion_data_count: number[];
  light_ambient_mean: number[];
  sound_amplitude_mean: number[];
  temperature_ambient_mean: number[];
  pressure_mean: number[];
  humidity_mean: number[];
  indoor_air_quality_mean: number[];
  epoch_duration: number[];
  signal_quality_mean: number[];
  sleep_stage: number[];
}

export interface ISleepEpochPlotData {
  hypnogram: IPlotData[];
  timestamp: IPlotData[];
  distanceMean: IPlotData[];
  respirationRateMean: IPlotData[];
  respirationRateVarMean: IPlotData[];
  heartRateMean: IPlotData[];
  heartRateVar: IPlotData[];
  externalHeartRateMean: IPlotData[];
  externalHeartRateVar: IPlotData[];
  externalSpO2Mean: IPlotData[];
  movementMean: IPlotData[];
  signalQualityMean: IPlotData[];
  lightAmbient: IPlotData[];
  soundAmplitude: IPlotData[];
}

// chart type enum
// TODO: consider using HighchartsType type instead of enum so values can be used directly for the highcharts type prop
export enum ChartTypes {
  "area_chart" = "area_chart",
  "column_chart" = "column_chart",
}
export type HighchartsType = "areaspline" | "column"; // There are others as well but we should add them here as we apply them
