import "./css/index.css";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import store from "./store/index";

export const history = createBrowserHistory();

const domNode = document.getElementById("root");
const root = createRoot(domNode!);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={
            process.env.REACT_APP_PRODUCTION === "true"
              ? "upgrade-insecure-requests"
              : ""
          }
        />
        <style type="text/css" media="print">{`
            @page {
                size: auto;
                margin: 0mm;
            }
            body
            {
                margin: 0px;
            }
        `}</style>
      </Helmet>
      <App />
    </Router>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.unregister();
