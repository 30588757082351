import CloseIcon from "@mui/icons-material/Close";
import { Chip, ChipProps, Menu, SvgIcon, svgIconClasses } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, styled } from "@mui/system";
import { MouseEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { SleepHistoryContext } from "@modules/sleepHistory/SleepHistoryContext";
import { IColorValue } from "interfaces/color.interface";
import { IFilter } from "interfaces/filters.interface";

import { getTagNames } from "./SMMenuComponents/MenuOptions";
import RenderSleepHistoryFilterOptions from "./SMMenuComponents/RenderSleepHistoryFilterOptions";

const bgMap = {
  timeInBed: "teal",
  totalSleepTime: "blue",
  sessionStartTime: "purple",
};

const StyledChip = styled(Chip)(({ bg }: { bg: string }) => {
  const theme = useTheme();
  return {
    height: "32px",
    padding: "4px 8px 4px 12px",
    borderRadius: 4,
    // @ts-ignore
    background: `${theme.palette[`${bg}`][40]}` as IColorValue,
    color: "#FFF",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    "&:hover": {
      // @ts-ignore
      background: `${theme.palette[`${bg}`][30]}` as IColorValue,
    },
    [`& .${svgIconClasses.root}`]: {
      color: "#FFF",
    },
    [`& .bold`]: {
      color: "#FFF",
      fontWeight: 700,
    },
  };
});

type ISessionRange = { start: string; end: string };

function SessionFilterTags() {
  const { t } = useTranslation();
  const { sleepHistoryFilters, setSleepHistoryFilters } =
    useContext(SleepHistoryContext);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const [dropDownType, setDropDownType] = useState<keyof IFilter | null>();

  const onDeleteFilter = (key: keyof IFilter) => {
    const value = key === "sessionStartTime" ? { start: "", end: "" } : "";
    setSleepHistoryFilters((prev: IFilter) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const onChipClick = (e: MouseEvent<HTMLDivElement>, key: keyof IFilter) => {
    if (anchorEl) {
      setAnchorEl(null);
      setDropDownType(null);
    } else {
      setAnchorEl(e.currentTarget);
      setDropDownType(key);
    }
  };

  // isNotEmpty
  const isNOTEmpty = (key: keyof IFilter, value: string | ISessionRange) => {
    if (key === "sessionStartTime") {
      const val = value as ISessionRange;
      return val.start !== "" && val.end !== "";
    }
    return (value as string) !== "";
  };

  const getFilterTagText = (
    key: keyof IFilter,
    value: string | ISessionRange,
  ) => {
    if (key === "sessionStartTime") {
      // value should be start, end
      const { start, end } = value as { start: string; end: string };
      return `${start} - ${end}`;
    }
    if (typeof +(value as string) === "number") {
      const hours = +value / 60;
      return `${hours} ${t("hours_lc")} ${t("or_more")}`;
    }
    return "";
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (
    !sleepHistoryFilters ||
    (!sleepHistoryFilters.timeInBed &&
      !sleepHistoryFilters.totalSleepTime &&
      (sleepHistoryFilters.sessionStartTime?.start === "" ||
        sleepHistoryFilters.sessionStartTime?.end === ""))
  ) {
    return <Box>{t("no_session_filters_applied")}</Box>;
  }

  const tagNames = getTagNames();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      {Object.entries(sleepHistoryFilters)
        .filter(([key, value]: [string, ISessionRange | string]) => {
          return isNOTEmpty(key as keyof IFilter, value);
        })
        .map(([key, value]: [string, ISessionRange | string]) => {
          const k = key as keyof IFilter;
          const text = getFilterTagText(k, value);
          return (
            // Removed div here
            <FilterTag
              filterLabel={{ filter: `${tagNames[k]}: `, text }}
              background={bgMap[k]}
              onDelete={() => onDeleteFilter(k)}
              onClick={(e) => onChipClick(e, k)}
              key={k}
              id={k}
            />
          );
        })}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {dropDownType ? (
          <RenderSleepHistoryFilterOptions
            type={dropDownType}
            handleMenuClose={handleMenuClose}
          />
        ) : null}
      </Menu>
    </Box>
  );
}
export default SessionFilterTags;

type IFilterTagProps = ChipProps & {
  filterLabel: { filter: string; text: string };
  background: string;
};
function FilterTag(props: IFilterTagProps) {
  const {
    filterLabel: { filter, text },
    background,
    ...rest
  } = props;
  const { onDelete, onClick } = { ...rest };
  return (
    <StyledChip
      label={
        <Box sx={{ display: "flex" }}>
          <div>{filter}</div>
          <span className="bold">&nbsp;{text}</span>
        </Box>
      }
      clickable
      onDelete={onDelete}
      onClick={onClick}
      deleteIcon={
        <SvgIcon>
          <CloseIcon sx={{ color: "#FFF" }} />{" "}
        </SvgIcon>
      }
      bg={background}
      key={text}
    />
  );
}
