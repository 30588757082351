import { Box, Stack } from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import SMButtonNew from "components/SMButton/SMButtonNew";
import SMFormDiscreteSlider from "components/SMFormComponents/SMFormDiscreteSlider";
import SMTextField from "components/SMTextField/SMTextField";
import { InputEndAdornment } from "styled/CommonStyled";

function HourSlider({
  onSelect,
  selected,
}: {
  onSelect: (time: string) => void;
  selected: string;
}) {
  const defaultHourValue =
    selected && !Number.isNaN(+selected)
      ? minutesToHourMinutes(+selected)
      : 0.5;
  const [hour, setHours] = useState<number>(defaultHourValue);
  const { t } = useTranslation();

  const onUpdate = (val: string) => {
    if (typeof +val === "number") {
      const total = minutesToHourMinutes(+val * 60);
      setHours(total);
    }
  };

  function minutesToHourMinutes(val: number): number {
    const minutes = val as number;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = (minutes % 60) / 60;
    const total = hours + remainingMinutes;
    return total;
  }

  const setState = (val: number) => {
    setHours(val);
  };
  const applyFilter = () => {
    onSelect(`${hour * 60}`);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <SMTextField
          type="number"
          inputProps={{
            step: 0.5,
            min: 0.5,
            max: 12,
          }}
          defaultValue={hour}
          endAdornment={<InputEndAdornment>{t("hours")}</InputEndAdornment>}
          width={116}
          onUpdate={onUpdate}
        />

        <SMFormDiscreteSlider
          field={{
            value: hour ? Math.floor(hour * 60) : 30,
          }}
          steps={30} // .5 hour
          min={30} // .5 hour
          max={720} // 12 hour (12/ 60)
          valueLabelDisplay="off"
          onChangeCommitted={(
            event: Event | SyntheticEvent<Element, Event>,
            value: number | number[],
          ) => {
            const val: number = minutesToHourMinutes(value as number);
            setState(val);
          }}
        />
      </Box>
      <Stack direction="row-reverse" sx={{ marginTop: "20px" }}>
        <SMButtonNew text={t("apply_filter")} onClick={applyFilter} />
      </Stack>
    </Box>
  );
}

export default HourSlider;
