import { Box, Theme } from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { useTheme } from "@mui/material/styles";
import { MouseEvent, memo, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  AuthorizationContext,
  SubjectPermissionEnum,
} from "authorization/AuthorizationContext";
import { can } from "authorization/authorization.util";
import SMTableHeader from "components/SMTableComponents/SMTableHeader";
import UseAdminContext from "hooks/UseAdminContext";
import { useSortableData } from "hooks/UseSortableData";
import {
  HeadCell,
  Order,
} from "interfaces/componentsTypes/tableComponentsTypes";
import { ISubject } from "interfaces/subject.interface";
import { IZone } from "interfaces/zone.interface";
import { TableBodyStyled } from "styled/TableElementStyled";
import * as LOCAL_STORAGE from "utils/localStorage";

import useFixedHeader from "hooks/UseFixedHeader";
import { MemoizedDeviceStatus, MemoizedSex } from "./SubjectTableRowComponets";

type IProps = {
  headCells: HeadCell[];
  subjects: ISubject[];
  zoneEntity: Record<string, IZone>;
};

const SubjectList = memo(function SubjectList({
  headCells,
  subjects,
  zoneEntity,
}: IProps) {
  let settings = LOCAL_STORAGE.getAdminSettings() || {};
  const appTheme: Theme = useTheme();
  const { permissions } = useContext(AuthorizationContext);
  const history = useHistory();
  const { setSelectedZone } = UseAdminContext();

  // Set default sort order in localStorage Admin Settings
  if (!settings.subject_sort_config) {
    LOCAL_STORAGE.setAdminSettings({
      ...settings,
      subject_sort_config: {
        key: "identifier",
        direction: "asc",
      },
    });
    settings = LOCAL_STORAGE.getAdminSettings();
  }

  const { items, requestSort, sortConfig } = useSortableData(
    subjects,
    settings.subject_sort_config,
    zoneEntity,
  );

  const handleRequestSort = (event: MouseEvent<unknown>, property: string) => {
    // set local storage
    const currentSettings = LOCAL_STORAGE.getAdminSettings();
    LOCAL_STORAGE.setAdminSettings({
      ...currentSettings,
      subject_sort_config: {
        key: property,
        direction: sortConfig?.direction === "asc" ? "desc" : "asc",
      },
    });

    requestSort(property);
  };

  const handleClick = (event: MouseEvent<unknown>, row: ISubject) => {
    if (can(permissions, SubjectPermissionEnum["subject.read"])) {
      if (setSelectedZone) {
        setSelectedZone(row.zone_id);
      }
      history.push(`/adminstration/zones/subjects/${row.id}`);
    }
  };

  const { headerRef, tableWrapperRef, lastChildRef } = useFixedHeader();
  return (
    <div style={{ padding: 1 }}>
      <Box sx={{ width: "100%" }} ref={tableWrapperRef}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <SMTableHeader
              order={sortConfig?.direction || ("asc" as Order)}
              orderBy={(sortConfig?.key as string) || ""}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              headerRef={headerRef}
            />
            <TableBodyStyled>
              {items.map((subject: ISubject, index: number) => (
                <tr
                  key={subject.id}
                  onClick={(event) => handleClick(event, subject)}
                  ref={index === items.length - 1 ? lastChildRef : null}
                >
                  <td>{subject.identifier}</td>
                  <td>{zoneEntity[subject.zone_id]?.name || ""}</td>
                  <td align="center">
                    <MemoizedSex gender={subject.sex} />
                  </td>
                  <td align="center">
                    <div className="flex_center">
                      {subject.birth_year}
                      <Box sx={{ visibility: "hidden" }} width="24px" />
                    </div>
                  </td>
                  <td>{subject?.devices?.data[0]?.name || "-"}</td>
                  <td>
                    <Box
                      sx={{
                        color: appTheme.palette.neutral[80],
                        fontSize: 16,
                        fontFamily: "Roboto Mono, monospace",
                      }}
                    >
                      {subject?.devices?.data[0]?.serial_number || "-"}
                    </Box>
                  </td>
                  <td align="center">
                    <MemoizedDeviceStatus
                      deviceStatus={
                        subject?.devices?.data &&
                        subject?.devices?.data?.length > 0
                          ? subject?.devices?.data[0]?.online
                          : undefined
                      }
                    />
                  </td>
                </tr>
              ))}
            </TableBodyStyled>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
});
export default SubjectList;
