import {
  Checkbox,
  FormControlLabel,
  Tooltip,
  checkboxClasses,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import Highlighted from "components/Highlighted/HighLighted";
import { IZone } from "interfaces/zone.interface";
import { SyntheticEvent, useCallback, useMemo } from "react";
import { flatten } from "utils/zone.util";

enum CheckStates {
  NothingChecked,
  EverythingChecked,
  Indeterminate,
}

const NodeWrapper = styled("div")(
  ({
    level,
    hasChildren,
    levelPadding,
  }: {
    level: number;
    hasChildren?: boolean;
    levelPadding: number;
  }) => {
    return {
      paddingLeft: hasChildren
        ? `${level * levelPadding}px`
        : `${level * levelPadding + 24}px`,
      position: "relative",
    };
  },
);

const ToggleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "collapsed",
})(({ collapsed }: { collapsed: boolean }) => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    width: 24,
    height: 24,
    borderRadius: 0,
    transform: collapsed ? "rotate(-90deg)" : "",
    "&:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.04)",
    },
  };
});

const FormControlLabelStyled = styled(FormControlLabel)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.neutral[80],
    fontSize: 16,
    padding: 6,
    margin: 0,
    alignItems: "flex-start",
    textTransform: "none",
    "& .MuiFormControlLabel-label": {
      fontSize: 16,
    },
    "&.Mui-disabled": {
      cursor: "not-allowed",
    },
    [`& .${checkboxClasses.root}`]: {
      color: theme.palette.main[70],
      width: 12,
      height: 12,
      marginLeft: 1,
      marginRight: 10,
      padding: 0,
      borderRadius: 0,
      marginTop: 4,
      "&.MuiCheckbox-indeterminate": {
        color: theme.palette.main[70],
      },
    },
  };
});

const CheckboxStyled = styled(Checkbox)(
  ({
    itemselected,
    checkstate,
  }: {
    itemselected?: number;
    checkstate: CheckStates;
  }) => {
    const theme = useTheme();
    return {
      "&.Mui-disabled": {
        cursor: "not-allowed",
        color: theme.palette.neutral[10],
        background:
          itemselected || checkstate === CheckStates.Indeterminate
            ? "none"
            : theme.palette.neutral[10],
      },
    };
  },
);

interface NodeWithCheckboxProps {
  item: IZone;
  hasChildren: boolean;
  level: number;
  onToggle: () => void;
  onChecked: (checked: boolean, node: IZone) => void;
  levelPadding: number;
  collapsed: boolean;
  selected: string[];
  search?: string;
  isDisableNode: (node: IZone) => boolean;
  disabled_tooltip_message: string;
  no_permission_tooltip_message: string;
}
const NodeWithCheckbox = ({
  item,
  hasChildren,
  level,
  onToggle,
  onChecked,
  levelPadding,
  collapsed,
  selected,
  search,
  isDisableNode,
  disabled_tooltip_message,
  no_permission_tooltip_message,
}: NodeWithCheckboxProps) => {
  const isDisabled = isDisableNode(item);
  const nodeIsSelected = useCallback(
    (node: IZone) => selected.indexOf(node.id) !== -1,
    [selected],
  );
  const isSelected = nodeIsSelected(item);

  const checkState = useMemo(() => {
    const flattenedChildren = flatten(item).filter(
      (node) => node.id !== item.id,
    );
    const areAllChildrenSelected = flattenedChildren.every(nodeIsSelected);
    const areNoChildrenSelected = !flattenedChildren.some(nodeIsSelected);
    if (areAllChildrenSelected && isSelected)
      return CheckStates.EverythingChecked;
    if (areNoChildrenSelected && !isSelected) return CheckStates.NothingChecked;
    return CheckStates.Indeterminate;
  }, [item, nodeIsSelected, isSelected]);

  const getTooltipMessage = useMemo(() => {
    if (!item.parent_id) return "";

    if (isSelected || checkState === CheckStates.Indeterminate)
      return disabled_tooltip_message;

    return no_permission_tooltip_message;
  }, [
    isSelected,
    checkState,
    disabled_tooltip_message,
    no_permission_tooltip_message,
    item.parent_id,
  ]);

  return (
    <NodeWrapper
      level={level}
      hasChildren={hasChildren}
      levelPadding={levelPadding}
    >
      {hasChildren && (
        <ToggleButton onClick={onToggle} collapsed={collapsed}>
          <KeyboardArrowDownOutlinedIcon />
        </ToggleButton>
      )}
      <Tooltip
        placement="right"
        disableHoverListener={!isDisabled}
        disableFocusListener={!isDisabled}
        title={getTooltipMessage}
      >
        <FormControlLabelStyled
          disabled={isDisabled}
          checked={isSelected}
          onChange={(e: SyntheticEvent<Element, Event>, checked: boolean) =>
            onChecked(checked, item)
          }
          control={
            <CheckboxStyled
              size="medium"
              indeterminate={checkState === CheckStates.Indeterminate}
              checkstate={checkState}
              itemselected={isDisabled && isSelected ? 1 : 0}
              indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
            />
          }
          label={
            <span style={{ userSelect: "none" }}>
              {isDisabled ? (
                item.name
              ) : (
                <Highlighted text={item.name} highlight={search} />
              )}
            </span>
          }
        />
      </Tooltip>
    </NodeWrapper>
  );
};
export default NodeWithCheckbox;
