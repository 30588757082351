import { MutableRefObject, useEffect, useRef } from "react";
import debounce from "utils/common/debounce";

const TOP_NAV_BAR_HEIGHT = 55;

const useFixedHeader = (prevWidth?: MutableRefObject<number>) => {
  const headerRef = useRef<HTMLTableSectionElement>(null);
  const tableWrapperRef = useRef<HTMLElement>(null);
  const lastChildRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    const wrapper = tableWrapperRef.current;
    const header = headerRef.current;
    const lastChild = lastChildRef.current;

    if (wrapper === null || header === null || lastChild === null) return;

    const placeholderDiv = document.createElement("div");
    header.parentNode?.insertBefore(placeholderDiv, header.nextSibling);
    const headerRow = header.querySelector<HTMLTableRowElement>("tr");
    const tbody = wrapper.querySelector<HTMLTableSectionElement>("tbody");
    const tableContainer = wrapper.querySelector<HTMLDivElement>(
      ".MuiTableContainer-root",
    );
    const table = wrapper.querySelector<HTMLTableElement>("table");

    if (
      headerRow === null ||
      tbody === null ||
      tableContainer === null ||
      table === null
    )
      return;

    const handleScroll = () => {
      const wrapperOffsetTop = wrapper.offsetTop;
      const wrapperOffsetHeight = wrapper.offsetHeight;
      const lastChildOffsetHeight = lastChild.offsetHeight;
      const lastChildOffsetTop = lastChild.offsetTop;
      const headerOffsetHeight = header.offsetHeight;

      const isScrolledOutOfView =
        wrapperOffsetTop + wrapperOffsetHeight < window.scrollY ||
        wrapperOffsetTop - headerOffsetHeight > window.scrollY;

      const extraOffset =
        lastChild.getBoundingClientRect().top -
        TOP_NAV_BAR_HEIGHT -
        headerOffsetHeight;

      const shouldFixHeader =
        wrapperOffsetTop < window.scrollY &&
        headerOffsetHeight !== lastChildOffsetTop;

      const resetStyling = () => {
        if (header.style.position === "fixed") {
          header.style.removeProperty("position");
          header.style.removeProperty("top");
          header.style.removeProperty("width");
          header.style.removeProperty("overflow");
          headerRow.style.removeProperty("display");
          headerRow.style.removeProperty("padding");
          tbody.style.removeProperty("display");
          placeholderDiv.style.removeProperty("height");
        }
      };

      if (isScrolledOutOfView) return resetStyling();

      const scrollLength = tableContainer.scrollLeft;
      if (shouldFixHeader) {
        if (header.style.top === "" && header.style.position !== "fixed") {
          header.style.top = `${
            extraOffset + TOP_NAV_BAR_HEIGHT + lastChildOffsetHeight / 2 < 0
              ? TOP_NAV_BAR_HEIGHT + extraOffset
              : TOP_NAV_BAR_HEIGHT
          }px`;
        }
        if (extraOffset <= 0) {
          header.style.top = `${TOP_NAV_BAR_HEIGHT + extraOffset}px`;
        }

        initializeColumnWidths();
        placeholderDiv.style.height = `${headerOffsetHeight}px`;
        if (header.style.position === "fixed") return;

        header.style.position = "fixed";
        header.style.width = `${wrapper.clientWidth}px`;
        header.style.overflow = "hidden";
        headerRow.style.display = "block";
        headerRow.style.padding = "0";
        tbody.style.display = "block";
      } else resetStyling();

      tableContainer.scrollLeft = scrollLength;
      header.scrollLeft = tableContainer.scrollLeft;
    };

    const initializeColumnWidths = () => {
      headerRow.style.width = `${headerRow.clientWidth}px`;
      tbody.style.width = `${tbody.clientWidth}px`;

      const totalWidth = table.clientWidth;

      const fixColWidths = (cols: HTMLTableCellElement[]) => {
        cols.forEach((col: HTMLTableCellElement, index: number) => {
          const width =
            Math.round((col.clientWidth / totalWidth) * 10000) / 100; // round to 2 decimal places
          cols[index].style.width = `${width}%`;
        });
        cols[cols.length - 1].style.width = "50000px";
        // for some reason, unless we set at least one of the columns to be a fixed width,
        // the entire row will be wrong size above a certain screenwidth
      };

      const headerCols = Array.from(
        wrapper.querySelectorAll<HTMLTableCellElement>("thead th"),
      );
      fixColWidths(headerCols);

      const bodyCols = Array.from(
        wrapper.querySelectorAll<HTMLTableCellElement>("tbody td"),
      );
      fixColWidths(bodyCols);
    };

    const handleResize = debounce(() => {
      if (
        wrapper.clientWidth > tbody.clientWidth ||
        wrapper.clientWidth > headerRow.clientWidth
      ) {
        tbody.style.width = `${wrapper.clientWidth}px`;
        headerRow.style.width = `${wrapper.clientWidth}px`;
      }
      header.style.width = `${wrapper.clientWidth}px`;

      if (prevWidth === undefined || wrapper.clientWidth === prevWidth.current)
        return;

      prevWidth.current = wrapper.clientWidth;
      initializeColumnWidths();
      handleScroll();
    }, 25);

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(wrapper);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleScroll);

    if (tableContainer !== null) {
      tableContainer.addEventListener("scroll", () => {
        header.scrollLeft = tableContainer.scrollLeft;
      });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
      placeholderDiv.remove();
      resizeObserver.disconnect();
    };
  }, [tableWrapperRef, headerRef, lastChildRef, prevWidth]);

  return { headerRef, tableWrapperRef, lastChildRef };
};

export default useFixedHeader;
