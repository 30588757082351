import { IDevice } from "interfaces/device.interface";
import { API_ACTIONS } from "store/actionTypes/apiActionTypes";

import * as types from "../../actionTypes/administration/deviceActionTypes";

export default function deviceReducer(
  state: { devices?: IDevice[] } = {},
  action: types.devicesActionTypes,
) {
  switch (action.type) {
    case types.FETCH_DEVICE_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        apitStatus: API_ACTIONS.reset,
      };

    case types.FETCH_DEVICE_BY_ID_SUCCESS:
      return {
        ...state,
        devices:
          state.devices && state.devices?.length
            ? state.devices.map((device: IDevice) =>
                device.id === action.payload.id
                  ? { ...action.payload }
                  : device,
              )
            : [action.payload],
        apitStatus: API_ACTIONS.reset,
      };
    case types.CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: [...(state.devices ?? []), action.payload],
        apitStatus: API_ACTIONS.created,
      };

    case types.RESET_DEVICE_STATE:
      return {
        devices: undefined,
        apitStatus: API_ACTIONS.reset,
      };
    case types.UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        apitStatus: API_ACTIONS.updated,
        devices: state.devices?.map((device: IDevice) =>
          device.id === action.payload.id ? { ...action.payload } : device,
        ),
      };
    case types.RESET_DEVICE_ACTIONS:
      return {
        ...state,
        apitStatus: API_ACTIONS.reset,
      };
    default:
      return state;
  }
}
