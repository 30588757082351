import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as LOCAL_STORAGE from "utils/localStorage";
import SplitPaneContext from "./SplitPanelContext";
import { SplitPanelContainer } from "./SplitPanelStyledComponents";

const SIDE_BAR_WIDTH: string = "side_bar_width";
const FALLBACK_MIN_WIDTH_PERCENTAGE: number = 0.1;

export const SplitPanel = ({
  children,
  flexDirection,
  bgColor,
}: {
  children: ReactNode;
  flexDirection: "column" | "row";
  bgColor?: string;
}) => {
  const memoizedSettings = useMemo(
    () => LOCAL_STORAGE.getAdminSettings() || {},
    [],
  );

  const prevUsedClientWidth = memoizedSettings[SIDE_BAR_WIDTH] ?? 256;
  const [clientWidth, setClientWidth] = useState<number | null>(
    prevUsedClientWidth,
  );
  const treeRef = useRef<HTMLDivElement | null>(null);

  const setTreeRef = useCallback((ref: HTMLDivElement | null) => {
    treeRef.current = ref;
  }, []);

  const xDividerPos = useRef<number | null>(null);

  const onMouseHoldDown = (e: any) => {
    xDividerPos.current = e.clientX || e?.touches[0]?.clientX;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      LOCAL_STORAGE.setAdminSettings({
        ...memoizedSettings,
        [SIDE_BAR_WIDTH]: clientWidth,
      });
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [clientWidth, memoizedSettings]);

  const onMouseHoldUp = () => {
    xDividerPos.current = null;
  };

  const checkNewWindowSize = useCallback(() => {
    if (clientWidth === null) return;
    if (treeRef?.current === null) return;
    const computedStyle = window.getComputedStyle(treeRef.current);
    const treeWidth =
      treeRef.current.clientWidth ??
      window.innerWidth * FALLBACK_MIN_WIDTH_PERCENTAGE;
    const treeWidthWithPadding =
      parseInt(computedStyle.paddingLeft) +
      treeWidth +
      parseInt(computedStyle.paddingRight);
    if (treeWidthWithPadding > clientWidth) {
      setClientWidth(treeWidthWithPadding);
    }
  }, [clientWidth]);

  const onMouseHoldMove = useCallback(
    (e: any) => {
      if (!xDividerPos.current) return;
      if (clientWidth) {
        const newClientWidth =
          clientWidth +
          (e.clientX ?? e?.touches[0]?.clientX) -
          (xDividerPos?.current || 0);
        if (treeRef?.current === null) return;
        const computedStyle = window.getComputedStyle(treeRef.current);
        const treeWidth =
          treeRef.current.clientWidth ??
          window.innerWidth * FALLBACK_MIN_WIDTH_PERCENTAGE;
        const treeWidthWithPadding =
          parseInt(computedStyle.paddingLeft) +
          treeWidth +
          parseInt(computedStyle.paddingRight);

        if (newClientWidth >= treeWidthWithPadding) {
          setClientWidth(newClientWidth);
        }
      }
      xDividerPos.current = e.clientX ?? e.touches[0].clientX;
    },
    [clientWidth],
  );

  useEffect(() => {
    document.addEventListener("mouseup", onMouseHoldUp, { capture: true });
    document.addEventListener("touchend", onMouseHoldUp, { capture: true });

    document.addEventListener("mousemove", onMouseHoldMove, { capture: true });
    document.addEventListener("touchmove", onMouseHoldMove, { capture: true });

    window.addEventListener("resize", checkNewWindowSize);

    if (treeRef.current === null) return;
    const resizeObserver = new ResizeObserver(checkNewWindowSize);
    resizeObserver.observe(treeRef.current);

    return () => {
      document.removeEventListener("mouseup", onMouseHoldUp, { capture: true });
      document.removeEventListener("mousemove", onMouseHoldMove, {
        capture: true,
      });

      document.removeEventListener("touchend", onMouseHoldUp, {
        capture: true,
      });
      document.removeEventListener("touchmove", onMouseHoldMove, {
        capture: true,
      });

      window.removeEventListener("resize", checkNewWindowSize);
    };
  }, [checkNewWindowSize, onMouseHoldMove]);

  return (
    <SplitPanelContainer
      style={{
        flexDirection,
        backgroundColor: bgColor,
        height: "100%",
      }}
    >
      <SplitPaneContext.Provider
        value={{
          clientWidth,
          setClientWidth,
          onMouseHoldDown,
          setTreeRef,
          treeRef,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </SplitPanelContainer>
  );
};
