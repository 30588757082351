import { ISettings } from "interfaces/device.interface";

import i18n from "../../i18n/config";

/**
 * This function will return the last time diffrence in hours and minutes
 * @param DateTime: time string
 * @return time diffrence as houres and minutes
 */
export const getTimeDifferenceAsHM = (endTime: string): string | null => {
  if (!endTime) {
    return null;
  }
  const timeA = new Date();
  const timeB = new Date(endTime);
  const diff = Math.abs(timeA.getTime() - timeB.getTime());

  const days = Math.floor(diff / (24 * 60 * 60 * 1000));
  const daysms = diff % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = diff % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  if (!days && !hours && !minutes) {
    return ` ${i18n.t("now")}`;
  }

  const daysText = !days
    ? ""
    : days > 1
    ? ` ${days} ${i18n.t("days").toLowerCase()}`
    : days === 1
    ? ` ${days} ${i18n.t("day").toLowerCase()}`
    : "";

  const hoursText = !hours
    ? ""
    : hours > 1
    ? ` ${hours} ${i18n.t("hours").toLowerCase()}`
    : hours === 1
    ? ` ${hours} ${i18n.t("hour").toLowerCase()}`
    : "";

  const minutesText = !minutes
    ? ""
    : minutes > 1
    ? ` ${minutes} ${i18n.t("minutes").toLowerCase()}`
    : minutes === 1
    ? ` ${minutes} ${i18n.t("minute").toLowerCase()}`
    : "";

  return daysText + hoursText + minutesText + ` ${i18n.t("ago")}`;
};

/**
 * This function will return the disable sleep tracking from device settings
 *
 * @param settings:ISettings zone device settings of type ISettings
 * @return disable sleep tracking of the device
 */
export const getDisableSleepTracking = (settings: ISettings): string => {
  if (
    !settings ||
    !settings?.user?.schedule ||
    !settings?.user?.schedule[0].enabled
  ) {
    return i18n.t("No");
  }
  return `${settings?.user?.schedule[0].timespan.start} - ${settings?.user?.schedule[0].timespan.end}`;
};
