import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { isAllowed } from "authorization/authorization.util";
import { ADMINSTRATION_DEVICES, PROD_API_BASE_URL } from "constants/constants";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { IAccessTokenPayload } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { jwtDecode } from "jwt-decode";
import { getAuthUser } from "store/actions/auth/authActions";
import { IRootState } from "store/reducers";
import { AuthState } from "store/reducers/auth/authReducerV1";
import { clearCookies, shouldRefreshAccessToken } from "utils/auth/auth.util";
import { gotTo } from "utils/history.util";
import * as LOCAL_STORAGE from "utils/localStorage";
import { removeLanguagePreference, setLanguage } from "utils/localStorage";
import UseAccessTokenRefresh from "./UseAccessTokenRefresh";

const UseAuthenticatedUser = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const location = useLocation();
  const [useTokenRefresh, setUseTokenRefresh] = useState<boolean>(false);
  const [intervalId] = useState<string | undefined>();
  const [authUser, setAuthUser] = useState<IUser | false>();
  const authState: AuthState = useSelector(
    (state: IRootState) => state.authReducerV1,
  );

  useEffect(() => {
    if (process.env.REACT_APP_PRODUCTION === "false") {
      const baseURL = LOCAL_STORAGE.getCustomAPIEndPoint() || PROD_API_BASE_URL;
      axios.defaults.baseURL = baseURL;
    } else {
      axios.defaults.baseURL = PROD_API_BASE_URL;
    }
    if (Cookies.get("access_token")) {
      dispatch(getAuthUser());
    } else {
      setAuthUser(false);
    }
  }, [dispatch]);

  const onFocus = useCallback(() => {
    const accessToken = Cookies.get("access_token");
    if (!accessToken) {
      clearCookies();
      setAuthUser(false);
    }
    if (document.visibilityState === "visible") {
      if (accessToken) {
        dispatch(getAuthUser());
      }
    }
  }, [dispatch]);
  // refresh on auth user on
  useEffect(() => {
    document.addEventListener("visibilitychange", onFocus);
    return () => {
      document.removeEventListener("visibilitychange", onFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    if (authState.user) {
      setAuthUser(authState.user);
      // set email
      Cookies.set("logged_user_email", authState.user.email);
      // set the language preference
      if (authState.user.preferences?.language) {
        setLanguage(authState.user.preferences?.language);
        //
        i18n.changeLanguage(authState.user.preferences?.language);
      }

      if (
        !isAllowed(authState.user, [
          ZoneUserRolesEnum.DATA_READER,
          ZoneUserRolesEnum.DATA_READER_LIMITED,
          ZoneUserRolesEnum.SERVICE_MONITOR,
        ])
      ) {
        if (location.pathname.includes("monitor/overview")) {
          gotTo(ADMINSTRATION_DEVICES);
        }
      }

      if (
        authState.user.roles &&
        shouldRefreshAccessToken(authState.user.roles)
      ) {
        setUseTokenRefresh(true);
      } else {
        setUseTokenRefresh(false);
      }
    } else if (Object.keys(authState).length !== 0) {
      setAuthUser(false);
      setUseTokenRefresh(false);
      clearCookies();

      // remove language preference
      removeLanguagePreference();
    }
  }, [authState.user, i18n, location.pathname, authState]);

  // Refresh access token periodically
  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    if (accessToken && useTokenRefresh) {
      const { iat, exp } = jwtDecode<IAccessTokenPayload>(accessToken);
      if (iat && exp) {
        const delaySeconds = (exp - iat) / 2;
        setInterval(UseAccessTokenRefresh, delaySeconds * 1000);
      }
    } else if (intervalId) {
      clearInterval(intervalId);
    }
  }, [useTokenRefresh, intervalId]);

  return { authUser };
};

export default UseAuthenticatedUser;
