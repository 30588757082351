import { Button, SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useContext } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  AuthorizationContext,
  DevicePermissionEnum,
  SubjectPermissionEnum,
  UserPermissionEnum,
} from "authorization/AuthorizationContext";
import SMBreadCrump from "components/BreadCrump/SMBreadCrump";
import { AppRouteProps } from "interfaces/router.interface";

const RouteContaner = styled("div")(() => {
  return {
    width: "100%",
  };
});

const TabWrapper = styled("div")(() => {
  const theme = useTheme();
  return {
    margin: "0 24px 24px 4px",
    height: 68,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "calc(100%-64px)",
    borderBottom: `1px solid ${theme.palette.neutral[5]}`,
    marginBottom: 16,
  };
});

const TabContentWrapper = styled("div")(() => {
  return {
    width: "100%",
  };
});

const Tabs = styled(Button)(
  ({ activetab = "false" }: { activetab: string }) => {
    const theme = useTheme();
    return {
      color:
        activetab === "true"
          ? theme.palette.main[70]
          : theme.palette.neutral[50],
      fontSize: 14,
      padding: "8px 12px",
      marginRight: 16,
      background: activetab === "true" ? theme.palette.white : "none",
      boxShadow:
        activetab === "true" ? "0px 0.5px 4px rgba(0, 0, 0, 0.25)" : "",
      borderRadius: activetab === "true" ? 4 : 0,
      height: 36,
      marginTop: "1px",
      "&:hover": {
        background: "#FFF",
      },
    };
  },
);

const SVGStyled = styled(SvgIcon)(
  ({ activetab = 0 }: { activetab: number }) => {
    const theme = useTheme();
    return {
      color: activetab ? theme.palette.main[70] : theme.palette.neutral[50],
    };
  },
);

function RouteTabsNew({
  routes,
  isAdmin = true,
  hideBreadcrumb = false,
  t,
}: {
  routes: AppRouteProps[];
  isAdmin?: boolean;
  hideBreadcrumb?: boolean;
  t?: any;
}) {
  const history = useHistory();
  const location = useLocation();
  const { permissions } = useContext(AuthorizationContext);

  const changeRoute = (path: string) => {
    history.push(path);
  };

  const getPath = (route: AppRouteProps) => {
    return `${route.path}`;
  };

  const hasPermission = (route: AppRouteProps) => {
    if (
      route.name === "employees" &&
      permissions.indexOf(UserPermissionEnum["user.list"]) === -1
    ) {
      return false;
    }
    if (
      route.name === "Subjects" &&
      permissions.indexOf(SubjectPermissionEnum["subject.list"]) === -1
    ) {
      return false;
    }
    if (
      route.name === "Devices" &&
      permissions.indexOf(DevicePermissionEnum["device.list"]) === -1
    ) {
      return false;
    }
    return true;
  };

  const isActive = (route: AppRouteProps) => {
    return location.pathname.includes(route.path);
  };

  return (
    <RouteContaner>
      <TabWrapper>
        {routes
          .filter((route: AppRouteProps) => !!route.primary)
          .filter(hasPermission)
          .map((route: AppRouteProps) => {
            return (
              <Tabs
                key={route.name}
                activetab={`${isActive(route)}`}
                onClick={() => changeRoute(route.path)}
                startIcon={
                  route.icon ? (
                    <SVGStyled activetab={isActive(route) ? 1 : 0}>
                      {" "}
                      <route.icon />
                    </SVGStyled>
                  ) : (
                    ""
                  )
                }
              >
                <span> {t(route.name)}</span>
              </Tabs>
            );
          })}
      </TabWrapper>
      {!hideBreadcrumb ? <SMBreadCrump /> : ""}
      <TabContentWrapper>
        <Switch>
          {routes.map((route: AppRouteProps) => (
            <Route
              exact={route.exact}
              key={route.name}
              path={getPath(route)}
              render={() => {
                const Component = route.component;
                return Component ? (
                  <Component />
                ) : (
                  <Redirect
                    to={
                      (!isAdmin
                        ? route.redirectPathIfNotAdmin
                        : route.redirectPath) || "/"
                    }
                  />
                );
              }}
            />
          ))}
        </Switch>
      </TabContentWrapper>
    </RouteContaner>
  );
}
export default RouteTabsNew;
