import BuildZonePath from "@modules/administration/zones/components/BuildZonePath";
import { findAncestorWithNightReportTimes } from "@modules/administration/zones/zoneSettings/NightReportSettings";
import { Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import SMNoDataBox from "components/SMDynamicTable/SMNoDataBox";
import UseDashboardContext from "hooks/UseDashboardContext";
import {
  DEFAULT_NIGHT_REPORT_END,
  DEFAULT_NIGHT_REPORT_START,
  NightReport,
  NightReportMetric,
} from "interfaces/nightReport.interface";
import { IZone } from "interfaces/zone.interface";
import moment from "moment-timezone";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchNightReport } from "store/actions/dashboard/nightReportActions";
import { IRootState } from "store/reducers";
import { SMTableWrapperBox, TableTitle } from "styled/CommonStyled";
import { timesEquals } from "utils/date.util";
import { getTimeAsleep } from "../NightReportColumn";
import NightReportTable from "./NightReportTable";

interface ZoneNightReportProps {
  zone: IZone;
  onNightReportRowClick: (row: NightReport) => void;
  subjectIDs: string[];
}
const ZoneNightReport = ({
  zone,
  onNightReportRowClick,
  subjectIDs,
}: ZoneNightReportProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zoneEntity, selectedDate } = UseDashboardContext();
  const [nightReports, setNightReports] = useState<NightReport[]>([]);
  const nightReportReducer = useSelector(
    (state: IRootState) => state.nightReportReducer || {},
  );

  const zoneReducer: IZone[] = useSelector(
    (state: IRootState) => state.zoneReducer.zones ?? [],
  );

  const nightReportTimes = useMemo(
    () =>
      zone?.settings?.night_report_times ??
      findAncestorWithNightReportTimes(zone, zoneReducer)?.settings
        ?.night_report_times ?? {
        start: DEFAULT_NIGHT_REPORT_START,
        end: DEFAULT_NIGHT_REPORT_END,
      },
    [zone, zoneReducer],
  );

  const [avgSleepScore, setAvgSleepScore] = useState<string>();
  const [avgTotalSleepTime, setAvgTotalSleepTime] = useState<string>();
  const palette = useTheme().palette;

  useEffect(() => {
    if (nightReports === undefined || nightReports.length === 0) return;

    const sleepScores: NightReportMetric[] = nightReports
      .map((report) => report.sleep_score)
      .filter((score) => score !== null);

    const totalSleepTimes: NightReportMetric[] = nightReports
      .map((report) => report.time_asleep)
      .filter((time) => time !== null);

    if (sleepScores.length === 0 || totalSleepTimes.length === 0) return;

    const avgSleepScore =
      sleepScores.reduce((sum, score) => sum + score.value, 0) /
      sleepScores.length;
    const avgTotalSleepTime =
      totalSleepTimes.reduce((sum, time) => sum + time.value, 0) /
      totalSleepTimes.length;
    setAvgSleepScore(avgSleepScore.toFixed(0));
    setAvgTotalSleepTime(getTimeAsleep(avgTotalSleepTime));
  }, [nightReports]);

  const prevWidth = useRef(0);

  useEffect(() => {
    if (subjectIDs.length === 0) return;

    const date = moment(selectedDate).format("YYYY-MM-DD");

    dispatch(fetchNightReport(subjectIDs, date));
  }, [dispatch, subjectIDs, selectedDate]);

  useEffect(() => {
    if (
      subjectIDs.length === 0 ||
      nightReportReducer?.nightReport === undefined
    )
      return;
    const date = moment(selectedDate).format("YYYY-MM-DD");
    setNightReports(
      nightReportReducer?.nightReport.filter(
        (item: NightReport) =>
          subjectIDs.includes(item.subject.id) &&
          item.report_date === date &&
          timesEquals(item.timeframe_start, nightReportTimes.start) &&
          timesEquals(item.timeframe_end, nightReportTimes.end),
      ),
    );
  }, [nightReportReducer, subjectIDs, selectedDate, nightReportTimes]);

  return nightReports && nightReports.length !== 0 ? (
    <BoxPadding pad="0px" className="print_table_wrapper">
      <SMTableWrapperBox key={zone.id}>
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <TableTitle
            bg="light"
            sx={{
              padding: "16.5px 20px",
              borderRadius: "8px 8px 0px 0px",
              display: "flex",
            }}
          >
            {zoneEntity && (
              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                <BuildZonePath path={zone.path} zoneEntity={zoneEntity} />
                <Tooltip title={t("night_report_times")} placement="top" arrow>
                  <span style={{ fontWeight: "lighter", userSelect: "none" }}>
                    {nightReportTimes.start} - {nightReportTimes.end}
                  </span>
                </Tooltip>
              </div>
            )}
          </TableTitle>
          <div style={{ flexGrow: 1 }} />
          {avgTotalSleepTime !== undefined && avgSleepScore !== undefined && (
            <div style={{ marginRight: "1rem", gap: "1rem", display: "flex" }}>
              <div>
                <span
                  style={{ color: palette.neutral[40], fontSize: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: `${t("average_time_asleep")}: ${
                      avgTotalSleepTime ?? "-"
                    }`,
                  }}
                />
              </div>
              <div>
                <span style={{ color: palette.neutral[40], fontSize: "14px" }}>
                  {t("average_sleep_score")}: {avgSleepScore}
                </span>
              </div>
            </div>
          )}
        </div>
        <div style={{ padding: 1 }}>
          <NightReportTable
            reports={nightReports}
            nightReportRowClick={onNightReportRowClick}
            prevWidth={prevWidth}
          />
        </div>
      </SMTableWrapperBox>
    </BoxPadding>
  ) : (
    <BoxPadding pad="0px">
      <SMNoDataBox
        message={t("no_subjects_with_device_in_this_zone")}
        title={
          zoneEntity ? (
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <BuildZonePath path={zone.path} zoneEntity={zoneEntity} />
              <Tooltip title={t("night_report_times")} placement="top" arrow>
                <span style={{ fontWeight: "lighter", userSelect: "none" }}>
                  {nightReportTimes.start} - {nightReportTimes.end}
                </span>
              </Tooltip>
            </div>
          ) : (
            zone.name
          )
        }
        bodyBg="white"
      />
    </BoxPadding>
  );
};

export default ZoneNightReport;
