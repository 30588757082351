import { RuleTypes } from "constants/rule.types.enum";
import { IRule, IRulePreset } from "interfaces/rules.interface";
import { rulesService } from "services/rules/rulesService";
import * as types from "store/actionTypes/rules/rulesActionTypes";
import { sortByRuleType } from "utils/rules.util";

export const resetRulesStateAction = (): types.resetRulesStateActionType => {
  return {
    type: types.RESET_RULES_STATE,
  };
};

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */
/**
 * Redux action to fetch all rules
 *
 * @return type - redux action type
 */
export const getAllRulesAction = (): types.fetchRulesActionType => {
  return {
    type: types.FETCH_RULES,
  };
};

/**
 * Redux success action
 *
 * @return type - redux action type
 *         payload - Ruless
 */
export const getAllRulesActionSuccess = (
  data: IRule[],
): types.fetchRulesSuccessActionType => {
  return {
    type: types.FETCH_RULES_SUCCESS,
    payload: data,
  };
};

export const getRulesByIDAction = (): types.fetchRulesByIDActionType => {
  return {
    type: types.FETCH_RULES_BY_ID,
  };
};

export const getRulesByIDActionSuccess = (
  data: IRule,
): types.fetchRulesByIDSuccessActionType => {
  return {
    type: types.FETCH_RULES_BY_ID_SUCCESS,
    payload: data,
  };
};

/**
 * Create  Rules action
 * @return type - redux action type |
 */
export const createRulesAction = (): types.createRulesActionType => {
  return {
    type: types.CREATE_RULES,
  };
};

/**
 * Create  Rules success action
 *
 * @return type - redux action type |
 *         payload - param
 */
export const createRulesSuccesAction = (
  data: IRule,
): types.createRulesSuccessActionType => {
  return {
    type: types.CREATE_RULES_SUCCESS,
    payload: data,
  };
};

/**
 * update Rules actionType
 * @return type - redux action type
 *
 */
export const updateRulesAction = (): types.updateRulesActionType => {
  return {
    type: types.UPDATE_RULES,
  };
};

/**
 * update Rules successAction
 * @return type - redux action type
 *         response - Created Rules
 */
export const updateRulesSuccessAction = (
  data: IRule,
): types.updateRulesSuccessActionType => {
  return {
    type: types.UPDATE_RULES_HANDLER_SUCCESS,
    payload: data,
  };
};

export const deleteRulesSuccessAction = (
  data: string,
): types.deleteRulesHandlerSuccessActionType => {
  return {
    type: types.DELETE_RULES_SUCCESS,
    payload: data,
  };
};

export const deleteRulesHandlerAction =
  (): types.deleteRulesHandlerActionType => {
    return {
      type: types.DELETE_RULES,
    };
  };

export const fetchRulePresetSuccesAction = (data: {
  type: string;
  data: IRulePreset[];
}): types.fetchRulesPresetSuccessActionType => {
  return {
    type: types.FETCH_RULES_PRESET_SUCCESS,
    payload: data,
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

export function resetRulesState() {
  return function (dispatch: (arg0: any) => void) {
    dispatch(resetRulesStateAction());
  };
}

/**
 *  Function to fetch all the Ruless
 *
 * @param none
 * @return all the Ruless in the system
 */
export function getAllRules(path: string) {
  return function (dispatch: (arg0: any) => void) {
    rulesService
      .fetchRules(path)
      .then((data: { object: "list"; data: IRule[] }) => {
        if (data?.data) {
          const filterdRules = data?.data.filter((item) => {
            return (
              item.type === RuleTypes.DeviceOffline ||
              item.type === RuleTypes.Movement ||
              item.type === RuleTypes.NoPresence ||
              item.type === RuleTypes.RRDeviation ||
              item.type === RuleTypes.SoundLevel ||
              item.type === RuleTypes.Awake
            );
          });
          // filtered rules
          dispatch(
            getAllRulesActionSuccess(filterdRules?.sort(sortByRuleType)),
          );
        }
      });
  };
}

export function createRulesHandler(
  handler: IRule | Partial<IRule>,
  callback?: () => void,
) {
  return function (dispatch: (arg0: any) => void) {
    rulesService
      .createRules(handler)
      .then((data: { object: "list"; data: IRule }) => {
        if (data?.data) {
          dispatch(createRulesSuccesAction(data.data));
          if (callback) {
            callback();
          }
        }
      });
  };
}

/**
 *  Function to delete notitifcation handler
 *
 * @param name
 * @return none
 */
export function deleteRule(id: string, callback: () => void) {
  return function (dispatch: (arg0: any) => void) {
    rulesService.deleteRules(id).then(() => {
      dispatch(deleteRulesSuccessAction(id));
      // callback to show the feedback
      if (callback) {
        callback();
      }
    });
  };
}

export function fetchRulesPresets(type: string) {
  return function (dispatch: (arg0: any) => void) {
    rulesService.fetchRulesPresets(type).then((data) => {
      if (data?.data) {
        dispatch(fetchRulePresetSuccesAction({ type, data: data.data }));
      }
    });
  };
}

export function createRule(
  params: IRule | Partial<IRule>,
  callback: () => void,
) {
  return function (dispatch: (arg0: any) => void) {
    rulesService.createRule(params).then((data) => {
      if (data?.data) {
        dispatch(createRulesSuccesAction(data?.data));
        if (callback) {
          callback();
        }
      }
    });
  };
}

export function enableDisableRule(
  id: string,
  data: { type: string; enabled: boolean },
  callback?: () => void,
) {
  return function (dispatch: (arg0: any) => void) {
    rulesService.enableDisableRule(id, data).then((res: any) => {
      if (res?.data) {
        dispatch(updateRulesSuccessAction(res?.data));
      }
      // callback to show the feedback
      if (callback) {
        callback();
      }
    });
  };
}
