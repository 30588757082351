import { ErrorOutline } from "@mui/icons-material";
import i18n from "i18n/config";
import { TFunctionResult } from "i18next";
import { getMinuteString, secondsToTime } from "utils/date.util";
import {
  DivWrapper,
  ErrorOutlineWrapperStyled,
  ErrorPlaceHolderStyled,
  ValueWrapperNoWidth,
} from "./components/NightReportStyledComponents";

export const getTimeAsleep = (seconds: number): string => {
  if (!seconds) return "";

  const { h, m } = secondsToTime(seconds);
  return `${
    h > 0 ? `${h}${i18n.t("hours_abbreviation_lc")}` : ""
  } ${getMinuteString(h, m)}`;
};

export const NightReportColumnNoData = ({ message }: { message: string }) => (
  <DivWrapper sx={{ justifyContent: "left" }}>
    <ErrorOutlineWrapperStyled>
      {" "}
      <ErrorOutline />{" "}
    </ErrorOutlineWrapperStyled>
    <div> {message}</div>
  </DivWrapper>
);

export const NightReportColumnNoAlert = ({
  value,
}: {
  value: string | number | TFunctionResult;
}) => (
  <DivWrapper>
    <ErrorPlaceHolderStyled />
    <ValueWrapperNoWidth
      dangerouslySetInnerHTML={{ __html: `${value}` || "-" }}
    />
  </DivWrapper>
);
