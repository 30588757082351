import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

import Blue from "theme/Colors/Blue";
import Green from "theme/Colors/Green";
import Neutral from "theme/Colors/Neutral";
import Orange from "theme/Colors/Orange";
import Primary from "theme/Colors/Primary";
import Purple from "theme/Colors/Purple";
import Red from "theme/Colors/Red";
import Teal from "theme/Colors/Teal";
import Yellow from "theme/Colors/Yellow";

const theme = {
  palette: {
    type: "light",
    white: "#FFFFFF",
    neutral: {
      ...Neutral,
    },
    main: {
      ...Primary,
    },
    blue: {
      ...Blue,
    },
    green: {
      ...Green,
    },
    orange: {
      ...Orange,
    },
    purple: {
      ...Purple,
    },
    red: {
      ...Red,
    },
    teal: {
      ...Teal,
    },
    yellow: {
      ...Yellow,
    },
    lightBlue: "#F6FAFB",
    chart: {
      legend: {
        selected: {
          light: grey["900"],
          dark: grey["100"],
        },
        unselected: {
          light: grey["400"],
          dark: grey["600"],
        },
        hover: {
          light: grey["400"],
          dark: grey["600"],
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1120,
      lg: 1300,
      xl: 1536,
    },
  },
};

export const appTheme = createTheme({
  ...theme,
  components: {
    MuiCssBaseline: {},
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.main[70],
          "&.Mui-checked": {
            color: `${theme.palette.main[70]}`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.main[70],
          padding: "0 15px",
          borderRadius: 0,
        },
        colorPrimary: {
          "&.Mui-checked": {
            color: theme.palette.main[70],
          },
        },
      },
    },
  },
});
