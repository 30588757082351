import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";

export const generateBirthYearOptions = (): number[] => {
  const NUM_YEARS = 120;
  const date = new Date();
  const firstYear = date.getFullYear() - NUM_YEARS;
  return Array.from(
    Array(NUM_YEARS),
    (_, x) => x + firstYear + 1,
  ).reverse() as number[];
};

export const makeZoneUserEntity = (users: IUser[]) => {
  const zoneUserEntity: { [key: string]: IUser[] } = {};
  users.forEach((user: IUser) => {
    if (user.zones?.data) {
      user.zones.data.forEach((zone: IZone) => {
        if (zoneUserEntity[zone.id]) {
          zoneUserEntity[zone.id].push(user);
        } else {
          zoneUserEntity[zone.id] = [user];
        }
      });
    }
  });

  return zoneUserEntity;
};
