import Awake2Icon from "assets/icons/Awake2.svg";
import NotPresentIcon from "assets/icons/Notpresent.svg";
import OutOfBedIcon from "assets/icons/OutOfBed.svg";
import Sleeping2Icon from "assets/icons/Sleeping2.svg";
import i18n from "i18n/config";

function Duration(ms: number) {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  return {
    days,
    hours,
    minutes,
    sec,
  };
}

export const getMonitorTileStatusText = (status: string) => {
  switch (status) {
    case "awake_in_bed":
      return i18n.t("Awake in bed");
    case "asleep":
      return i18n.t("asleep");
    case "not_in_bed":
      return i18n.t("Not in bed");
    case "device_inactive":
      return i18n.t("In Active");
    case "device_sleep_tracking_disabled":
      return i18n.t("device_sleep_tracking_disabled_status_text");
    case "device_offline":
      return i18n.t("device_offline");
    default:
      return i18n.t("No data");
  }
};

export const getMonitorTileStatusIcon = (status: string) => {
  switch (status) {
    case "awake_in_bed":
      return Awake2Icon;
    case "asleep":
      return Sleeping2Icon;
    case "not_in_bed":
      return OutOfBedIcon;
    default:
      return NotPresentIcon;
  }
};

// getDuration
export const getDuration = (state: string) => {
  if (!state) {
    //
    return "";
  }
  const currentDate: any = new Date();
  const stateChanged: any = new Date(state);
  const diff = currentDate.getTime() - stateChanged.getTime();

  const duration = Duration(diff);
  const getMinutes = (): string => {
    if (!duration.minutes) {
      return "";
    }
    if (duration.minutes === 1) {
      return `${duration.minutes} ${i18n.t("minute")}`;
    }
    return `${duration.minutes} ${i18n.t("minute")}`;
  };

  const getHours = (): string => {
    if (!duration.hours) {
      return "";
    }
    if (duration.hours === 1) {
      return `${duration.hours} ${i18n.t("hour_lc")}`;
    }
    return `${duration.hours} ${i18n.t("hours_lc")}`;
  };

  if (duration?.days > 0) {
    return `${duration.days} ${
      duration.days === 1 ? i18n.t("day_lc") : i18n.t("days_lc")
    } ${getHours()}`;
  }

  if (duration?.hours > 0) {
    return `${duration.hours} ${
      duration.hours === 1 ? i18n.t("hour_lc") : i18n.t("hours_lc")
    } ${getMinutes()}`;
  }
  if (duration?.minutes > 0) {
    return `${duration.minutes} ${
      duration.minutes === 1 ? i18n.t("minute") : i18n.t("minutes")
    }`;
  }
  return "";
};

export const getMonitorMovementText = (movement_mean: number) => {
  if (movement_mean > 30) {
    return i18n.t("High Movements");
  }
  if (movement_mean > 10) {
    return i18n.t("Moderate bevegelse");
  }
  if (movement_mean > 2) {
    return i18n.t("Light Movements");
  }
  return i18n.t("calm");
};
