import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

import { IDevice } from "interfaces/device.interface";
import { ChipStyled } from "styled/CommonStyled";
import { ApplyFloat } from "styled/StylesStyled";

export const GridStyled = styled(Grid)(() => {
  const theme = useTheme();
  return {
    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    width: "100%",
    padding: "8px 16px",
    margin: 0,
    marginBottom: 16,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.main[2],
      boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
    },
  };
});

const MainTitle = styled("div")(() => {
  const theme = useTheme();
  return {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.neutral[80],
    letterSpacing: "0.1px",
  };
});

const SubInfo = styled("div")(() => {
  const theme = useTheme();
  return {
    fontSize: 14,
    color: theme.palette.neutral[50],
    float: "left",
    letterSpacing: "0.4px",
  };
});

function DeviceRow({
  device,
  onClick,
}: {
  device: IDevice;
  onClick: (item: IDevice) => void;
}) {
  const { t } = useTranslation();
  const status: string = device.online ? "online" : "offline";
  return (
    <GridStyled container columnSpacing={0} onClick={() => onClick(device)}>
      <Grid item xs={6}>
        <MainTitle> {device.name} </MainTitle>
        <SubInfo>{device.serial_number}</SubInfo>
      </Grid>
      <Grid item xs={6}>
        <ApplyFloat float="right">
          {" "}
          <ChipStyled
            label={t(status)}
            bg={status === "online" ? "green" : "red"}
          />{" "}
        </ApplyFloat>
      </Grid>
    </GridStyled>
  );
}
export default DeviceRow;
