import { createContext, Dispatch, SetStateAction } from "react";

import { IZone } from "interfaces/zone.interface";

export type AdministrationContextType = {
  currentPath: { id: string; name: string } | undefined;
  setCurrentPath: Dispatch<
    SetStateAction<{ id: string; name: string } | undefined>
  >;
  zones: IZone[];
  selectedZone: string;
  setSelectedZone: Dispatch<SetStateAction<string>>;
  zoneEntity: { [key: string]: IZone };
};

export const AdministrationContext =
  createContext<AdministrationContextType | null>(
    {} as AdministrationContextType,
  );
