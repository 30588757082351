import { Alert, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { getUserPermission, isAllowed } from "authorization/authorization.util";
import {
  AuthorizationContext,
  AuthorizationPermissionsType,
} from "authorization/AuthorizationContext";
import SMAdminAppBar from "components/SMAdminAppBar/SMAdminAppBar";
import SleepHistoryFilterDropdown from "components/StyledMenu/SleepHistoryFilterDropdown";
import { ZoneUserRolesEnum } from "constants/userContstants";
import useNetworkStatus from "hooks/UseNetworkStatus";
import { AppMenuProps } from "interfaces/router.interface";
import ConfirmDialogProvider from "providers/ConfirmDialogProvider";
import DialogProvider from "providers/DialogProvider";
import { IRootState } from "store/reducers";

import { AuthState } from "store/reducers/auth/authReducerV1";
import routes from "../routes/admin.routes";

function AdminLayout() {
  const { t } = useTranslation();
  const isOnline = useNetworkStatus();
  const authState: AuthState = useSelector(
    (state: IRootState) => state.authReducerV1,
  );
  const [permissions, setPermissions] = useState<
    AuthorizationPermissionsType[]
  >([]);

  useEffect(() => {
    if (!authState.user) return;
    const userPermissions = getUserPermission(authState?.user);
    setPermissions(userPermissions);
  }, [authState.user]);

  const filteredRoutes = (rawRoutes: AppMenuProps[]) =>
    rawRoutes.filter(
      (route: AppMenuProps) =>
        authState.user && isAllowed(authState.user, route.allowedRoles),
    );

  // if the path is / so redirect to monitor or admin  based on the access
  const getAdminRedirectPath = () => {
    if (
      authState.user &&
      isAllowed(authState.user, [
        ZoneUserRolesEnum.DATA_READER,
        ZoneUserRolesEnum.DATA_READER_LIMITED,
        ZoneUserRolesEnum.SERVICE_MONITOR,
      ])
    ) {
      return "/monitor";
    }
    return "/adminstration";
  };
  return (
    <AuthorizationContext.Provider
      value={{
        permissions,
      }}
    >
      <DialogProvider>
        <ConfirmDialogProvider>
          <Container maxWidth={false} disableGutters>
            <SMAdminAppBar
              screens={filteredRoutes(routes) || []}
              loginedUser={authState.user}
            />
            <SleepHistoryFilterDropdown />
            {/* <Offline>
              <Alert variant="filled" severity="error" sx={{ borderRadius: 0 }}>
                {t("No internet connection")}
              </Alert>
            </Offline> */}

            {/* if no network */}
            {!isOnline && (
              <Alert variant="filled" severity="error" sx={{ borderRadius: 0 }}>
                {t("No internet connection")}
              </Alert>
            )}

            <Switch>
              {filteredRoutes(routes).map((route: AppMenuProps) => {
                return (
                  <Route
                    exact={false}
                    key={route.name}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
              <Redirect to={getAdminRedirectPath()} />
            </Switch>
          </Container>
        </ConfirmDialogProvider>
      </DialogProvider>
    </AuthorizationContext.Provider>
  );
}

export default AdminLayout;
