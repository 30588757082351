import _ from "lodash";

import { RuleTypes } from "constants/rule.types.enum";
import {
  ICronSchedule,
  INoPresenceCondition,
  IRule,
  IRuleAwakeCondition,
  IRuleDeviceOfflineConditions,
  IRuleMovementConditions,
  ISoundLevelCondition,
} from "interfaces/rules.interface";
import { IZone } from "interfaces/zone.interface";

import i18n from "../i18n/config";

export const ruleTypeBgMap: Record<RuleTypes, string> = {
  [RuleTypes.NoPresence]: "green-dark",
  [RuleTypes.Awake]: "teal-dark",
  [RuleTypes.SoundLevel]: "blue-dark",
  [RuleTypes.Movement]: "purple-dark",
  [RuleTypes.DeviceOffline]: "orange-dark",
  [RuleTypes.RRDeviation]: "red-dark",
};

export const rulePresetBgMap: { [key: string]: string } = {
  custom: "teal",
  default: "darkgrey",
};

export const parseTime = (time: string) => {
  if (!time) {
    return time;
  }
  if (time.length > 5) {
    return time.slice(0, time.lastIndexOf(":"));
  }
  return time;
};

const objTransform = (obj: any, convertTo: ConvertTo) => {
  for (const [key, value] of Object.entries(obj)) {
    if (
      typeof value !== "object" &&
      key.toString().includes("_time_epochs_gte") &&
      value &&
      !Number.isNaN(Number(value))
    ) {
      obj[key] =
        convertTo === "toMinutes" ? Number(value) / 2 : Number(value) * 2;
    }

    // for device offline time_since_last_seen_gte
    // here we need to convert seconds to minutes
    if (key.toString() === "time_since_last_seen_gte") {
      obj[key] =
        convertTo === "toMinutes" ? Number(value) / 60 : Number(value) * 60;
    }

    // transform movement_mean_gte to Number to keep same type as the default preset
    if (key.toString() === "movement_mean_gte") {
      obj[key] = Number(value);
    }

    if (typeof value === "object" && value !== null) {
      objTransform(value, convertTo);
    }
  }
  return obj;
};

type ConvertTo = "toMinutes" | "toEpoch" | "seconds";
type Condtions =
  | INoPresenceCondition
  | IRuleAwakeCondition
  | ISoundLevelCondition
  | IRuleMovementConditions
  | IRuleDeviceOfflineConditions;
export const transformConditions = (
  condtions: Condtions,
  convertTo: ConvertTo,
): Condtions => {
  if (condtions) {
    return objTransform(_.cloneDeep({ ...condtions }), convertTo);
  }
  return condtions;
};

export const checkIfUseHasAccess = (node: IZone, authorizedZones: string[]) => {
  if (!node) {
    return false;
  }
  const nodePaths = node.path.split(".");
  for (let i = 0; i < authorizedZones.length; i += 1) {
    if (nodePaths.includes(authorizedZones[i])) {
      return true;
    }
  }
  return false;
};

export const parseTimeToShecdule = (time?: string): ICronSchedule | "" => {
  if (!time) {
    return "";
  }
  const [hour, minutes] = time.split(":");
  return {
    hour: hour || "",
    minute: minutes || "",
    timezone: "Europe/Oslo",
  };
};

export const parseShecduleToTime = (schedule?: ICronSchedule): string => {
  if (!schedule) {
    return "";
  }
  return `${schedule.hour || ""}:${schedule.minute}`;
};

export const sortByRuleType = (a: IRule, b: IRule) => {
  const typeA = i18n.t(a.type).toUpperCase();
  const typeB = i18n.t(b.type).toUpperCase();
  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }
  return 0;
};
