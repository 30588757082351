import { NightReport } from "interfaces/nightReport.interface";

export const FETCH_NIGHT_REPORT = "FETCH_NIGHT_REPORT";
export const FETCH_NIGHT_REPORT_SUCCESS = "FETCH_NIGHT_REPORT_SUCCESS";
export const CLEAR_NIGHT_REPORT = "CLEAR_NIGHT_REPORT";

export interface ClearNightReportActionType {
  type: typeof CLEAR_NIGHT_REPORT;
}
export interface FetchNightReportActionType {
  type: typeof FETCH_NIGHT_REPORT;
}
export interface FetchNightReportSuccessActionType {
  type: typeof FETCH_NIGHT_REPORT_SUCCESS;
  payload: NightReport;
}

export type NightReportActionTypes =
  | FetchNightReportActionType
  | FetchNightReportSuccessActionType
  | ClearNightReportActionType;
