import Cookies from "js-cookie";
import axios from "axios";

import { getAuthBaseUrl } from "utils/auth/auth.util";

export function jwtInterceptor() {
  axios.interceptors.request.use((request) => {
    const token = Cookies.get("access_token");
    const userId = Cookies.get("userId");
    const destination = request.url;
    if (token && userId && !destination?.includes(getAuthBaseUrl())) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
  });
}
