import moment from "moment";

import {
  IHistoryStagePiePlotData,
  IHistoryStagesPlotData,
  IHistoryVitalsPlotData,
  ISession,
} from "interfaces/sleephistory.interface";

const hasTimeAsleep = (session: ISession) => {
  return session.time_asleep > 0;
};

// getSleepStagesPlotData
export const getSleepStagesPlotData = (
  sessions: ISession[],
): { series: IHistoryStagesPlotData; threshold: number } => {
  const plotData: IHistoryStagesPlotData = {
    timeInDeepSleep: [],
    timeInLightSleep: [],
    timeInREMSleep: [],
    timeWakeAfterSleepOnset: [],
    sleepOnset: [],
    timeAsleep: [],
    timeWakePrePostSleep: [],
    relativeSessionStart: [],
    wake_after_sleep: [],
  };

  const sortedSessions = sessions.sort(
    (a: ISession, b: ISession) =>
      Number(moment.utc(a.session_start)) - Number(moment.utc(b.session_start)),
  );
  sortedSessions.forEach((session: ISession, ix: number) => {
    // const timestamp = Number(
    //   moment(
    //     hasTimeAsleep(session)
    //       ? session.time_at_intended_sleep
    //       : session.session_start
    //   )
    // );
    const timestamp = Number(moment(session.session_start));
    plotData.timeInDeepSleep.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session) ? session.time_in_deep_sleep : 0,
      sessionId: session.id,
    });
    plotData.timeInLightSleep.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session) ? session.time_in_light_sleep : 0,
      sessionId: session.id,
    });
    plotData.timeInREMSleep.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session) ? session.time_in_rem_sleep : 0,
      sessionId: session.id,
    });
    plotData.timeWakeAfterSleepOnset.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session) ? session.time_wake_after_sleep_onset : 0,
      sessionId: session.id,
    });
    plotData.sleepOnset.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session)
        ? session.sleep_onset_latency
        : session.time_in_bed,
      sessionId: session.id,
    });
    plotData.timeAsleep.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session) ? session.time_asleep : 0,
      sessionId: session.id,
    });
    plotData.timeWakePrePostSleep.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session) ? session.time_wake_pre_post_sleep : 0,
      sessionId: session.id,
    });

    plotData.wake_after_sleep.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session)
        ? session.time_wake_pre_post_sleep - session.sleep_onset_latency
        : 0,
      sessionId: session.id,
    });
    plotData.relativeSessionStart.push({
      ix,
      x: timestamp,
      y: hasTimeAsleep(session) ? session.relative_session_start : 0,
      sessionId: session.id,
    });
  });

  const relativeSessionStart: number[] = sessions.map(
    (item: ISession) => item.relative_session_start,
  );
  return {
    series: plotData,
    threshold: Math.min(...relativeSessionStart),
  };
};

export const getSleepVitalsPlotData = (
  sessions: ISession[],
): IHistoryVitalsPlotData => {
  const plotData: IHistoryVitalsPlotData = {
    timeAsleep: [],
    sleepOnset: [],
    sleepEfficiency: [],
    sleepScore: [],
    numberOfTimesAwakeLong: [],
    numberOfTimesNoPresence: [],
    nonREMMeanRespirationRate: [],
    nonREMMeanHeartRate: [],
    nonREMMeanExternalHeartRate: [],
    externalSpO2Mean: [],
    epochsWithMovementPct: [],
    lightAmbientMean: [],
    soundAmplitudeMean: [],
  };
  sessions.sort((a, b) => {
    const dateA = a?.session_start;
    const dateB = b?.session_start;
    return Number(new Date(dateA)) - Number(new Date(dateB));
  });
  sessions
    .filter((session: ISession) => session.time_asleep > 0)
    .forEach((session: ISession, ix: number) => {
      // const timestamp = Number(
      //   moment(
      //     hasTimeAsleep(session)
      //       ? session.time_at_intended_sleep
      //       : session.session_start
      //   )
      // );

      const timestamp = Number(moment(session.session_start));
      plotData.timeAsleep.push({
        ix,
        x: timestamp,
        y: session.time_asleep,
        sessionId: session.id,
      });

      plotData.sleepOnset.push({
        ix,
        x: timestamp,
        y: hasTimeAsleep(session)
          ? session.sleep_onset_latency
          : session.time_in_bed,
        sessionId: session.id,
      });
      plotData.sleepEfficiency.push({
        ix,
        x: timestamp,
        y: session.sleep_efficiency,
        sessionId: session.id,
      });
      plotData.sleepScore.push({
        ix,
        x: timestamp,
        y: session.sleep_score_standard,
        sessionId: session.id,
      });
      plotData.numberOfTimesAwakeLong.push({
        ix,
        x: timestamp,
        y: session.number_of_times_awake_long,
        sessionId: session.id,
      });

      plotData.numberOfTimesNoPresence.push({
        ix,
        x: timestamp,
        y: session.number_of_times_no_presence,
        sessionId: session.id,
      });

      plotData.nonREMMeanRespirationRate.push({
        ix,
        x: timestamp,
        y: session.rpm_non_rem_filtered_mean,
        sessionId: session.id,
      });

      // only with value
      if (session.heart_rate_non_rem_mean > 0) {
        plotData.nonREMMeanHeartRate.push({
          ix,
          x: timestamp,
          y: session.heart_rate_non_rem_mean,
          sessionId: session.id,
        });
      }

      if (session.external_heart_rate_non_rem_filtered_mean > 0) {
        plotData.nonREMMeanExternalHeartRate.push({
          ix,
          x: timestamp,
          y: session.external_heart_rate_non_rem_filtered_mean,
          sessionId: session.id,
        });
      }

      if (session.external_spo2_mean > 0) {
        plotData.externalSpO2Mean.push({
          ix,
          x: timestamp,
          y: session.external_spo2_mean,
          sessionId: session.id,
        });
      }
      plotData.epochsWithMovementPct.push({
        ix,
        x: timestamp,
        y: session.epochs_with_movement_pct,
        sessionId: session.id,
      });
      plotData.lightAmbientMean.push({
        ix,
        x: timestamp,
        y: session.light_ambient_filtered_mean,
        sessionId: session.id,
      });
      plotData.soundAmplitudeMean.push({
        ix,
        x: timestamp,
        y: session.sound_amplitude_filtered_mean,
        sessionId: session.id,
      });
    });
  return plotData;
};

export const getSleepStagePiePlotData = (
  sleepReport: ISession,
): IHistoryStagePiePlotData | null => {
  if (!sleepReport) {
    return null;
  }
  return {
    timeAwakeAfterSleepOnset: sleepReport.time_wake_after_sleep_onset,
    timeInREMSleep: sleepReport.time_in_rem_sleep,
    timeInLightSleep: sleepReport.time_in_light_sleep,
    timeInDeepSleep: sleepReport.time_in_deep_sleep,
    timeInNoPresence: sleepReport.time_in_no_presence,
    timeAwake: sleepReport.time_asleep
      ? sleepReport.time_wake_pre_post_sleep +
        sleepReport.time_wake_after_sleep_onset
      : sleepReport.time_in_bed,
  };
};
const convertDurationtoSeconds = (duration: string) => {
  const [hours, minutes] = duration.split(":");
  return Number(hours) * 60 * 60 + Number(minutes) * 60;
};
export const getFilteredSessionByTme = ({
  sessions,
  from,
  to,
}: {
  sessions: ISession[];
  from: string;
  to: string;
}): ISession[] => {
  const rangeStart = convertDurationtoSeconds(from);
  const rangeEnd = convertDurationtoSeconds(to);
  const filtered = sessions.filter((session: ISession) => {
    const currentSessionTime = hasTimeAsleep(session)
      ? session.time_at_intended_sleep
      : session.session_start;

    const timeToCheck = convertDurationtoSeconds(
      `${new Date(currentSessionTime).getHours()}:${new Date(
        currentSessionTime,
      ).getMinutes()}`,
    );

    if (rangeStart < rangeEnd) {
      // Time range doesn't include shift at midnight
      if (rangeStart <= timeToCheck && timeToCheck <= rangeEnd) {
        // The time is within the range
        return true;
      }
      return false;
    }
    if (rangeEnd < timeToCheck && timeToCheck < rangeStart) {
      // The time is not within the range
      return false;
    }
    return true;
  });
  return filtered;
};
