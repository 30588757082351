/**
 * This function will return the avatar letter
 *
 * @param name:string user name
 * @return first letter or string -
 */
export const getAvatar = (name: string) => {
  if (!name) {
    return "-";
  }
  return name.charAt(0);
};
