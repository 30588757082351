import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import EnterFullscreenIcon from "@mui/icons-material/Fullscreen";
import ExitFullscreenIcon from "@mui/icons-material/FullscreenExit";
import PrintIcon from "@mui/icons-material/Print";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import AppVersion from "components/AppVersion/AppVersion";
import {
  BoxPadding,
  FlexOne,
} from "components/ResizablePanel/SplitPanelStyledComponents";
import SMAlertDialog from "components/SMDialogs/SMAlertDialog";
import SMPopoverDialog from "components/SMPopover/PopoverDialog";
import { basePath } from "constants/constants";
import { ZoneUserRolesEnum } from "constants/userContstants";
import useFullScreen from "hooks/UseFullScreenHooks";
import { SMMenuProps } from "interfaces/menu.interface";
import { AppRouteProps } from "interfaces/router.interface";
import { IZone } from "interfaces/zone.interface";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getAllZones } from "store/actions/administration/zoneActions";
import { getAuthUser } from "store/actions/auth/authActions";
import { clearLiveMonitoring } from "store/actions/dashboard/monitorActions";
import { IRootState } from "store/reducers";
import { ApplyFloat } from "styled/StylesStyled";
import { gotTo } from "utils/history.util";
import * as LOCAL_STORAGE from "utils/localStorage";
import { authorizedZones } from "utils/zone.util";
import { DashboardContext } from "./DashboardContext";
import MonitorScreenMenus from "./DashboardMenus";
import DashboardPopup from "./DashboardPopup";
import DateTimeText from "./NightReport/components/DateTimeText";
import NightReportLanding from "./NightReport/NightReportLanding";
import SubjectOverview from "./SubjectOverview/SubjectOverview";

const ButtonStyled = styled(Button)(() => {
  const theme = useTheme();
  return {
    marginRight: 4,
    color: theme.palette.main[70],
    fontWeight: 500,
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: 16,
    paddingRight: 12,
    paddingLeft: 12,
    marginBottom: 4,
    "&:hover": {
      background: "#FFF",
    },
    "> span": {
      marginLeft: 0,
    },
    "& svg": {
      width: 24,
      height: 24,
    },
  };
});

export interface ITileSettings {
  showIcon: boolean;
  showSubjectId: boolean;
  showDeviceName: boolean;
  showDuration: boolean;
  showVitals: boolean;
  tileScale: number;
  show_epoch_timestamp: boolean;
  show_tile_warning: boolean;
}

function MonitorScreen(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const componentRef = useRef<HTMLDivElement>(null);
  const fullScreenElemRef = useRef<HTMLDivElement>(null);
  const { isFullScreen, enterFullScreen, exitFullScreen } = useFullScreen();

  const settings: ITileSettings = LOCAL_STORAGE.getMonitorSettings() || {
    showIcon: true,
    showSubjectId: true,
    showDeviceName: true,
    showDuration: true,
    showVitals: true,
    show_epoch_timestamp: false,
    show_tile_warning: false,
    tileScale: 50,
  };

  const methods = useForm<ITileSettings>({
    defaultValues: {
      showIcon: settings.showIcon,
      showSubjectId: settings.showSubjectId,
      showDeviceName: settings.showDeviceName,
      showDuration: settings.showDuration,
      showVitals: settings.showVitals,
      tileScale: settings.tileScale,
      show_epoch_timestamp: settings.show_epoch_timestamp || false,
      show_tile_warning: settings.show_tile_warning || false,
    },
    reValidateMode: "onChange",
    mode: "onChange",
  });

  useEffect(() => {
    const subscription = methods.watch((value) => {
      LOCAL_STORAGE.setMonitorSettings(value);
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  const [popup, setPopup] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<"select_zones" | "settings" | "">(
    "",
  );
  const [selectedZones, setSelectedZones] = useState<IZone[] | undefined>(
    undefined,
  );

  const [zoneEntity, setZoneEntity] = useState<{ [key: string]: IZone }>({});
  const [currentPath, setCurrentPath] = useState<string>();
  const [openNoZoneAlert, setOpenNoZoneAlert] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const monitorRoutes: AppRouteProps[] = [
    {
      name: "home",
      path: `${basePath}/monitor`,
      exact: true,
      redirectPath: `${basePath}/monitor/overview`,
    },
    {
      name: "overview",
      path: `${basePath}/monitor/overview`,
      exact: true,
      component: SubjectOverview,
    },
    {
      name: "Night report",
      path: `${basePath}/monitor/night_report`,
      exact: true,
      component: NightReportLanding,
    },
  ];

  const zoneReducer = useSelector((state: IRootState) => {
    return state?.zoneReducer || {};
  });

  const authUser: any = useSelector((state: IRootState) => {
    return state.authReducerV1?.user || {};
  });

  const pageStyle = `
      @page {
        size:  auto;
        margin: 0mm;
      }
      @page:right{
        @bottom-right {
          content: counter(page);
        }
      }
      @media print {
          body{
            -webkit-print-color-adjust: exact;
          }
          table{
            width:90%;
            page-break-inside: avoid;
             min-width:600px !important;
            break-inside: avoid;
          }
          th {
            font-size: 8px !important;
            padding: 3px !important;
            max-width:50px;
          }
          td {
            font-size: 10px !important;
            padding: 5px !important;
            max-width:50px;
          }
          .sortIconParentDiv{
            display:none;
          }
          .printContainer{
            padding:5px;
          }
          .print_css_baseline_night{
            max-width:50px;
            overflow-wrap: break-word;
          }
          .print_table_wrapper{
            break-inside: avoid;
          }
    }
  `;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
  });

  useEffect(() => {
    const path = location.pathname.split("/").reverse();
    // for to fix issue: when another user changed the state from a different machine/browser
    if (path && path[0] && path[0] === "overview") {
      dispatch(getAuthUser());
    }
    if (path && path[0] && path[0] === "night_report") {
      dispatch(getAuthUser());
      dispatch(clearLiveMonitoring());
    }
    if (path?.length) {
      setCurrentPath(path[0]);
    }
  }, [location, dispatch]);

  const prevRoles = useRef<string>();
  const prevZones = useRef<string>();
  useEffect(() => {
    if (
      prevRoles.current === JSON.stringify(authUser?.roles) &&
      prevZones.current === JSON.stringify(zoneReducer?.zones)
    )
      return;
    if (prevRoles.current !== JSON.stringify(authUser?.roles)) {
      prevRoles.current = JSON.stringify(authUser?.roles);
    }
    if (prevZones.current !== JSON.stringify(zoneReducer?.zones)) {
      prevZones.current = JSON.stringify(zoneReducer?.zones);
    }
    const adminRoles = [
      ZoneUserRolesEnum.ADMIN,
      ZoneUserRolesEnum.MANAGER,
      ZoneUserRolesEnum.MANAGER_LIMITED,
      ZoneUserRolesEnum.OWNER,
    ];
    const intersection = authUser?.roles?.filter(
      (item: ZoneUserRolesEnum) => adminRoles.indexOf(item) !== -1,
    );

    if (!intersection.length || !zoneReducer?.zones?.length) {
      dispatch(getAllZones());
    }
  }, [authUser?.roles, dispatch, zoneReducer?.zones]);

  const getAuthorizedZones = useCallback(
    (zones: IZone[], authZones: IZone[]) => authorizedZones(zones, authZones),
    [],
  );

  const getSelectedZonesFromAuthZones = (
    authZones: IZone[],
    currentUserZones: string[],
  ) => {
    let updatedZones: string[] = [];
    const authZoneIds = authZones.map((item) => item.id);
    const difference = currentUserZones.filter(
      (x: string) => !authZoneIds.includes(x),
    );
    updatedZones = currentUserZones.filter(
      (x: string) => !difference.includes(x),
    );
    // set selected
    const selected = authZones.filter((item: IZone) =>
      updatedZones.includes(item.id),
    );
    return { selected, updatedZones };
  };

  useEffect(() => {
    if (zoneReducer?.zones?.length && authUser) {
      const entity: { [key: string]: IZone } = {};
      zoneReducer?.zones.forEach((zone: IZone) => {
        entity[zone.id] = zone;
      });

      const authZones = getAuthorizedZones(
        zoneReducer.zones,
        authUser?.zones?.data || [],
      );
      const activeZones = LOCAL_STORAGE.getDashboardZones() || {};

      const authZoneIds = authZones.map((item) => item.id);
      const selectedIds = selectedZones?.map((item) => item.id);
      let updated: string[] = [];

      if (activeZones[authUser.id]) {
        const currentUserZones = activeZones[authUser.id];
        const { selected, updatedZones } = getSelectedZonesFromAuthZones(
          authZones,
          currentUserZones,
        );
        updated = updatedZones;

        if (!_.isEqual(selected, selectedZones)) {
          setSelectedZones(selected);
        }
      } else if (!_.isEqual(authZoneIds, selectedIds)) {
        updated = authZones.map((x) => x.id);

        if (!_.isEqual(authZones, selectedZones)) {
          setSelectedZones(authZones);
        }
      }

      activeZones[authUser.id] = updated;
      setZonesInLocalStorage(activeZones);

      setZoneEntity(entity);
    }
  }, [zoneReducer, authUser, getAuthorizedZones, selectedZones]);

  const setZonesInLocalStorage = (activeZonesSettings: {
    [key: string]: string[];
  }) => {
    LOCAL_STORAGE.setDashboardZones(activeZonesSettings);
  };

  const onClick = (menu: SMMenuProps) => {
    if (!menu.popup && !menu.customAction) {
      history.push(`${basePath}/monitor/${menu.value}`);
    } else if (menu.customAction) {
      menu.customAction();
    } else {
      setPopupType(menu.value as "select_zones" | "settings" | "");
      setPopup(true);
    }
  };

  const handleFullScreenClick = () => {
    if (isFullScreen) {
      exitFullScreen();
    } else if (fullScreenElemRef.current) {
      enterFullScreen(fullScreenElemRef.current);
    }
  };

  const menus: SMMenuProps[] = [
    {
      label: t("select_zones"),
      value: "select_zones",
      popup: true,
      icon: <AccountTreeIcon />,
    },
    {
      label: t("print_report"),
      value: "print_report",
      customAction: () => {
        if (handlePrint) {
          handlePrint();
        }
      },
      icon: <PrintIcon />,
      hide: currentPath === "overview",
    },
    {
      label: t("enter_fullscreen"),
      value: "enter_fullscreen",
      icon: <EnterFullscreenIcon />,
      customAction: handleFullScreenClick,
      hide: isFullScreen || currentPath === "night_report",
    },
    {
      label: t("exit_fullscreen"),
      value: "exit_fullscreen",
      icon: <ExitFullscreenIcon />,
      customAction: handleFullScreenClick,
      hide: !isFullScreen || currentPath === "night_report",
    },
    {
      label: t("Settings"),
      value: "settings",
      icon: <SettingsIcon />,
      popup: true,
      hide: currentPath === "night_report",
    },
  ];

  const onSetSelectedZones = (selected: IZone[]) => {
    if (!selected?.length) {
      setOpenNoZoneAlert(true);
    } else {
      setPopup(false);

      const selectedIds = selected?.map((item) => item.id);

      // set to local storage
      const activeZones = LOCAL_STORAGE.getDashboardZones() || {};
      activeZones[authUser.id] = selectedIds;
      setZonesInLocalStorage(activeZones);

      const authZones = getAuthorizedZones(
        zoneReducer.zones ?? [],
        authUser?.zones?.data || [],
      );
      const { selected: slectd } = getSelectedZonesFromAuthZones(
        authZones,
        selectedIds,
      );
      setSelectedZones(slectd);
    }
  };

  // ! Temporarily unused until somnofy-data-science repo is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDateChange = (change: number) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + change);
    if (newDate > new Date()) return;
    setSelectedDate(newDate);
  };

  return (
    <DashboardContext.Provider
      value={{
        selectedZones,
        zoneEntity,
        setSelectedZones,
        handlePrint,
        componentRef,
        selectedDate,
      }}
    >
      <FlexOne
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: `calc(100vh - ${
            fullScreenElemRef.current?.getBoundingClientRect().top
          }px)`,
          backgroundColor: theme.palette.neutral[3],
        }}
        ref={fullScreenElemRef}
      >
        <FormProvider {...methods}>
          <BoxPadding pad="18px 24px" sx={{ position: "relative", flex: 1 }}>
            {/* Temporarily commented out until somnofy-data-science repo is ready */}
            {/* {currentPath === "overview" ? ( */}
            <DateTimeText />
            {/* ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  float: "left",
                  marginBottom: "12px",
                }}
              >
                <DateSelector
                  date={selectedDate}
                  setDate={setSelectedDate}
                  leftArrowClick={() => handleDateChange(-1)}
                  rightArrowClick={() => handleDateChange(1)}
                  disableRightArrow={
                    new Date().toDateString() === selectedDate.toDateString()
                  }
                  todayButton
                />
              </div>
            )} */}
            <ApplyFloat float="right">
              <MonitorScreenMenus menus={menus} onClick={onClick} />
            </ApplyFloat>

            {currentPath !== "overview" && (
              <ApplyFloat float="right">
                <ButtonStyled
                  startIcon={<DashboardIcon />}
                  onClick={() => gotTo(`monitor/overview`)}
                >
                  {t("overview")}
                </ButtonStyled>
              </ApplyFloat>
            )}

            {!isFullScreen && currentPath !== "night_report" && (
              <ApplyFloat float="right">
                <ButtonStyled
                  startIcon={<DescriptionRoundedIcon />}
                  onClick={() => gotTo(`monitor/night_report`)}
                >
                  {t("Night Report")}
                </ButtonStyled>
              </ApplyFloat>
            )}
            <Switch>
              {monitorRoutes.map((route: AppRouteProps) => (
                <Route
                  exact={route.exact}
                  key={route.name}
                  path={route.path}
                  render={() => {
                    const Component = route.component;
                    return Component ? (
                      <Component />
                    ) : (
                      <Redirect to={route.redirectPath || "/"} />
                    );
                  }}
                />
              ))}
            </Switch>
          </BoxPadding>
          {zoneReducer.zones && authUser && (
            <SMPopoverDialog
              open={popup}
              title={
                popupType === "select_zones" ? t("select_zones") : t("Settings")
              }
              maxWidth="sm"
              onClose={() => setPopup(false)}
            >
              <DashboardPopup
                type={popupType}
                authUser={authUser}
                zones={zoneReducer.zones}
                onSetSelectedZones={onSetSelectedZones}
                selectedZones={selectedZones}
              />
            </SMPopoverDialog>
          )}
        </FormProvider>
        <AppVersion />
      </FlexOne>
      <SMAlertDialog
        title={t("no_zone_selected_warning_title")}
        dialogDesc={t("no_zone_selected_warning_text")}
        buttonOk={t("ok")}
        open={openNoZoneAlert}
        setOpen={setOpenNoZoneAlert}
      />
    </DashboardContext.Provider>
  );
}

export default MonitorScreen;
