import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useTranslation } from "react-i18next";
import ResizeDetector from "react-resize-detector";

import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import {
  IPlotData,
  ISleepEpochPlotData,
} from "interfaces/sleephistory.interface";
import { createRef, memo } from "react";
import { getLangKey } from "utils/common/locale.util";
import { getLocale } from "utils/date.util";
import { isAndroidOrWindows, isiOS } from "utils/device.detect";
import { getLocalSettings, setLocalSettings } from "utils/localStorage";

const SleepEpochDataChart = ({
  plotData,
  enableHeartRateMean,
}: {
  plotData: ISleepEpochPlotData;
  enableHeartRateMean: boolean;
}) => {
  const chartRef = createRef<HighchartsReactRefObject>();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ResizeDetector
      handleWidth
      onResize={() =>
        chartRef.current ? chartRef.current.chart.reflow() : undefined
      }
    >
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="stockChart"
        options={getPlotOptions({ plotData, enableHeartRateMean, t, theme })}
      />
    </ResizeDetector>
  );
};
export default memo(SleepEpochDataChart);

const getPlotOptions = ({
  plotData,
  enableHeartRateMean,
  t,
  theme,
}: {
  plotData: ISleepEpochPlotData;
  enableHeartRateMean: boolean;
  t: any;
  theme: Theme;
}) => {
  const colorType = "light";
  const series: ISleepEpochPlotData = plotData;
  const showHeartRate = series.heartRateMean.length > 0 && enableHeartRateMean;
  const showExternalHeartRate = series.externalHeartRateMean.length > 0;
  const showExternalSpO2 = series.externalSpO2Mean.length > 0;
  let legendSelected: string[] =
    getLocalSettings(USER_SETTINGS_CONSTANTS.sleep_epoch_settings) || [];
  // let legendSelected = ["respiration_rate_mean"];

  let yAxisExternalSpO2Min = 100;
  let yAxisExternalSpO2Max = 0;
  for (let i = 0; i < series.externalSpO2Mean.length; i += 1) {
    const externalSpO2Mean: IPlotData = series.externalSpO2Mean[i];
    if (
      externalSpO2Mean.y &&
      externalSpO2Mean.y > 0 &&
      externalSpO2Mean.y < yAxisExternalSpO2Min
    ) {
      yAxisExternalSpO2Min = externalSpO2Mean.y;
    }
    if (externalSpO2Mean.y && externalSpO2Mean.y > yAxisExternalSpO2Max) {
      yAxisExternalSpO2Max = externalSpO2Mean.y;
    }
  }

  yAxisExternalSpO2Min -= (yAxisExternalSpO2Max - yAxisExternalSpO2Min) * 0.05;
  yAxisExternalSpO2Max += (yAxisExternalSpO2Max - yAxisExternalSpO2Min) * 0.05;

  const plotYAxisLowTop = "40%";
  const plotYAxisLowHeight = "60%";

  const getLocalizedString = (val: string) => {
    if (val && getLocale() === "nb") {
      return val.replace(".", ",");
    }
    return val;
  };

  return {
    chart: {
      type: "line",
      backgroundColor: "transparent",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    title: {
      text: null, // 'Somnofy Sleep History'
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%e. %b",
        year: "%b",
      },
      title: {
        text: t("date"),
      },
      ordinal: false,

      // offset: -50,
    },
    yAxis: [
      {
        // 0: SleepStages

        title: {
          text: null,
        },
        min: 0,
        max: 4,

        // alignTicks: false,
        // allowDecimals: false,
        // tickInterval: 1,

        gridLineWidth: 0,
        height: "30%",

        plotLines: [
          {
            value: 0,
            color: "lightgrey",
            width: 1,
            zIndex: 30, // TODO: Adjusted to 3 to get the labels in front of the colored zones. This adjustment correlates to the opacity adjustment of the zones.
            label: {
              text: "",
              align: "left",
              x: 2,
              y: 10,
            },
          },
          {
            value: 1,
            color: "rgba(0, 0, 139, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("Deep"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold",
              },
            },
          },
          {
            value: 2,
            color: "rgba(173, 216, 230, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("Light"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold",
              },
            },
          },
          {
            value: 3,
            color: "rgba(255, 140, 0, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("REM"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold",
              },
            },
          },
          {
            value: 4,
            color: "rgba(139, 0, 0, 0.2)",
            width: 1,
            zIndex: 30,
            label: {
              text: t("Awake"),
              align: "left",
              x: 2,
              y: 10,
              style: {
                color: "grey",
                fontWeight: "bold",
              },
            },
          },

          /*
                    {
                        value: 5,
                        color: 'lightgrey',
                        width: 1,
                        zIndex: 1,
                        label: {
                            text: 'No presence',
                            align: 'left',
                            x: 2
                        }
                    }
*/
        ],

        labels: {
          enabled: false,
          /*
                    enabled: true,
                    align: "left",
                    formatter: function() {
                        switch (this.value) {
                            case 1:
                                return "Deep"
                            case 2:
                                return "Light"
                            case 3:
                                return "REM"
                            case 4:
                                return "Awake"
                            case 5:
                                return "Not present"
                            default:
                                return ""
                        }
                    },
          */
        },
      },
      {
        // 1

        title: {
          text: null,
        },
        min: 0,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
      {
        // 2

        title: {
          text: null,
        },
        min: 0,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
      {
        // 3

        title: {
          text: null,
        },
        min: 0,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
      {
        // 4

        title: {
          text: null,
        },
        min: 0,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
      {
        // 5

        title: {
          text: null,
        },
        min: 0,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
      {
        // 6

        title: {
          text: null,
        },
        min: 0,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
      {
        // 7: Sound

        title: {
          text: null,
        },
        min: 20,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
      {
        // 8: SpO2

        title: {
          text: null,
        },
        min: yAxisExternalSpO2Min,
        max: yAxisExternalSpO2Max,
        labels: {
          enabled: false,
        },
        top: plotYAxisLowTop,
        height: plotYAxisLowHeight,
        gridLineWidth: 0,
      },
    ],
    navigator: {
      enabled: true,
      series: {
        data: series.hypnogram,
        type: "line",
      },
      yAxis: {
        min: 0,
      },
      height: 30,
      // top: '50%',
    },
    rangeSelector: {
      enabled: false,
    },
    legend: {
      enabled: true,
      itemHiddenStyle: {
        color: theme.palette.chart.legend.unselected[colorType],
        textDecoration: "none",
      },
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
          inactive: {
            opacity: isAndroidOrWindows() || isiOS() ? 1 : 0.2,
          },
        },
        threshold: null,
        navigatorOptions: {
          type: "line",
          dataGrouping: {
            smoothed: true,
          },
          showInLegend: false,
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          step: true,
          zones: [
            {
              value: 1.01,
              color: "rgba(0, 0, 139, 1)",
            },
            {
              value: 2.01,
              color: "rgba(173, 216, 230, 1)",
            },
            {
              value: 3.01,
              color: "rgba(255, 140, 0, 1)",
            },
            {
              value: 4.01,
              color: "rgba(139, 0, 0, 1)",
            },
            {
              color: "rgba(0,0,0,0)",
            },
          ],
        },
        events: {
          legendItemClick() {
            // @ts-ignore
            const { visible, userOptions } = this;

            if (!visible && legendSelected.indexOf(userOptions.slug) === -1) {
              legendSelected.push(userOptions.slug);
              // callback(selected);
            }
            if (visible) {
              legendSelected = legendSelected.filter(
                (item) => item !== userOptions.slug,
              );
              // callback(arr);
            }
            setLocalSettings({
              [USER_SETTINGS_CONSTANTS.sleep_epoch_settings]: legendSelected,
            });
          },
        },
      },
    },
    tooltip: {
      formatter(): string {
        // @ts-ignore
        const { points } = this;
        const pointsLength = points.length;
        const timestamp = new Date(points[0].key);

        const options: Intl.DateTimeFormatOptions = {
          hour: "numeric",
          minute: "numeric",
          timeZone: "Europe/Oslo",
          hourCycle: "h23",
        };

        const currentDate = new Date(timestamp).toLocaleString(
          getLangKey(),
          options,
        );
        // old
        // let tooltipMarkup = pointsLength
        //   ? `<span style="font-size: 10px">${t("Time")}: ${moment(timestamp)
        //       .utc()
        //       .format("HH:mm")}</span><br/>`
        //   : "";

        // new
        let tooltipMarkup = pointsLength
          ? `<span style="font-size: 10px">${t(
              "Time",
            )}: ${currentDate}</span><br/>`
          : "";
        let index;

        for (index = 0; index < pointsLength; index += 1) {
          if (points[index].series.userOptions.slug === "sleep_stage") {
            tooltipMarkup += `<span style="color:${points[index].series.color}">\u25CF</span> ${points[index].series.name}: <b>`;
            switch (points[index].y) {
              case 0:
                tooltipMarkup += t("unknown");
                break;
              case 1:
                tooltipMarkup += t("Deep");
                break;
              case 2:
                tooltipMarkup += t("Light");
                break;
              case 3:
                tooltipMarkup += t("REM");
                break;
              case 4:
                tooltipMarkup += t("Wake");
                break;
              case 5:
                tooltipMarkup += t("No Presence");
                break;
              default:
                tooltipMarkup += t("unknown");
                break;
            }
            tooltipMarkup += "</b><br/>";
          } else if (
            points[index].series.userOptions.slug === "respiration_rate_mean"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} RPM</b><br/>`;
          } else if (
            points[index].series.userOptions.slug ===
            "respiration_rate_variance"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${
              points[index].series.name
            }: <b>${getLocalizedString(points[index].y.toFixed(1))}</b><br/>`;
          } else if (
            points[index].series.userOptions.slug === "heart_rate_mean" ||
            points[index].series.userOptions.slug === "heart_rate_mean_external"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("bpm")}</b><br/>`;
          } else if (
            points[index].series.userOptions.slug === "heart_rate_variance" ||
            points[index].series.userOptions.slug ===
              "heart_rate_variance_external"
          ) {
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${
              points[index].series.name
            }: <b>${getLocalizedString(points[index].y.toFixed(1))} </b><br/>`;
          } else if (
            points[index].series.userOptions.slug === "spo2_mean_external"
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} %</b><br/>`;
          else if (points[index].series.userOptions.slug === "movement")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)}</b><br/>`;
          else if (points[index].series.userOptions.slug === "distance")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${
              points[index].series.name
            }: <b>${getLocalizedString(points[index].y.toFixed(1))} ${t(
              "meter_abbreviation_lc",
            )}</b><br/>`;
          else if (points[index].series.userOptions.slug === "illuminance")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("lux")}</b><br/>`;
          else if (points[index].series.userOptions.slug === "sound")
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${points[index].series.name}: <b>${points[
              index
            ].y.toFixed(0)} ${t("dba")}</b><br/>`;
          else if (
            points[index].series.userOptions.slug === "bio_signal_quality"
          )
            tooltipMarkup += `<span style="color:${
              points[index].series.color
            }">\u25CF</span> ${
              points[index].series.name
            }: <b>${getLocalizedString(points[index].y.toFixed(1))} </b><br/>`;
        }

        return tooltipMarkup;
      },
      shared: true,
    },
    series: [
      {
        name: t("sleep_stage"),
        slug: "sleep_stage",
        cursor: "pointer",
        yAxis: 0,
        turboThreshold: 3000,
        data: series.hypnogram,
        visible: true,
        showInLegend: false,
        showInNavigator: true,
        type: "column",
        borderWidth: 0,
        pointPadding: 0,
        groupPadding: 0,
        zones: [
          {
            // TODO: Adjusted opacity to get rid of "lines" caused by points packing up on each other

            value: 1.01,
            color: "rgba(0, 0, 139, 1)",
            className: "zone-deep",
          },
          {
            value: 2.01,
            color: "rgba(173, 216, 230, 1)",
            className: "zone-light",
          },
          {
            value: 3.01,
            color: "rgba(255, 140, 0, 1)",
            className: "zone-rem",
          },
          {
            value: 4.01,
            color: "rgba(139, 0, 0, 1)",
            className: "zone-awake",
          },
          {
            color: "rgba(0,0,0,0)",
          },
        ],
      },
      {
        name: t("Resting respiration rate (rpm)"),
        slug: "respiration_rate_mean",
        cursor: "pointer",
        yAxis: 1,
        color: "blue",
        turboThreshold: 3000,
        data: series.respirationRateMean,
        visible: legendSelected.indexOf("respiration_rate_mean") !== -1,
        showInLegend: true,
      },
      {
        name: t("respiration_rate_variance"),
        slug: "respiration_rate_variance",
        cursor: "pointer",
        yAxis: 1,
        color: "lightblue",
        turboThreshold: 3000,
        data: series.respirationRateVarMean,
        visible: legendSelected.indexOf("respiration_rate_variance") !== -1,
        showInLegend: true,
      },
      {
        name: t("Mean heart rate"),
        slug: "heart_rate_mean",
        cursor: "pointer",
        yAxis: 2,
        color: "red",
        turboThreshold: 3000,
        data: series.heartRateMean,
        visible: legendSelected.indexOf("heart_rate_mean") !== -1,
        showInLegend: showHeartRate,
      },
      {
        name: t("heart_rate_variance"),
        slug: "heart_rate_variance",
        cursor: "pointer",
        yAxis: 2,
        color: "rgba(180, 0, 0, 1)",
        turboThreshold: 3000,
        data: series.heartRateVar,
        visible: legendSelected.indexOf("heart_rate_variance") !== -1,
        showInLegend: false, // Not validated yet. showHeartRate
      },
      {
        name: t("Mean heart rate external"),
        cursor: "pointer",
        slug: "heart_rate_mean_external",
        yAxis: 2,
        color: "purple",
        turboThreshold: 3000,
        data: series.externalHeartRateMean,
        visible: legendSelected.indexOf("heart_rate_mean_external") !== -1,
        showInLegend: showExternalHeartRate,
      },
      {
        name: t("heart_rate_variance_external"),
        slug: "heart_rate_variance_external",
        cursor: "pointer",
        yAxis: 2,
        color: "rgba(180, 0, 0, 1)",
        turboThreshold: 3000,
        data: series.externalHeartRateVar,
        visible: legendSelected.indexOf("heart_rate_variance_external") !== -1,
        showInLegend: false, // Not validated yet. showHeartRate
      },
      {
        name: t("Mean SpO2 external"),
        slug: "spo2_mean_external",
        cursor: "pointer",
        yAxis: 8,
        color: "rgba(100, 100, 255, 1)",
        turboThreshold: 3000,
        data: series.externalSpO2Mean,
        visible: legendSelected.indexOf("mospo2_mean_externalvement") !== -1,
        showInLegend: showExternalSpO2,
      },
      {
        name: t("movement"),
        cursor: "pointer",
        slug: "movement",
        yAxis: 3,
        color: "black",
        turboThreshold: 3000,
        data: series.movementMean,
        visible: legendSelected.indexOf("movement") !== -1,
        showInLegend: true,
      },
      {
        name: t("distance"),
        slug: "distance",
        cursor: "pointer",
        yAxis: 4,
        color: "orange",
        turboThreshold: 3000,
        data: series.distanceMean,
        visible: legendSelected.indexOf("distance") !== -1,
        showInLegend: true,
      },
      {
        name: t("illuminance"),
        cursor: "pointer",
        slug: "illuminance",
        yAxis: 6,
        color: "red",
        turboThreshold: 3000,
        data: series.lightAmbient,
        visible: legendSelected.indexOf("illuminance") !== -1,
        showInLegend: true,
      },
      {
        name: t("Sound amplitude"),
        slug: "sound",
        cursor: "pointer",
        yAxis: 7,
        color: "green",
        turboThreshold: 3000,
        data: series.soundAmplitude,
        visible: legendSelected.indexOf("sound") !== -1,
        showInLegend: true,
      },
      {
        name: t("bio_signal_quality"),
        slug: "bio_signal_quality",
        cursor: "pointer",
        yAxis: 5,
        color: "brown",
        turboThreshold: 3000,
        data: series.signalQualityMean,
        visible: legendSelected.indexOf("bio_signal_quality") !== -1,
        showInLegend: true,
      },
    ],
  };
};
