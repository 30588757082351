import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { MouseEvent, useState } from "react";

import { IZone } from "interfaces/zone.interface";
import { numberOfOpenNodes } from "utils/zone.util";

import Node from "./Node";

const NodeParentWrapper = styled("div")(
  ({
    level,
    levelPadding,
    lastitemheight,
  }: {
    level: number;
    levelPadding: number;
    lastitemheight: number;
  }) => {
    const theme = useTheme();
    return {
      position: "relative",
      width: "fit-content",
      minHeight: "fit-content",
      "&:before": {
        display: "block",
        borderLeft: `.5px dotted ${theme.palette.neutral[80]}`,
        height: `calc(100% - ${lastitemheight * 44 + 62}px)`,
        content: '""',
        position: "absolute",
        top: "36.5px",
        left: `${Number(level * levelPadding) + 18}px`,
      },
    };
  },
);

function TreeBranch({
  item,
  level,
  handleChange,
  levelPadding,
  activeZone,
  onCollapse,
  isDisableNode,
  disabled_tooltip,
}: {
  item: IZone;
  level: number;
  handleChange: (e: MouseEvent<HTMLElement>, clicked: IZone) => void;
  levelPadding: number;
  activeZone: string;
  onCollapse: (item: IZone, collapse: boolean) => void;
  isDisableNode: (item: IZone) => boolean;
  disabled_tooltip: string;
}) {
  const [collapsed, setCollapsed] = useState<any>(item.collapsed ?? false);
  const hasChildren = (item.children && item.children.length !== 0) || false;
  const renderBranches = () => {
    if (hasChildren) {
      const newLevel = level + 1;
      return item?.children?.map((child: any) => {
        return (
          <TreeBranch
            key={child.id}
            item={child}
            level={newLevel}
            handleChange={handleChange}
            levelPadding={levelPadding}
            activeZone={activeZone}
            onCollapse={onCollapse}
            isDisableNode={isDisableNode}
            disabled_tooltip={disabled_tooltip}
          />
        );
      });
    }
    return null;
  };

  const toggleSelected = () => {
    onCollapse(item, !collapsed);
    setCollapsed((prev: any) => !prev);
  };

  const countChildren = (obj: IZone) => {
    if (obj.collapsed) {
      return 0;
    }
    if (
      obj.children &&
      obj.children.length &&
      !obj.children[obj.children.length - 1]?.collapsed
    ) {
      const numnodes = numberOfOpenNodes(obj.children[obj.children.length - 1]);
      return numnodes - 1;
    }
    return 0;
  };

  return (
    <NodeParentWrapper
      level={level}
      levelPadding={levelPadding}
      lastitemheight={countChildren(item) || 0}
    >
      <Node
        item={item}
        collapsed={collapsed}
        level={level}
        onToggle={toggleSelected}
        levelPadding={levelPadding}
        handleChange={handleChange}
        activeZone={activeZone}
        isDisableNode={isDisableNode}
        disabled_tooltip={disabled_tooltip}
      />
      {!collapsed && renderBranches()}
    </NodeParentWrapper>
  );
}
export default TreeBranch;
