import { IColor } from "interfaces/color.interface";

const Neutral: IColor = {
  0: "#FFFFFF",
  1: "#FDFDFD",
  2: "#FBFBFB",
  3: "#F8F8F8",
  4: "#F2F2F2",
  5: "#EDEDED",
  10: "#DBDBDB",
  20: "#B5B5B5",
  30: "#8F8F8F",
  40: "#6C6C6C",
  50: "#4E4E4E",
  60: "#363636",
  70: "#242424",
  80: "#161616",
  90: "#0C0C0C",
  100: "#050505",
  110: "#000000",
};

export default Neutral;
