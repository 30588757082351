import { Box, Button, ButtonProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

const ButtonStyled = styled(Button)(({ border }: { border: number }) => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    textTransform: "none",
    border: border ? `1px solid ${theme.palette.main[70]}` : "none",
    "&:hover": {
      background: theme.palette.main[2],
    },
  };
});

type IProps = ButtonProps & {
  text: string;
  onClick: (event?: any) => void;
  submit?: boolean;
  background?: "green" | "red" | "none" | "yellow" | any;
  opacity?: number;
  cursor?: "pointer" | "not-allowed";
  outlineBtn?: boolean;
  border?: boolean;
  width?: string | number;
};

function SMButtonWithIcon({
  text,
  onClick,
  disabled = false,
  border = false,
  width,
  ...buttonProps
}: IProps) {
  const { startIcon } = buttonProps;
  return (
    <ButtonStyled
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled}
      type="button"
      border={border ? 1 : 0}
    >
      <Box
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          width,
        }}
      >
        {" "}
        {text}{" "}
      </Box>
    </ButtonStyled>
  );
}

export default SMButtonWithIcon;
