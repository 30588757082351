import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import RouteTabsNew from "components/RouteTabs/RouteTabsNew";
import { basePath } from "constants/constants";
import UseAdminContext from "hooks/UseAdminContext";
import { IZone } from "interfaces/zone.interface";
import { getAuthUser } from "store/actions/auth/authActions";
import {
  IconButtonStyled,
  PositionAbsolute,
  PositionRelative,
  SettingsIconStyled,
} from "styled/CommonStyled";

import { IRootState } from "store/reducers";
import { AuthState } from "store/reducers/auth/authReducerV1";
import { zonesChildRoutes as routes } from "./zone.routes";

export const ZoneLandingPage = () => {
  const { t } = useTranslation();
  const { selectedZone, setSelectedZone } = UseAdminContext();

  const dispatch = useDispatch();
  const history = useHistory();

  const authState: AuthState = useSelector(
    (state: IRootState) => state.authReducerV1,
  );

  const handleClickOpen = () => {
    history.push(`${basePath}/adminstration/settings`);
  };

  useEffect(() => {
    dispatch(getAuthUser());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedZone && setSelectedZone) {
      const authZones =
        authState.user?.zones?.data?.map((item: IZone) => item.id) || [];
      setSelectedZone(authZones[0]);
    }
  }, [authState, selectedZone, setSelectedZone]);

  return (
    <PositionRelative>
      <RouteTabsNew routes={routes} t={t} hideBreadcrumb />
      <PositionAbsolute right={24} top={19}>
        <IconButtonStyled onClick={handleClickOpen}>
          <SettingsIconStyled style={{ cursor: "pointer" }} />
        </IconButtonStyled>
      </PositionAbsolute>
    </PositionRelative>
  );
};

export default ZoneLandingPage;
