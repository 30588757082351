import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { ChangeEvent } from "react";

export const TextFieldStyled = styled(TextField)(
  ({ width }: { width: string | number }) => {
    const theme = useTheme();
    return {
      border: "none",
      marginLeft: 0,
      maxWidth: width || 160,
      width,
      "& .Mui-disabled": {
        background: theme.palette.neutral[5],
        color: theme.palette.neutral[80],
        cursor: "not-allowed",
        WebkitTextFillColor: theme.palette.neutral[80],
      },
      '& input[type="time"]::-webkit-calendar-picker-indicator': {
        fontSize: 30,
        color: theme.palette.main[70],
      },
      "& input": {
        padding: "10px 8px",
        border: `1px solid ${theme.palette.neutral[5]}`,
        minWidth: 32,
        color: theme.palette.neutral[70],
        borderRadius: 2,
      },
      "& button": {
        padding: 0,
        "& svg": {
          color: theme.palette.main[70],
        },
      },
      "& fieldset": {
        border: "none",
      },
    };
  },
);

interface IProps {
  disabled?: boolean;
  placeholder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: string | number;
  allowKeyDown?: boolean;
}

function SMTimePicker({
  disabled,
  value,
  placeholder,
  onChange,
  width,
  allowKeyDown,
}: IProps) {
  return (
    <TextFieldStyled
      disabled={!!disabled}
      inputProps={{ type: "time", disableUnderline: true }}
      onChange={onChange}
      onKeyDown={!allowKeyDown ? (e: any) => e.preventDefault() : () => {}}
      placeholder={placeholder || ""}
      value={value}
      width={width || "auto"}
    />
  );
}

export default SMTimePicker;
