import Cookies from "js-cookie";

import { USER_SETTINGS_CONSTANTS } from "../constants/localSettingsConstants.ts";

const TOKEN_KEY = "SOMNOFY_AUTH_TOKEN";
const STYLE_TYPE_KEY = "SOMNOFY_STYLE_TYPE";
const TIME_FILTER = "TIME_FILTER";
const LOCAL_SETTINGS = "LOCAL_SETTINGS";
const SELECTED_ZONE = "SELECTED_ZONE";
// const CURRENT_USER_SELECTED_ZONE = "CURRENT_USER_SELECTED_ZONE";
const CLIENT_ID = "CLIENT_ID";
const MONITOR_SETTIGNS = "MONITOR_SETTIGNS";
const DASHBOARD_ACTIVE_ZONES = "DASHBOARD_ACTIVE_ZONES";
const ADMIN_SETTINGS = "ADMIN_SETTINGS";
const AUTH_KEY = "AUTH_KEY";
const API_END_POINT = "API_END_POINT";
const AUTH_BACKEND = "AUTH_BACKEND";
const SLEEP_HISTORY_SETTINGS = "SLEEP_HISTORY_SETTINGS";
const SLEEP_HISTORY_CHAT_TYPE = "SLEEP_HISTORY_CHAT_TYPE";
const SLEEP_HISTORY_DATE_FILTER_TYPE = "SLEEP_HISTORY_DATE_FILTER_TYPE";

export const LANG_KEY = "LANG_KEY";
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const getStyleType = () => {
  return localStorage.getItem(STYLE_TYPE_KEY);
};

export const toggleStyleType = () => {
  const nextStyleType = getStyleType() === "light" ? "dark" : "light";
  localStorage.setItem(STYLE_TYPE_KEY, nextStyleType);
  return nextStyleType;
};

export const removeLocalSetting = (key) => {
  const currentSettings =
    JSON.parse(localStorage.getItem(LOCAL_SETTINGS)) || {};
  if (currentSettings[key]) {
    delete currentSettings[key];
    localStorage.setItem(LOCAL_SETTINGS, JSON.stringify(currentSettings));
  }
};

export const setLocalSettings = (settings) => {
  const currentSettings =
    JSON.parse(localStorage.getItem(LOCAL_SETTINGS)) || {};
  const merged = { ...currentSettings, ...settings };
  localStorage.setItem(LOCAL_SETTINGS, JSON.stringify(merged));
};

export const getLocalSettings = (key) => {
  if (key === USER_SETTINGS_CONSTANTS.all) {
    return localStorage.getItem(LOCAL_SETTINGS) || {};
  }
  const currentSettings =
    JSON.parse(localStorage.getItem(LOCAL_SETTINGS)) || {};
  return currentSettings[key];
};

export const setFilter = (filter) => {
  const userId = Cookies.get("userId");
  const current = JSON.parse(localStorage.getItem(TIME_FILTER)) || {};
  if (userId) {
    const timeFilter = { ...current, [userId]: filter };
    localStorage.setItem(TIME_FILTER, JSON.stringify(timeFilter));
  }
};

export const getTimeFilter = () => {
  const userId = Cookies.get("userId");
  const current = JSON.parse(localStorage.getItem(TIME_FILTER)) || {};
  if (current && userId && current[userId]) {
    return current[userId];
  }
  return null;
};

export const setLanguage = (lang) => {
  localStorage.setItem(LANG_KEY, lang);
};

export const getLanguage = (lang) => {
  return localStorage.getItem(LANG_KEY, lang);
};

export const removeLanguagePreference = () => {
  return localStorage.removeItem(LANG_KEY);
};

export const setZone = (zone) => {
  localStorage.setItem(SELECTED_ZONE, zone);
};

export const getZone = () => {
  return localStorage.getItem(SELECTED_ZONE);
};

export const removeZone = () => {
  return localStorage.removeItem(SELECTED_ZONE);
};

export const setAuthKey = (key) => {
  localStorage.setItem(AUTH_KEY, key);
};

export const getAuthKey = () => {
  return localStorage.getItem(AUTH_KEY);
};

export const setClientId = (value) => {
  return localStorage.setItem(CLIENT_ID, value);
};

export const getClientId = () => {
  return localStorage.getItem(CLIENT_ID);
};

export const removeKey = (key) => {
  return localStorage.removeItem(key);
};

export const setMonitorSettings = (value) => {
  localStorage.setItem(MONITOR_SETTIGNS, JSON.stringify(value));
};

export const getMonitorSettings = () => {
  return JSON.parse(localStorage.getItem(MONITOR_SETTIGNS));
};

export const setDashboardZones = (value) => {
  localStorage.setItem(DASHBOARD_ACTIVE_ZONES, JSON.stringify(value));
};

export const getDashboardZones = () => {
  return JSON.parse(localStorage.getItem(DASHBOARD_ACTIVE_ZONES));
};

export const setAdminSettings = (value) => {
  localStorage.setItem(ADMIN_SETTINGS, JSON.stringify(value));
};

export const getAdminSettings = () => {
  return JSON.parse(localStorage.getItem(ADMIN_SETTINGS));
};

export const setCustomAPIEndPoint = (value) => {
  localStorage.setItem(API_END_POINT, value);
};

export const getCustomAPIEndPoint = () => {
  return localStorage.getItem(API_END_POINT);
};

export const setCustomAuthBackend = (value) => {
  localStorage.setItem(AUTH_BACKEND, value);
};

export const getCustomAuthBackend = () => {
  return localStorage.getItem(AUTH_BACKEND);
};

export const setSleepHistorySettings = (value) => {
  localStorage.setItem(SLEEP_HISTORY_SETTINGS, JSON.stringify(value));
};

export const getSleepHistorySettings = () => {
  return JSON.parse(localStorage.getItem(SLEEP_HISTORY_SETTINGS));
};

export const setSleepHistoryChartType = (value) => {
  localStorage.setItem(SLEEP_HISTORY_CHAT_TYPE, value);
};

export const getSleepHistoryChartType = () => {
  return localStorage.getItem(SLEEP_HISTORY_CHAT_TYPE);
};

export const setSleepHistoryDateFilterType = (value) => {
  localStorage.setItem(SLEEP_HISTORY_DATE_FILTER_TYPE, value);
};

export const getSleepHistoryDateFilterType = () => {
  return localStorage.getItem(SLEEP_HISTORY_DATE_FILTER_TYPE);
};
