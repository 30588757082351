export const USER_SETTINGS_CONSTANTS = {
  all: "all",
  users_per_page: "users_per_page",
  enable_heart_rate_mean: "enable_heart_rate_mean",
  devices_per_page: "devices_per_page",
  employees_per_page: "employees_per_page",
  groups_per_page: "groups_per_page",
  reports_per_page: "reports_per_page",
  user_notifications_per_page: "user_notifications_per_page",
  user_notifications_recievers_per_page:
    "user_notifications_recievers_per_page",
  notification_default_user: "notification_default_user",
  set_history_view_mode: "set_history_view_mode",
  vital_signs_settings: "vital_signs_settings",
  sleep_epoch_settings: "sleep_epoch_settings",
};

export type UserSettingsConstantsType =
  | typeof USER_SETTINGS_CONSTANTS.all
  | typeof USER_SETTINGS_CONSTANTS.users_per_page
  | typeof USER_SETTINGS_CONSTANTS.devices_per_page
  | typeof USER_SETTINGS_CONSTANTS.employees_per_page
  | typeof USER_SETTINGS_CONSTANTS.groups_per_page
  | typeof USER_SETTINGS_CONSTANTS.reports_per_page
  | typeof USER_SETTINGS_CONSTANTS.user_notifications_per_page
  | typeof USER_SETTINGS_CONSTANTS.user_notifications_recievers_per_page
  | typeof USER_SETTINGS_CONSTANTS.set_history_view_mode
  | typeof USER_SETTINGS_CONSTANTS.enable_heart_rate_mean
  | typeof USER_SETTINGS_CONSTANTS.vital_signs_settings
  | typeof USER_SETTINGS_CONSTANTS.sleep_epoch_settings;
