import { IFilter } from "interfaces/filters.interface";

import i18n from "../../../i18n/config";

export function getTagNames(): Record<keyof IFilter, string> {
  return {
    timeInBed: i18n.t("time_in_bed"),
    totalSleepTime: i18n.t("time_asleep"),
    sessionStartTime: i18n.t("session_start_time"),
  };
}

export function getSessionStartTimeOptions() {
  return [
    {
      key: i18n.t("20_to_08"),
      value: "20:00_08:00",
    },
  ];
}

// get sleep history options
export function getSleephistrotFilterOptions() {
  return [
    {
      key: i18n.t("4_hours_or_more"),
      value: "240",
    },
    {
      key: i18n.t("2_hours_or_more"),
      value: "120",
    },
    {
      key: i18n.t("1_hour_or_more"),
      value: "60",
    },
    {
      key: i18n.t("30_minutes_or_more"),
      value: "30",
    },
  ];
}

// getDateRangeOptions
export function getDateRangeOptions() {
  return [
    {
      key: i18n.t("in_the_last_90_days"),
      value: "90",
    },
    {
      key: i18n.t("in_the_last_30_days"),
      value: "30",
    },
    {
      key: i18n.t("in_the_last_14_days"),
      value: "14",
    },
    {
      key: i18n.t("in_the_last_7_days"),
      value: "7",
    },
    {
      key: i18n.t("in_the_last_3_days"),
      value: "3",
    },
  ];
}
