import {
  Dispatch,
  MouseEvent,
  TouchEvent,
  MutableRefObject,
  SetStateAction,
  createContext,
} from "react";

export interface ContextInterface {
  clientWidth: number | null;
  setClientWidth: Dispatch<SetStateAction<number | null>>;
  onMouseHoldDown: (e: MouseEvent | TouchEvent) => void;
  setTreeRef: (ref: HTMLDivElement | null) => void;
  treeRef: MutableRefObject<HTMLDivElement | null>;
}
export const SplitPaneContext = createContext<ContextInterface | null>(null);
export default SplitPaneContext;
