import { useCallback, useEffect, useMemo } from "react";
import {
  FieldErrors,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import SubTitle from "components/SMTitles/SubTitle";
import SMUpdateCancel from "components/SMUpdateCancel/SMUpdateCancel";
import { IUser } from "interfaces/user.interface";
import { IZone } from "interfaces/zone.interface";
import {
  createUser,
  updateUser,
  updateUserZone,
} from "store/actions/administration/userActions";
import { getAuthUser } from "store/actions/auth/authActions";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import { ApplyMargin } from "styled/StylesStyled";
import getErrorInputElement from "utils/common/getErrorInputElement";
import { goBack, gotTo } from "utils/history.util";
import { toastSuccessPositionCenter } from "utils/toast.util";

import UserForm from "./UserForm";
import UserZonesAndRoles from "./UserZonesAndRoles";

export default function UserInformation({
  user,
  disabled = false,
  authUser,
}: {
  user: IUser;
  disabled?: boolean;
  authUser: IUser;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAuthUser());
  }, [dispatch]);

  const defaultValues = useMemo<IUser>(
    () => ({
      ...user,
      first_name: user.first_name ?? "",
      last_name: user.last_name ?? "",
      profession: user.profession ?? "",
      roles: user.roles ?? [],
      zones: user.zones ?? {},
    }),
    [user],
  );

  const methods = useForm<IUser>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onTouched",
  });

  const resetFormFields = useCallback(() => {
    methods.reset({ ...defaultValues });
  }, [methods, defaultValues]);

  useEffect(() => {
    resetFormFields();
  }, [user, resetFormFields]);

  const onRolesUpdate = () => {
    if (user.id) {
      methods.resetField("zones.data");
      methods.handleSubmit(onSubmit)();
    }
  };

  const onZoneUpdate = () => {
    const formValues = methods.getValues();
    if (user.id) {
      const selectedZoneIds =
        formValues.zones?.data.map((item: IZone) => item.id) ?? [];
      const currentZoneIds =
        user.zones?.data?.map((item: IZone) => item.id) ?? [];

      const toDeleteIds =
        currentZoneIds?.filter((x) => !selectedZoneIds.includes(x)) ?? [];
      const toAddIds =
        selectedZoneIds.filter((x) => !currentZoneIds.includes(x)) ?? [];

      const toDelete: IZone[] =
        user.zones?.data?.filter(
          (item) => toDeleteIds.indexOf(item.id) !== -1,
        ) ?? [];
      const toAdd: IZone[] =
        formValues.zones?.data?.filter(
          (item) => toAddIds.indexOf(item.id) !== -1,
        ) ?? [];
      dispatch(updateUserZone({ data: formValues, toAdd, toDelete }));
    }
    return true;
  };

  const createUserSuccessCB = (item: IUser) => {
    toastSuccessPositionCenter(t("employee_created_successfully"));
    gotTo(`adminstration/zones/users/${item.id}`);
  };

  const updateUserErrorCB = () => {
    resetFormFields();
  };
  const errorFocusOrder: { [key: string]: string } = {
    first_name: "a",
    last_name: "b",
    email: "c",
  };

  function onErrors(err: FieldErrors<IUser>) {
    const { elem, key } = getErrorInputElement<IUser>(err, errorFocusOrder);
    if (elem && key !== "roles") elem.focus();
  }

  const onSubmit: SubmitHandler<IUser> = (data) => {
    if (data.id) {
      dispatch(updateUser(data, updateUserErrorCB));
    } else {
      dispatch(createUser(data, createUserSuccessCB));
    }
  };

  return (
    <div>
      <FormProvider {...methods}>
        <BoxSubSectionStyled>
          <SubTitle text={t("employee_information")} />
          <BoxSubSectionContentStyled>
            <UserForm user={user} onSubmit={onSubmit} disabled={disabled} />
          </BoxSubSectionContentStyled>
        </BoxSubSectionStyled>
        <ApplyMargin top={20}>
          <BoxSubSectionStyled>
            <SubTitle text={t("Zones and Roles")} />
            <BoxSubSectionContentStyled>
              <UserZonesAndRoles
                user={user}
                onZoneUpdate={onZoneUpdate}
                onRolesUpdate={onRolesUpdate}
                disabled={disabled}
                authUser={authUser}
              />
            </BoxSubSectionContentStyled>
          </BoxSubSectionStyled>
        </ApplyMargin>
        {!user.id && (
          <SMUpdateCancel
            onCancel={() => goBack()}
            onUpdate={methods.handleSubmit(onSubmit, onErrors)}
            create
          />
        )}
      </FormProvider>
    </div>
  );
}
