import { IColor } from "interfaces/color.interface";

const Teal: Omit<
  IColor,
  0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
> = {
  1: "#F2F8F9",
  5: "#D2E9ED",
  10: "#ACD6DD",
  20: "#6BB3C0",
  30: "#3692A3",
  40: "#0F7385",
  50: "#005868",
};

export default Teal;
