import { MouseEvent, RefObject } from "react";
import { Order } from "./componentsTypes/tableComponentsTypes";

export interface NightReportMetric {
  value: number;
  baseline: number;
  deviation: number;
  deviation_pct?: number;
  deviation_alert: boolean;
}

export const NightReportStatus = {
  PRESENCE_AND_SLEEP: "PRESENCE_AND_SLEEP",
  NO_PRESENCE: "NO_PRESENCE",
  NO_SLEEP: "NO_SLEEP",
};
export interface ReportZone {
  id: string;
  path: string;
  name: string;
}
export interface NightReport {
  subject: NightReportSubject;
  device: NightReportDevice;
  session_id: string;
  timeframe_start: string;
  timeframe_end: string;
  report_date: string;
  state: "PRESENCE_AND_SLEEP" | "NO_PRESENCE" | "NO_SLEEP";
  selected_session_is_in_progress: boolean;
  baseline_night_count: number;
  respiration_rate: NightReportMetric;
  movement: NightReportMetric;
  time_asleep: NightReportMetric;
  out_of_bed: NightReportMetric;
  sleep_score: NightReportMetric;
}
export interface NightReportDevice {
  id: string;
  type: string;
  serial_number: string;
  name: string;
}
export interface NightReportSubject {
  id: string;
  identifier: string;
  sex: string;
  birth_year: number;
}

export interface NightReportHeadCell {
  id: string;
  label: string;
  hideSort?: boolean;
  tooltip?: string;
  width?: string | number;
  minWidth?: string | number;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof NightReport,
  ) => void;
  order: Order;
  orderBy: keyof NightReport | undefined | string;
  headerRef?: RefObject<HTMLTableSectionElement>;
}

export type NightReportTimes = { start: string; end: string };

export const DEFAULT_NIGHT_REPORT_START = "20:00";
export const DEFAULT_NIGHT_REPORT_END = "07:00";

export type AlertMetric = "respiration_rate" | "movement" | "time_asleep";
