import Cookies from "js-cookie";
import axios, { AxiosResponse } from "axios";

import * as LOCAL_STORAGE from "utils/localStorage";
import { AUTH_TOKEN_API } from "constants/auth.constants";
import { IAuthResponse } from "interfaces/auth.interface";
import {
  clearCookies,
  getAuthBaseUrl,
  getRefreshTokenParams,
} from "utils/auth/auth.util";
import { gotTo } from "utils/history.util";

const refreshAccessToken = (dataStringified: string, tokenUrl: string) => {
  axios({
    method: "post",
    url: tokenUrl,
    timeout: 30000,
    data: dataStringified,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  })
    .then((response: AxiosResponse<IAuthResponse>) => {
      if (response.data) {
        if (response.data.access_token && response.data.refresh_token) {
          const accessTokenLifetime = response.data.expires_in || 0;
          const accessTokenExpiryDate = new Date();
          accessTokenExpiryDate.setTime(
            accessTokenExpiryDate.getTime() + accessTokenLifetime * 1000,
          );
          Cookies.set("access_token", response.data.access_token, {
            expires: accessTokenExpiryDate,
          });
          Cookies.set("userId", response.data.userId, {
            expires: accessTokenExpiryDate,
          });
          Cookies.set("refresh_token", response.data.refresh_token);
        }
      }
    })
    .catch(() => {
      clearCookies();
    });
};

const UseAccessTokenRefresh = () => {
  const tokenUrl = getAuthBaseUrl() + AUTH_TOKEN_API;

  const client_id = LOCAL_STORAGE.getClientId();
  const refreshToken = Cookies.get("refresh_token");
  if (client_id && refreshToken) {
    const refreshParams: any = getRefreshTokenParams({
      client_id,
      refresh_token: refreshToken,
    });

    const refreshData: string = Object.keys(refreshParams)
      .map((key) => `${key}=${encodeURIComponent(refreshParams[key])}`)
      .join("&");
    refreshAccessToken(refreshData, tokenUrl);
  } else {
    gotTo("auth/login", true);
  }
};
export default UseAccessTokenRefresh;
