import { useTheme } from "@mui/material/styles";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { createRef, memo } from "react";
import { useTranslation } from "react-i18next";
import ResizeDetector from "react-resize-detector";

import {
  ChartTypes,
  HighchartsType,
  SleepStagesOptionsType,
} from "interfaces/sleephistory.interface";
import { getLocalizedShortMonths } from "utils/dashboard/graph.util";
import { SleepHistorySelectedDates } from "./../SleepHistoryContext";
import { getSleepHistoryStagesChartOptions } from "./SleepHistoryStagesChartOptions";

interface SleepHistoryStagesChartMemoProps {
  onChartMouseOver: (e: Event) => void;
  options: SleepStagesOptionsType;
  selectedDates: SleepHistorySelectedDates;
  chartType: ChartTypes;
}

Highcharts.setOptions({
  time: {
    useUTC: false,
  },
  lang: {
    shortMonths: getLocalizedShortMonths(),
  },
});

function SleepHistoryStagesChartMemo({
  onChartMouseOver,
  options,
  selectedDates,
  chartType,
}: SleepHistoryStagesChartMemoProps) {
  const highchartsType: HighchartsType =
    chartType === ChartTypes.column_chart ? "column" : "areaspline";
  const startDateMs = new Date(selectedDates.from).getTime();
  const endDateMs = new Date(selectedDates.to).getTime();

  const chartRef = createRef<HighchartsReactRefObject>();
  const { t } = useTranslation();
  const theme = useTheme();

  const sleepHistoryStagesChartOptions = getSleepHistoryStagesChartOptions({
    onChartMouseOver,
    options,
    t,
    theme,
    chartType,
    highchartsType,
    startDateMs,
    endDateMs,
  });
  return (
    <ResizeDetector
      skipOnMount
      handleWidth
      onResize={() => {
        return chartRef.current ? chartRef.current.chart.reflow() : undefined;
      }}
    >
      {() => (
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          constructorType="stockChart"
          options={sleepHistoryStagesChartOptions}
        />
      )}
    </ResizeDetector>
  );
}

const SleepHistoryStagesChart = memo(SleepHistoryStagesChartMemo);
export default SleepHistoryStagesChart;
