import { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { CSSProperties } from "react";

export const TableRowStyled = styled(TableRow)(() => {
  const theme = useTheme();
  return {
    padding: "16px 20px",
    fontSize: 14,
    cursor: "pointer",
    textAlign: "left",
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.neutral[5]}`,
    color: theme.palette.neutral[40],
    [`& .${tableCellClasses.root}`]: {
      border: "none",
    },
    "&:last-child": {
      borderBottom: "none",
    },
    "& td": {
      color: theme.palette.neutral[40],
      fontSize: 16,
    },
    "& td:first-of-type": {
      color: theme.palette.neutral[70],
      fontSize: 16,
    },
  };
});

export const TableHeadRowStyled = styled(TableRowStyled)(() => {
  const theme = useTheme();
  return {
    textTransform: "uppercase",
    textAlign: "left",
    verticalAlign: "top",
    background: theme.palette.neutral[2],
    fontWeight: "normal",
    borderBottom: `1px solid ${theme.palette.neutral[5]}`,
    cursor: "default",
    // boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
    "& th": {
      fontWeight: "normal",
      fontSize: 14,
      color: theme.palette.neutral[50],
    },

    [`& .${tableCellClasses.root}`]: {
      border: "none",
    },
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.neutral[5]}`,
    },
  };
});

export const TableHeadDataStyled = styled("div")(
  ({
    cursordefault,
    alignLeft,
  }: {
    cursordefault?: number;
    alignLeft?: number;
  }) => {
    return {
      cursor: cursordefault ? "default" : "pointer",

      // direction
      "& > div": {
        display: "flex",
        flexDirection: "row",
        justifyContent: alignLeft ? "left" : "center",
        textAlign: alignLeft ? "left" : "center",
        // alignItems: "center",

        columnGap: 5,
        "& > div > svg": {
          width: 24,
          padding: 0,
          height: 24,
        },
      },

      "& > div:nth-of-type(2)": {
        marginLeft: 10,
      },
    };
  },
);

export const ErrorOutlineWrapperStyled = styled("div")(() => {
  return {
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>svg": {
      color: "rgb(165, 5, 32)",
      width: 28,
      height: 28,
      "@media print": {
        width: 12,
        height: 12,
      },
    },
  };
});

export const ErrorPlaceHolderStyled = styled("div")(() => {
  return {
    width: 28,
    height: 28,
    marginRight: 10,
    "@media print": {
      width: 16,
      height: 16,
    },
  };
});

export const DivWrapper = styled("div")(({ sx }: { sx?: CSSProperties }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...sx,
  };
});

export const ValueWrapperNoWidth = styled("div")(() => {
  return {
    display: "inline-block",
    textAlign: "left",
    "&>span": {
      wordSpacing: "-0.125em",
    },
  };
});
