import { IMonitor } from "interfaces/monitor.interface";
import * as types from "../../actionTypes/dashboard/monitorActionTypes";

export default function liveMonitorReducer(
  state: { liveMonitor?: IMonitor[] } = {},
  action: types.monitorActionTypes,
) {
  switch (action.type) {
    case types.FETCH_LIVE_MONITORING_SUCCESS:
      return {
        ...state,
        liveMonitor: action.payload,
      };
    case types.CLEAR_STATE:
      return {
        liveMonitor: undefined,
      };
    default:
      return state;
  }
}
