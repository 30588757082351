import { useState } from "react";

import { IZone } from "interfaces/zone.interface";

import { PositionRelative } from "styled/CommonStyled";
import NodeWithCheckbox from "./NodeWithCheckbox";

interface TreeBranchWithCheckboxProps {
  item: IZone;
  level: number;
  onChecked: (checked: boolean, node: IZone) => void;
  levelPadding: number;
  selected: string[];
  search?: string;
  isDisableNode: (node: IZone) => boolean;
  disabled_tooltip_message: string;
  no_permission_tooltip_message: string;
}
const TreeBranchWithCheckbox = ({
  item,
  level,
  onChecked,
  levelPadding,
  selected,
  search,
  isDisableNode,
  disabled_tooltip_message,
  no_permission_tooltip_message,
}: TreeBranchWithCheckboxProps) => {
  const [collapsed, setCollapsed] = useState(!!item.collapsed);
  const hasChildren = item?.children?.length !== 0;

  const ChildBranches = ({ childZones }: { childZones: IZone[] }) => (
    <>
      {childZones.map((child: IZone) => (
        <TreeBranchWithCheckbox
          key={child.id}
          item={child}
          level={level + 1}
          onChecked={onChecked}
          levelPadding={levelPadding}
          selected={selected}
          search={search}
          isDisableNode={isDisableNode}
          disabled_tooltip_message={disabled_tooltip_message}
          no_permission_tooltip_message={no_permission_tooltip_message}
        />
      ))}
    </>
  );

  return (
    <PositionRelative>
      <NodeWithCheckbox
        item={item}
        collapsed={collapsed}
        hasChildren={hasChildren}
        level={level}
        onToggle={() => setCollapsed((prev) => !prev)}
        levelPadding={levelPadding}
        onChecked={onChecked}
        selected={selected}
        search={search}
        isDisableNode={isDisableNode}
        disabled_tooltip_message={disabled_tooltip_message}
        no_permission_tooltip_message={no_permission_tooltip_message}
      />
      {!collapsed && item.children !== undefined && hasChildren && (
        <ChildBranches childZones={item.children} />
      )}
    </PositionRelative>
  );
};
export default TreeBranchWithCheckbox;
