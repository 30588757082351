import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import { ReactNode } from "react";

export const BoxWithPadding = ({
  children,
  pad,
}: {
  children: ReactNode;
  pad?: number | string;
}) => {
  return <BoxPadding pad={pad || 48}>{children}</BoxPadding>;
};

export default BoxWithPadding;
