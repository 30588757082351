import { Grid } from "@mui/material";
import { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import SubTitle from "components/SMTitles/SubTitle";
import UseAdminContext from "hooks/UseAdminContext";
import { ISubject } from "interfaces/subject.interface";
import { ICustomField, ITenantPublic } from "interfaces/tenant.interface";
import { updateSubject } from "store/actions/administration/subjectActions";
import { resetRulesState } from "store/actions/rules/rules";
import {
  BoxSubSectionContentStyled,
  BoxSubSectionStyled,
} from "styled/ContainerStylesStyled";
import CustomFieldGridItem from "../../../../../components/CustomField/CustomField";

export default function AdditionalSubjectInformation({
  create = false,
  subject,
  tenant,
}: {
  create?: boolean;
  subject: ISubject;
  tenant: ITenantPublic;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // use context
  const { setSelectedZone } = UseAdminContext();

  const { handleSubmit, control, reset, getValues } = useForm<ISubject>({
    defaultValues: {
      ...subject,
      // preset-values
      custom_fields: subject?.custom_fields || {},
    },
    mode: "onTouched",
  });

  const resetFormFields = useCallback(() => {
    reset({
      ...subject,
      // preset-values
      custom_fields: subject?.custom_fields || {},
    });
  }, [reset, subject]);

  useEffect(() => {
    resetFormFields();
  }, [subject, resetFormFields]);

  const updateSubjectErrorCB = () => {
    resetFormFields();
  };

  const successCallback = () => {
    const data: ISubject = getValues();
    if (setSelectedZone) {
      setSelectedZone(data.zone_id);
    }
  };

  const onSubmit: SubmitHandler<ISubject> = (data: ISubject) => {
    // The custom field values are set to "0" if the user does not select a value.
    // We don't want to send this to the backend, so we remove these fields.
    let dataToSend = data;
    if (!data) {
      dataToSend = getValues();
    }

    const refreshRules = dataToSend.zone_id !== subject?.zone_id;

    for (const key in dataToSend.custom_fields) {
      if (
        key &&
        dataToSend.custom_fields[key] &&
        dataToSend.custom_fields[key] === "0"
      ) {
        delete dataToSend.custom_fields[key];
      }
    }

    dispatchUpdateSubject(dataToSend, false, refreshRules);
  };

  // dispatch subject
  const dispatchUpdateSubject = (
    sub: ISubject,
    removeDevice: boolean,
    refreshRules?: boolean,
  ) => {
    if (refreshRules) {
      // reset rules, becuase zone changed
      dispatch(resetRulesState());
    }
    dispatch(
      updateSubject({
        subject: sub,
        removeDevice,
        updateSubjectErrorCB,
        successCallback,
        refreshRules: !!refreshRules,
      }),
    );
  };

  const subject_custom_fields = tenant?.custom_fields.filter(
    (cf: ICustomField) => cf.entity === "subject",
  );
  return (
    <div>
      <BoxSubSectionStyled>
        <SubTitle text={t("additional_subject_information_title")} />
        <BoxSubSectionContentStyled>
          <Grid container rowSpacing={0} columnSpacing={2.5}>
            {tenant && tenant?.custom_fields ? (
              Object.keys(subject_custom_fields).map((key: string) => (
                <CustomFieldGridItem
                  key={key}
                  create={create}
                  // @ts-ignore to ignore index type not found
                  custom_field={subject_custom_fields[key]}
                  control={control}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                />
              ))
            ) : (
              <></>
            )}
          </Grid>
        </BoxSubSectionContentStyled>
      </BoxSubSectionStyled>
    </div>
  );
}
