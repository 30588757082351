import axios from "axios";

import { IRule } from "interfaces/rules.interface";
import {
  handleError,
  handleResponse,
  showErrorFeedback,
} from "services/responseHandler";

import { API_SERVICES } from "../../constants/constants";

export const rulesService = {
  fetchRules,
  createRules,
  deleteRules,
  fetchRulesPresets,
  createRule,
  enableDisableRule,
};

async function fetchRules(zone_id: string) {
  const url = `${API_SERVICES.rules}?entity_id=${zone_id}`;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

async function createRules(data: IRule | Partial<IRule>) {
  const url = `${API_SERVICES.rules}`;
  return axios({
    method: "post",
    url,
    timeout: 30000,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

async function deleteRules(id: string) {
  const url = `${API_SERVICES.rules}/${id}`;
  return axios({
    method: "delete",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

async function fetchRulesPresets(type: string) {
  const url = `${API_SERVICES.rulePresets}?rule_preset_type=conditions&&rule_type=${type}`;
  return axios({
    method: "get",
    url,
    timeout: 30000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

async function createRule(data: IRule | Partial<IRule>) {
  const url = `${API_SERVICES.rules}`;
  return axios({
    method: "post",
    url,
    timeout: 30000,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

async function enableDisableRule(
  id: string,
  data: { type: string; enabled: boolean },
) {
  const url = `${API_SERVICES.rules}/${id}`;
  return axios({
    method: "patch",
    url,
    timeout: 30000,
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}
