import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ISession } from "interfaces/sleephistory.interface";
import { getLangKey } from "utils/common/locale.util";
import SingleTextField from "components/SingleTextField/SingleTextField";

function EnvironmentDetails({ session }: { session: ISession }) {
  const { t } = useTranslation();
  const localizeValue = (value: number) => {
    if (value) {
      const rounded = value.toFixed(1);
      const lang = getLangKey();
      if (lang === "nb") {
        return rounded.replace(".", ",");
      }
      return rounded;
    }
    return value;
  };

  const getAirQuality = (value: number) => {
    if (!value) return "";
    if (value < 0) {
      return "&nbsp;";
    }

    const x = Math.round(value);

    switch (true) {
      case x <= 50 && x >= 0:
        return t("Great");
      case x <= 100:
        return t("Good");
      case x <= 150:
        return t("Fair");
      case x <= 200:
        return t("Poor");
      case x <= 250:
        return t("Bad");
      case x <= 350:
        return t("Very Bad");
      case x <= 500:
        return t("Extremely Bad");
      default:
        return "&nbsp;";
    }
  };
  return (
    <Grid item xs={12} lg={12} alignItems="stretch">
      <Card>
        <CardHeader title={t("Enviornment details")} />
        <CardContent>
          <Grid container spacing={3} direction="row">
            <Grid item xs={4} lg={4}>
              <Typography align="center">{t("Temperature")}</Typography>
              <Typography variant="h4" align="center">
                {localizeValue(session.temperature_filtered_mean)} &deg;C
              </Typography>
            </Grid>
            <Grid item xs={4} lg={4}>
              <SingleTextField
                title={`${t("Humidity")}`}
                text={
                  session.air_humidity_filtered_mean
                    ? `${Math.round(session.air_humidity_filtered_mean)} %`
                    : ""
                }
              />
            </Grid>
            <Grid item xs={4} lg={4}>
              <SingleTextField
                title={t("Air Quality")}
                text={getAirQuality(session.indoor_air_quality_filtered_mean)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default EnvironmentDetails;
