import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import { USER_SETTINGS_CONSTANTS } from "constants/localSettingsConstants";
import UseDashboardContext from "hooks/UseDashboardContext";
import { NightReport } from "interfaces/nightReport.interface";
import { IZone } from "interfaces/zone.interface";
import { clearNightReport } from "store/actions/dashboard/nightReportActions";
import { setLocalSettings } from "utils/localStorage";

import { ISubject } from "interfaces/subject.interface";
import {
  getAllSubjects,
  resetSubjectState,
} from "store/actions/administration/subjectActions";
import SleepHistoryModal from "../SleepHistoryModel";
import ZoneNightReport from "./components/ZoneNightReport";
import { IRootState } from "store/reducers";

function NightReportLanding() {
  const { selectedZones, componentRef } = UseDashboardContext();
  const [selectedSession, setSelectedSession] = useState<string>();
  const [zoneSubjectMap, setZoneSubjectMap] = useState<Map<string, string[]>>(
    new Map(),
  );

  const subjectReducer = useSelector(
    (state: IRootState) => state.subjectReducer || {},
  );

  const prevMapStringRef = useRef<string>();

  useEffect(() => {
    const map = new Map<string, string[]>();

    selectedZones?.forEach((zone: IZone) => {
      const subjectIDs =
        subjectReducer.subjects
          ?.filter((item: ISubject) => item.devices?.data?.length)
          .filter((item: ISubject) => item.zone_id === zone.id)
          .map((item: ISubject) => item.id) ?? [];
      map.set(zone.id, subjectIDs);
    });

    const mapString = JSON.stringify(Array.from(map.entries()));
    if (mapString === prevMapStringRef.current) return;

    setZoneSubjectMap(map);
    prevMapStringRef.current = mapString;
  }, [subjectReducer, selectedZones]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSubjects());
    return () => {
      dispatch(resetSubjectState());
    };
  }, [dispatch]);

  const [dashboardModalSubject, setDashboardModalSubject] = useState<{
    id: string;
    identifier: string;
    birth_year: number;
    sex: string;
  }>({ id: "", identifier: "", sex: "", birth_year: 0 });

  useEffect(() => {
    return () => {
      dispatch(clearNightReport());
    };
  }, [dispatch]);

  if (!selectedZones) return <div />;

  const onNightReportRowClick = (item: NightReport) => {
    if (item?.subject?.id) {
      setSelectedSession(item.session_id);

      setLocalSettings({
        [USER_SETTINGS_CONSTANTS.set_history_view_mode]: "last_night",
      });

      setDashboardModalSubject({
        id: item.subject.id,
        identifier: item.subject.identifier,
        sex: item.subject.sex,
        birth_year: item.subject.birth_year,
      });
    } else {
      setDashboardModalSubject({
        id: "",
        identifier: "",
        sex: "",
        birth_year: 0,
      });
    }
  };

  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Night Report Screen" />

      <Grid container ref={componentRef} sx={{ width: "100%" }}>
        <div style={{ width: "100%" }} className="printContainer">
          {selectedZones.map((zone: IZone) => (
            <ZoneNightReport
              zone={zone}
              subjectIDs={zoneSubjectMap.get(zone.id) ?? []}
              key={zone.id}
              onNightReportRowClick={onNightReportRowClick}
            />
          ))}
        </div>
        <SleepHistoryModal
          setDashboardModalSubject={setDashboardModalSubject}
          dashboardModalSubject={dashboardModalSubject}
          sessionID={selectedSession ?? undefined}
        />
      </Grid>
    </>
  );
}
export default NightReportLanding;
