import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { Button, Popover, SvgIcon, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SMTextField from "components/SMTextField/SMTextField";
import { MouseEvent, useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import { useTranslation } from "react-i18next";
import { getLangKey } from "utils/common/locale.util";
import { getFnsLocale } from "utils/date.util";

function DatePicker({
  onSelect,
  defaultValue,
  valueState,
  setValueState,
  minDate,
  maxDate,
  todayButton,
  dateFilter,
}: {
  onSelect: (val: Date | null) => void;
  defaultValue?: Date | null;
  valueState?: Date;
  setValueState?: (val: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  todayButton?: boolean;
  dateFilter?: (date: Date) => boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const [dateSelected, setDateSelected] = useState<Date | null>(
    (valueState || defaultValue) ?? null,
  );
  const appTheme: Theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    onSelect((valueState || dateSelected) ?? null);
  }, [valueState, dateSelected, onSelect]);

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: MouseEvent) => setAnchorEl(e.currentTarget);

  const handleSelect = (val: Date) => {
    if (setValueState) setValueState(val);
    else setDateSelected(val);
    onClose();
  };

  const getFormattedValue = (val: Date | null) => {
    if (!val) {
      return "";
    }
    return Intl.DateTimeFormat(getLangKey(), {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(val);
  };

  return (
    <div>
      <SMTextField
        onClick={handleClick}
        defaultValue={getFormattedValue(valueState || dateSelected)}
        endAdornment={
          <>
            {todayButton && (
              <Button
                onClick={() => setValueState && setValueState(new Date())}
                color="success"
                disabled={
                  valueState?.toDateString() === new Date().toDateString()
                }
              >
                {t("today")}
              </Button>
            )}

            <SvgIcon
              htmlColor={appTheme.palette.main[70]}
              sx={{ mx: "8px" }}
              onClick={handleClick}
            >
              <InsertInvitationIcon />
            </SvgIcon>
          </>
        }
      />
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Calendar
          color="#335E66"
          onChange={(val) => handleSelect(val)}
          disabledDay={dateFilter}
          date={valueState ?? dateSelected ?? undefined}
          minDate={minDate}
          maxDate={maxDate || new Date()}
          locale={getFnsLocale()}
        />
      </Popover>
    </div>
  );
}
export default DatePicker;
