import { Alert, AlertTitle } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AUTH_TOKEN_API } from "constants/auth.constants";
import { LOGIN_PAGE } from "constants/constants";
import FullScreenLoader from "components/FullScreenLoader/FullScreenLoader";
import UseAuth from "hooks/UseAuthHook";
import { getAuthBaseUrl } from "utils/auth/auth.util";

function LoginCallbackScreen() {
  const { t } = useTranslation();
  const { loginError } = UseAuth(getAuthBaseUrl() + AUTH_TOKEN_API);
  if (loginError) {
    return (
      <Box sx={{ padding: 5, background: "#FFF" }}>
        <Alert severity="error">
          <AlertTitle>{t("login_error_title")}</AlertTitle>
          {t("login_error_message")}
          <br />
        </Alert>
        <br />
        <Link to={`${LOGIN_PAGE}`}>{t("go_to_login_page")}</Link>
      </Box>
    );
  }
  return <FullScreenLoader />;
}
export default LoginCallbackScreen;
