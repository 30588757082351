import { DependencyList, useEffect, useRef } from "react";

type CallBack = void | (() => void);

export function arrayEqual(a1: any, a2: any) {
  if (a1.length !== a2.length) return false;
  for (let i = 0; i < a1.length; i += 1) {
    if (a1[i] !== a2[i]) {
      return false;
    }
  }
  return true;
}

export function useCustomArrayEffect(cb: () => CallBack, deps: DependencyList) {
  const ref = useRef<DependencyList>(deps);
  if (!arrayEqual || !arrayEqual(deps[0], ref.current[0])) {
    ref.current = deps;
  }

  useEffect(cb, [ref.current, cb]);
}
