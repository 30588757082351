import { ITenantPublic } from "interfaces/tenant.interface";

export const FETCH_TENANT_BY_ID = "FETCH_TENANT_BY_ID";
export const FETCH_TENANT_BY_ID_SUCCESS = "FETCH_TENANT_BY_ID_SUCCESS";

export interface fetchTenantByIDActionType {
  type: typeof FETCH_TENANT_BY_ID;
}

export interface fetchTenantByIDSuccessActionType {
  type: typeof FETCH_TENANT_BY_ID_SUCCESS;
  payload: ITenantPublic;
}

export type tenantsActionTypes =
  | fetchTenantByIDActionType
  | fetchTenantByIDSuccessActionType;
