import { styled, useTheme } from "@mui/material/styles";
import packageJson from "../../../package.json";

const appVersion = packageJson.version;

const AppVersion = () => {
  const theme = useTheme();

  const AppVersionTextStyled = styled("p")(() => ({
    marginTop: "auto",
    textAlign: "center",
    color: theme.palette.neutral[40],
  }));

  return <AppVersionTextStyled>v{appVersion}</AppVersionTextStyled>;
};

export default AppVersion;
