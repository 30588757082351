import { ISession } from "interfaces/sleephistory.interface";
import { useTranslation } from "react-i18next";
import { LabelStyled } from "styled/CommonStyled";
import SessionDetailedView from "./components/SessionDetailedView";

interface LatestNightModeProps {
  latestSession: ISession | undefined;
  isNoSleepData: boolean;
}
const LatestNightMode = ({
  latestSession,
  isNoSleepData,
}: LatestNightModeProps) => {
  const { t } = useTranslation();
  return (
    <>
      {latestSession && <SessionDetailedView session={latestSession} />}
      {isNoSleepData && (
        <LabelStyled sx={{ padding: 2, fontSize: 18 }}>
          {t("no_sleep_data_to_show")}
        </LabelStyled>
      )}
    </>
  );
};

export default LatestNightMode;
