import { ISession } from "interfaces/sleephistory.interface";
import { sleepHistoryServices } from "services/sleephistory/sleephistoryService";

import * as types from "../../actionTypes/sleephistory/sleephistoryActionTypes";

export const clearSleepHistoryAction =
  (): types.clearSleepHistoryActionType => {
    return {
      type: types.CLEAR_STATE,
    };
  };

export const fetchSleepHistoryAction =
  (): types.fetchSleepHistoryActionType => {
    return {
      type: types.FETCH_SLEEP_HISTORY,
    };
  };

export const fetchSleepHistoryActionSuccess = (
  data: ISession[],
): types.fetchSleepHistorySuccessActionType => {
  return {
    type: types.FETCH_SLEEP_HISTORY_SUCCESS,
    payload: data,
  };
};

export const fetchSleepHistoryByIDAction =
  (): types.fetchSleepHistoryByIDActionType => {
    return {
      type: types.FETCH_SLEEP_HISTOR_BY_ID_ACTION,
    };
  };

export const fetchSleepHistoryByIDActionSuccess = (
  data: ISession,
): types.fetchSleepHistoryByIDSuccessActionType => {
  return {
    type: types.FETCH_SLEEP_HISTOR_BY_ID_SUCCESS,
    payload: data,
  };
};

export function fetchSleepHistory({
  from,
  to,
  subject_id,
  totalSleepTime,
  timeInBed,
  sessionStartTime,
  latestSession,
}: {
  from: string;
  to: string;
  subject_id: string;
  totalSleepTime?: string | null;
  timeInBed?: string | null;
  sessionStartTime?: { start: string; end: string };
  latestSession: boolean;
}) {
  return function (dispatch: (arg0: any) => void) {
    sleepHistoryServices
      .fetchSleepHistory({
        from,
        to,
        subject_id,
        totalSleepTime,
        timeInBed,
        sessionStartTime,
        latestSession,
      })
      .then((response: any) => {
        if (response?.data) {
          dispatch(fetchSleepHistoryActionSuccess(response.data));
        }
      });
  };
}

export function fetchSleepHistoryByID({ id }: { id: string }) {
  return function (dispatch: (arg0: any) => void) {
    sleepHistoryServices.fetchSleepHistoryByID(id).then((response: any) => {
      if (response?.data) {
        dispatch(fetchSleepHistoryByIDActionSuccess(response?.data));
      }
    });
  };
}

export function clearSleepHistory() {
  return function (dispatch: (arg0: any) => void) {
    // clearLiveMonitoring
    dispatch(clearSleepHistoryAction());
  };
}
