import { NightReport } from "interfaces/nightReport.interface";
import { timesEquals } from "utils/date.util";
import * as types from "../../actionTypes/dashboard/nightReportActionTypes";

export default function nightReportReducer(
  state: { nightReport?: NightReport[] } = {},
  action: types.NightReportActionTypes,
) {
  switch (action.type) {
    case types.FETCH_NIGHT_REPORT_SUCCESS:
      return {
        ...state,
        nightReport: [
          action.payload,
          ...(state.nightReport?.filter(
            (report: NightReport) =>
              !(
                report.subject.id === action.payload.subject.id &&
                report.report_date === action.payload.report_date &&
                timesEquals(
                  report.timeframe_start,
                  action.payload.timeframe_start,
                ) &&
                timesEquals(report.timeframe_end, action.payload.timeframe_end)
              ),
          ) ?? []),
        ],
      };
    case types.CLEAR_NIGHT_REPORT:
      return {
        ...state,
        nightReport: undefined,
      };
    default:
      return state;
  }
}
