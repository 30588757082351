import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { CSSProperties } from "react";

/**
 * ```
 * div (bg) {
 *  flex: 1,
 *  background: bg === "light" ? theme.palette.neutral[3] : "",
 * }
 * ```
 */
export const FlexOne = styled("div")(({ bg }: { bg?: string }) => {
  const theme = useTheme();
  return {
    flex: 1,
    background: bg === "light" ? theme.palette.neutral[3] : "",
  };
});

export const DividerRow = styled("div")(() => {
  const theme = useTheme();
  return {
    width: 20,
    cursor: "ew-resize",
    background: theme.palette.neutral[3],
    borderLeft: `1px solid ${theme.palette.neutral[5]}`,
    zIndex: 0,
    "&:hover": {
      borderLeft: `1px solid ${theme.palette.main[70]}`,
    },
  };
});

export const SplitPanelContainer = styled("div")(() => {
  return {
    minHeight: "calc(100vh - 55px)",
    display: "flex",
  };
});

export const BoxPadding = styled("div")(
  ({ pad, sx }: { pad?: number | string; sx?: CSSProperties }) => {
    return {
      padding: pad || 0,
      float: "left",
      width: "100%",
      ...sx,
    };
  },
);
