import { IMonitor } from "interfaces/monitor.interface";
import { monitorServices } from "services/dashboard/monitorService";

import * as types from "../../actionTypes/dashboard/monitorActionTypes";

/* ************************************************** */
/*                      Actions                       */
/* ************************************************** */

export const clearLiveMonitoringAction =
  (): types.clearLiveMonitoringActionType => {
    return {
      type: types.CLEAR_STATE,
    };
  };

/**
 * Redux action to fetch all zones
 *
 * @return type - redux action type |
 */
export const fetchLiveMonitorAction =
  (): types.fetchLiveMonitoringActionType => {
    return {
      type: types.FETCH_LIVE_MONITORING,
    };
  };

/**
 * Redux monitor success action
 *
 * @return type - redux action type |
 *         payload - subjects
 */
export const fetchLiveMonitorActionSuccess = (
  data: IMonitor[],
): types.fetchLiveMonitoringSuccessActionType => {
  return {
    type: types.FETCH_LIVE_MONITORING_SUCCESS,
    payload: data,
  };
};

/* ************************************************** */
/*                      Services                      */
/* ************************************************** */

/**
 *  Function to fetch all the subjects
 *
 * @param none
 * @return all the subjects in the system
 */
export function fetchLiveMonitoring(zones: string[]) {
  return function (dispatch: (arg0: any) => void) {
    monitorServices
      .fetchLiveMonitor(zones)
      .then((response: { data: IMonitor[] }) => {
        if (response?.data) {
          dispatch(fetchLiveMonitorActionSuccess(response.data));
        }
      });
  };
}

export function clearLiveMonitoring() {
  return function (dispatch: (arg0: any) => void) {
    // clearLiveMonitoring
    dispatch(clearLiveMonitoringAction());
  };
}
