import axios, { AxiosResponse } from "axios";

import { IUser } from "interfaces/user.interface";

import { API_SERVICES } from "../../constants/constants";
import {
  handleError,
  handleResponse,
  showErrorFeedback,
  showMultipleErrorFeedback,
} from "../responseHandler";

export const usersServices = {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  fetchUserByID,
  attachMultipleZoneToUser,
  addUserInZone,
};

/**
 * This function fetch all the subject
 * @param none
 * @return all the subjects in the system
 */
async function fetchUsers() {
  const url = `${API_SERVICES.users}?embed=zones`;
  return axios({
    method: "get",
    url,
    timeout: 30000,

    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

/**
 * This function will fetch a single user by id
 * @param id:user id
 * @return user entity
 */
async function fetchUserByID(id: string) {
  const url = `${API_SERVICES.users}/${id}?embed=zones`;
  return axios({
    method: "get",
    url,
    timeout: 30000,

    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      return handleError(error);
    });
}

/**
 * This function create a subject
 * @param name - subject object
 * @return created subject
 */
async function createUser(data: IUser) {
  const url = API_SERVICES.users;
  return axios({
    method: "post",
    url,
    timeout: 30000,

    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

/**
 * This function to update a subject
 * @param name - subject object
 * @return created subject
 */
async function updateUser(data: IUser) {
  const url = `${API_SERVICES.users}/${data.id}`;
  return axios({
    method: "patch",
    url,
    timeout: 30000,

    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return handleError(error);
    });
}

/**
 * This function to delete a subject
 * @param name - subject object
 * @return success response
 */
async function deleteUser(data: IUser) {
  const url = `${API_SERVICES.users}/${data.id}`;
  return axios({
    method: "delete",
    url,
    timeout: 30000,

    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response: any) => {
      return handleResponse(response);
    })
    .catch((error: any) => {
      showErrorFeedback(error);
      return "error";
    });
}

export async function attachMultipleZoneToUser({
  userId,
  toAdd,
  toDelete,
}: {
  userId: string;
  toAdd: string[];
  toDelete?: string[];
}) {
  const toDeleteSources =
    toDelete?.map((item) => {
      return {
        zone_id: item,
        url: `${API_SERVICES.users}/${userId}/zones/${item}`,
      };
    }) || [];

  const toAddSources =
    toAdd?.map((item) => {
      return {
        zone_id: item,
        url: `${API_SERVICES.users}/${userId}/zones/${item}`,
      };
    }) || [];

  const taskTodelete = toDeleteSources.map((source) =>
    axios.delete(source.url, {
      data: source,
    }),
  );

  const taskDeleteResponse = await Promise.allSettled(taskTodelete);
  showMultipleErrorFeedback([...taskDeleteResponse]);

  const taskToAdd = toAddSources.map((source) =>
    axios.post(source.url, source),
  );
  const taskUpdateResponse = await Promise.allSettled(taskToAdd);
  showMultipleErrorFeedback([...taskUpdateResponse]);

  const response: PromiseSettledResult<AxiosResponse<any>>[] =
    taskDeleteResponse
      .concat(taskUpdateResponse)
      .filter((item) => item.status === "rejected") as PromiseSettledResult<
      AxiosResponse<any>
    >[];

  return response;

  // return Promise.allSettled(promisesToAdd.concat(promisesToDelete)).then(
  //   (
  //     values:
  //       | PromiseSettledResult<AxiosResponse<any>>[]
  //       | PromiseRejectedResult[]
  //   ) => {
  //     showMultipleErrorFeedback(values);
  //     const successResponse: PromiseSettledResult<AxiosResponse<any>>[] =
  //       values.filter(
  //         (item) => item.status === "fulfilled"
  //       ) as PromiseSettledResult<AxiosResponse<any>>[];
  //     return successResponse;
  //   }
  // );
}

// add user in group
async function addUserInZone(zone_id: string, user_id: string) {
  const url = `${API_SERVICES.users}/${user_id}/zones/${zone_id}`;
  return axios({
    method: "post",
    url,
    timeout: 30000,

    data: {
      zone_id,
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
}
