import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import {
  AuthorizationContext,
  ZonePermissionEnum,
} from "authorization/AuthorizationContext";
import BoxWithPadding from "components/BoxWithPadding/BoxWithPadding";
import SMGoogleAnalytic from "components/GoogleAnalytic/GoogleAnalytic";
import SMAlertDialog from "components/SMDialogs/SMAlertDialog";
import SMConfirmDialog from "components/SMDialogs/SMConfirmDialog";
import Maintitle from "components/SMTitles/MainTitle";
import UseAdminContext from "hooks/UseAdminContext";
import { useCustomArrayEffect } from "hooks/UseCustomArrayEffect";
import { IZone } from "interfaces/zone.interface";
import {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteZone } from "store/actions/administration/zoneActions";
import { ApplyFloat, ApplyMargin } from "styled/StylesStyled";
import { gotTo } from "utils/history.util";
import * as LOCAL_STORAGE from "utils/localStorage";
import { toastSuccessPositionCenter } from "utils/toast.util";
import NightReportSettings from "./NightReportSettings";
import ZoneInformation from "./components/ZoneInformation";

const ButtonStyled = styled(Button)(() => {
  const theme = useTheme();
  return {
    marginRight: 4,
    color: theme.palette.main[70],
    fontWeight: 500,
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: 16,
    paddingRight: 12,
    paddingLeft: 12,
    marginBottom: 0,
    "&:hover": {
      background: "#FFF",
    },
    "> span": {
      marginLeft: 0,
    },
    "& svg": {
      width: 24,
      height: 24,
    },
  };
});

// lazy load component
const NotificationScreen = lazy(
  () => import("../notification/NotificationScreen"),
);
function ZoneSettingsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [zone, setZone] = useState<IZone>();
  const [openZoneDeleteErrorAlert, setOpenZoneDeleteErrorAlert] =
    useState<boolean>(false);
  const { permissions } = useContext(AuthorizationContext);

  const { zones, selectedZone, setSelectedZone } = UseAdminContext();

  const disableZoneUpdate =
    permissions.indexOf(ZonePermissionEnum["zone.update"]) === -1;

  const setItem = useCallback(() => {
    if (!selectedZone || !zones) return;

    const currentZone = zones.find((item: IZone) => item.id === selectedZone);

    if (currentZone) setZone(currentZone);
  }, [selectedZone, zones]);

  useCustomArrayEffect(() => {
    if (selectedZone) setItem();
  }, [zones]);

  useEffect(() => setItem(), [selectedZone, setItem]);

  const onBack = () => {
    gotTo(`adminstration/zones/devices`);
  };

  const deleteSuccessCallBack = (item: IZone) => {
    toastSuccessPositionCenter(t("Zone deleted successfully"));

    if (item.parent_id) {
      LOCAL_STORAGE.setZone(item.parent_id);
      if (setSelectedZone) setSelectedZone(item.parent_id);
      gotTo(`adminstration/zones/devices`);
    }
  };

  const errorCallback = () => setOpenZoneDeleteErrorAlert(true);

  const onDelete = () => {
    if (zone && zone.id) {
      dispatch(deleteZone(zone, deleteSuccessCallBack, errorCallback));
    }
  };
  return (
    <>
      {/* Add google analytic */}
      <SMGoogleAnalytic title="Zone Settings Screen" />

      <BoxWithPadding pad="24px 24px 24px 4px">
        <ApplyMargin bottom={21}>
          <ApplyFloat float="left">
            <Maintitle
              text={`${t("Zone Settings")} - ${zone?.name}`}
              showBack={false}
            />
          </ApplyFloat>

          <ApplyFloat float="right">
            <ButtonStyled startIcon={<CloseIcon />} onClick={onBack}>
              {t("close")}
            </ButtonStyled>
          </ApplyFloat>
        </ApplyMargin>
        <Grid container rowSpacing={0}>
          <Grid container rowSpacing={2.5}>
            <Grid item xs={12} md={12}>
              {zone && (
                <ZoneInformation zone={zone} disabled={disableZoneUpdate} />
              )}
            </Grid>
            {zone && (
              <Grid item xs={12} md={12}>
                <NightReportSettings
                  selectedZone={zone}
                  disabled={disableZoneUpdate}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Suspense fallback={<span>Loading...</span>}>
                <NotificationScreen />
              </Suspense>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            {zone && (
              <SMConfirmDialog
                title={t("Delete zone")}
                dialogDesc={t("Are you sure you want to delete this zone?")}
                onDelete={onDelete}
                buttonOk={t("Delete")}
                buttonCancel={t("Cancel")}
                okButtonBg="red"
                disableDelete={
                  permissions.indexOf(ZonePermissionEnum["zone.delete"]) === -1
                }
              />
            )}
          </Grid>
        </Grid>
        <SMAlertDialog
          title={t("information")}
          dialogDesc={t("cannot_delete_the_selected_zone_as_it_is_not_empty")}
          buttonOk={t("ok")}
          open={openZoneDeleteErrorAlert}
          setOpen={setOpenZoneDeleteErrorAlert}
        />
      </BoxWithPadding>
    </>
  );
}
export default ZoneSettingsPage;
