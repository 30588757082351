import { Redirect, Route, Switch } from "react-router-dom";

import { basePath } from "constants/constants";
import { AppRouteProps } from "interfaces/router.interface";
import { ContentWrapper } from "styled/ContainerStylesStyled";

import AppVersion from "components/AppVersion/AppVersion";
import { FlexOne } from "components/ResizablePanel/SplitPanelStyledComponents";
import i18n from "../../../i18n/config";
import CreateSubject from "../create/CreateSubject";
import CreateUser from "../create/CreateUser";
import CreateZone from "../create/CreateZone";
import ZoneLandingPage from "../zones/ZoneLandingPage";
import ZoneSettingsPage from "../zones/zoneSettings/ZoneSettingsPage";

const adminRoutes: AppRouteProps[] = [
  {
    path: `${basePath}/adminstration/zones`,
    name: i18n.t("Zones"),
    exact: false,
    component: ZoneLandingPage,
  },
  {
    path: `${basePath}/adminstration`,
    redirectPath: `${basePath}/adminstration/zones/devices`,
    name: i18n.t("Zones"),
    exact: true,
    primary: false,
  },
  {
    path: `${basePath}/adminstration/create-subject`,
    name: i18n.t("Create Subject"),
    exact: false,
    component: CreateSubject,
  },
  {
    path: `${basePath}/adminstration/create-zone`,
    name: i18n.t("Create Subject"),
    exact: false,
    component: CreateZone,
  },
  {
    path: `${basePath}/adminstration/create-user`,
    name: i18n.t("create_employee"),
    exact: false,
    component: CreateUser,
  },
  {
    path: `${basePath}/adminstration/settings`,
    name: i18n.t("Zone Settings"),
    exact: true,
    component: ZoneSettingsPage,
  },
];

export const AdminMainContainer = () => {
  return (
    <FlexOne
      bg="light"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "auto",
      }}
    >
      <ContentWrapper
        style={{ flex: "1", display: "flex", flexDirection: "column" }}
      >
        <Switch>
          {adminRoutes.map((route: AppRouteProps) => (
            <Route
              exact={route.exact}
              key={route.name}
              path={route.path}
              render={() => {
                const Component = route.component;
                return Component ? (
                  <Component />
                ) : (
                  <Redirect to={route.redirectPath || "/home"} />
                );
              }}
            />
          ))}
        </Switch>
        <AppVersion />
      </ContentWrapper>
    </FlexOne>
  );
};

export default AdminMainContainer;
