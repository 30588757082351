import { Button, Grid, Menu, Stack, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { endOfDay } from "date-fns";
import { MouseEvent, MouseEventHandler, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import SMButtonNew from "components/SMButton/SMButtonNew";
import DatePicker from "components/SMDatePicker/DatePicker";
import { getDateRangeOptions } from "components/StyledMenu/SMMenuComponents/MenuOptions";
import MenuOptionsComponent from "components/StyledMenu/SMMenuComponents/MenuOptionsComponent";
import { getLocale, toIsoString } from "utils/date.util";

import {
  SleepHistoryContext,
  SleepHistoryContextType,
  SleepHistorySelectedDates,
} from "../SleepHistoryContext";

function SMRangePicker() {
  const { t } = useTranslation();
  const appTheme: Theme = useTheme();

  const { selectedDates, setSelectedDates } =
    useContext<SleepHistoryContextType>(SleepHistoryContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDateLabel = () => {
    if (selectedDates?.from && selectedDates?.to) {
      const from = new Intl.DateTimeFormat(getLocale(), {
        month: "long",
        day: "numeric",
        year: "numeric",
        // timeZone: "UTC"
      }).format(new Date(selectedDates.from));

      const to = new Intl.DateTimeFormat(getLocale(), {
        month: "long",
        day: "numeric",
        year: "numeric",
        // timeZone: "UTC"
      }).format(new Date(selectedDates.to));
      return `${from} - ${to}`;
    }
    return "";
  };

  // on DateSelect
  const onSelect = (val: string) => {
    const today = new Date();
    const to = toIsoString(endOfDay(today));

    if (!Number.isNaN(+val)) {
      const from = new Date(today.setDate(today.getDate() - +val));
      const selected: IRange = {
        from: toIsoString(from),
        to,
        type: val,
      };
      setSelectedDates(selected);

      // close menu
      handleClose();
    }
  };

  // get selected value
  const getSelected = (): string => {
    return selectedDates.type || "";
  };

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          color: `${appTheme.palette.main[70]}`,
          fontSize: 14,
          textTransform: "capitalize",
          "&:hover": {
            background: appTheme.palette.main[2],
          },
        }}
      >
        {getDateLabel()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ sx: { width: "390px" } }}
      >
        <Box>
          <MenuOptionsComponent
            options={getDateRangeOptions()}
            customComponent={<SMDatePicker handleClose={handleClose} />}
            title={t("show_sessions_with_start_date")}
            onSelect={onSelect}
            selected={getSelected()}
          />
        </Box>
      </Menu>
    </>
  );
}
export default SMRangePicker;

//
type IRange = SleepHistorySelectedDates;
function SMDatePicker({
  handleClose,
}: {
  handleClose: (
    e: MouseEventHandler<HTMLLIElement> | undefined | {},
    reason?: "backdropClick" | "escapeKeyDown",
  ) => void;
}) {
  const { t } = useTranslation();
  const { selectedDates, setSelectedDates } =
    useContext<SleepHistoryContextType>(SleepHistoryContext);
  const [range, setRange] = useState<{ from: string; to: string }>({
    ...selectedDates,
  });

  const handleOnChange = (val: Date | null, type: keyof IRange) => {
    let dateString = val ? toIsoString(val) : "";
    if (type === "to" && val) {
      dateString = toIsoString(endOfDay(val));
    }
    setRange((prev: IRange) => {
      return {
        ...prev,
        [type]: dateString,
      };
    });
  };

  const updateDateRange = () => {
    if (range && range.from && range.to) {
      setSelectedDates({ ...range, type: "custom" });
      handleClose({});
    }
  };

  const isDisable = () => {
    if (
      range.from &&
      range.to &&
      !Number.isNaN(new Date(range.from).getTime()) &&
      !Number.isNaN(new Date(range.to).getTime())
    ) {
      return false;
    }
    return false;
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DatePicker
            onSelect={(val: Date | null) => handleOnChange(val, "from")}
            defaultValue={range.from ? new Date(range.from) : null}
            maxDate={range.to ? new Date(range.to) : new Date()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            onSelect={(val: Date | null) => handleOnChange(val, "to")}
            defaultValue={range.to ? new Date(range.to) : null}
            minDate={range.from ? new Date(range.from) : undefined}
          />
        </Grid>
      </Grid>
      <Stack direction="row-reverse" sx={{ marginTop: "20px" }}>
        <SMButtonNew
          text={t("apply_filter")}
          onClick={updateDateRange}
          disabled={isDisable()}
        />
      </Stack>
    </Box>
  );
}
