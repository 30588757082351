import { createContext, Dispatch, SetStateAction } from "react";

import { IFilter } from "interfaces/filters.interface";
import { ChartTypes, ISession } from "interfaces/sleephistory.interface";

export interface SleepHistorySelectedDates {
  from: string;
  to: string;
  type?: string;
}
export type SleepHistoryContextType = {
  selectedDates: SleepHistorySelectedDates;
  setSelectedDates: Dispatch<SetStateAction<SleepHistorySelectedDates>>;
  viewMode: "last_night" | "history";
  setViewMode: Dispatch<SetStateAction<"last_night" | "history">>;
  enableHeartRateMean: boolean;
  setCurrentSession: Dispatch<SetStateAction<ISession | undefined>>;
  disableNext: boolean;
  disablePrev: boolean;
  activeChart: ChartTypes;
  setActiveChart: Dispatch<SetStateAction<ChartTypes>>;
  sleepHistoryFilters: IFilter;
  setSleepHistoryFilters: Dispatch<SetStateAction<IFilter>>;
};

export const SleepHistoryContext = createContext<SleepHistoryContextType>(
  {} as SleepHistoryContextType,
);
