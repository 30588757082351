import { IColor } from "interfaces/color.interface";

const Orange: Omit<
  IColor,
  0 | 2 | 3 | 4 | 6 | 7 | 8 | 9 | 60 | 70 | 80 | 90 | 100 | 110
> = {
  1: "#FFFFF7",
  5: "#FFFFDF",
  10: "#FFF4C2",
  20: "#FFD88E",
  30: "#FFBD5C",
  40: "#FFA42D",
  50: "#FF8B00",
};

export default Orange;
