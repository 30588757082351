import { styled } from "@mui/system";
import { useFormContext } from "react-hook-form";

import { ITileSettings } from "@modules/monitor/DashboardScreen";
import { ArrowStraightIcon } from "components/icons";
import { BoxPadding } from "components/ResizablePanel/SplitPanelStyledComponents";
import { IMonitor } from "interfaces/monitor.interface";
import { IZone } from "interfaces/zone.interface";
import {
  MonitorGrid,
  SMTableWrapperBox,
  TableTitle,
} from "styled/CommonStyled";

import { DeviationAlerts, LiveDataTableProps } from "../SubjectOverview";
import LiveMonitorTile from "./LiveMonitorTile";

const ArrowStraightStyled = styled(ArrowStraightIcon)(() => ({
  marginRight: 8,
  marginLeft: 8,
}));

function MonitorZoneTiles({
  liveData,
  zoneEntity,
  onTileClick,
  deviationAlertsList,
}: {
  liveData: LiveDataTableProps;
  zoneEntity: { [key: string]: IZone };
  onTileClick: (item: IMonitor) => void;
  deviationAlertsList?: DeviationAlerts[];
}) {
  const { watch } = useFormContext<ITileSettings>();
  const tileScale = watch("tileScale", 50);
  const showArrow = (paths: string[], path: string) =>
    paths.indexOf(path) !== paths.length - 1;

  return (
    <SMTableWrapperBox key={liveData.zone} sx={{ width: "100%" }}>
      <TableTitle
        sx={{
          padding: "16.5px 20px",
          borderRadius: "8px 8px 0px 0px",
          background: "#FFF",
        }}
      >
        {zoneEntity &&
          liveData.path
            .filter((path: string) => zoneEntity[path])
            .map((path: string) => (
              <span key={zoneEntity[path].name}>
                {zoneEntity[path].name}{" "}
                {showArrow(liveData.path, path) && <ArrowStraightStyled />}{" "}
              </span>
            ))}
      </TableTitle>
      <BoxPadding pad="0 16px 16px 16px" sx={{ background: "#FFF" }}>
        <MonitorGrid tilescale={tileScale} key={liveData.zone}>
          {liveData.data?.map((item: IMonitor) => (
            <LiveMonitorTile
              data={item}
              key={`${item.device.id} ${item.subject?.id}`}
              onClick={onTileClick}
              deviationAlerts={deviationAlertsList?.find(
                (val: DeviationAlerts) => val.subjectID === item.subject?.id,
              )}
            />
          ))}
        </MonitorGrid>
      </BoxPadding>
    </SMTableWrapperBox>
  );
}
export default MonitorZoneTiles;
