import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

import { SubTitleStyled } from "styled/CommonStyled";
import { WrapperStyled } from "styled/ContainerStylesStyled";

export const StyledEditIcon = styled(EditIcon)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
    cursor: "pointer",
    fontSize: 21,
  };
});

function SubTitle({ text }: { text: string }) {
  return (
    <WrapperStyled>
      <SubTitleStyled>
        <div style={{ textTransform: "uppercase" }}>{text}</div>
      </SubTitleStyled>
    </WrapperStyled>
  );
}

export default SubTitle;
