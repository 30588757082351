import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import { TextCenter } from "styled/CommonStyled";

export const MemoizedDeviceStatus = memo(function DeviceStatus({
  deviceStatus,
}: {
  deviceStatus: boolean | undefined;
}) {
  const { t } = useTranslation();
  return (
    <div className="flex_center">
      {deviceStatus === undefined && <TextCenter>-</TextCenter>}
      {deviceStatus === false && (
        <div className="chip_offline">{t("offline")}</div>
      )}
      {deviceStatus === true && (
        <div className="chip_online">{t("online")}</div>
      )}
      <Box sx={{ visibility: "hidden" }} width="24px" />
    </div>
  );
});

export const MemoizedSex = memo(function Sex({ gender }: { gender: string }) {
  const { t } = useTranslation();
  if (gender === "male") {
    return (
      <div className="flex_center">
        <div className="chip_male">{t("Male")}</div>
        <Box sx={{ visibility: "hidden" }} width="24px" />
      </div>
    );
  }
  if (gender === "female") {
    return (
      <div className="flex_center">
        <div className="chip_female">{t("Female")}</div>
        <Box sx={{ visibility: "hidden" }} width="24px" />
      </div>
    );
  }
  return <></>;
});
