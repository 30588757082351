import { AdministrationIcon, DashboardIcon } from "components/icons";
import { AppMenuProps } from "interfaces/router.interface";
import { ZoneUserRolesEnum } from "constants/userContstants";
import { basePath } from "constants/constants";
import AdministrationScreen from "@modules/administration/AdministrationScreen";
import MonitorScreen from "@modules/monitor/DashboardScreen";
import UserProfileScreen from "@modules/administration/userProfile/UserProfileScreen";

// The routes
const adminRoutes: AppMenuProps[] = [
  {
    path: `${basePath}/monitor`,
    name: "dashboard",
    icon: DashboardIcon,
    slug: "monitor",
    component: MonitorScreen,
    layout: "/adminLayout",
    allowedRoles: [
      ZoneUserRolesEnum.DATA_READER,
      ZoneUserRolesEnum.DATA_READER_LIMITED,
      ZoneUserRolesEnum.SERVICE_MONITOR,
    ],
  },
  // {
  //   path: `${vrsion2BasePath}/sleephistory/:id`,
  //   name: "dashboard",
  //   icon: DashboardIcon,
  //   slug: "monitor",
  //   component: SleepHistoryScreen,
  //   layout: "/adminLayout",
  //   allowedRoles: [
  //     ZoneUserRolesEnum.DATA_READER,
  //     ZoneUserRolesEnum.DATA_READER_LIMITED
  //   ]
  // },
  {
    path: `${basePath}/adminstration`,
    name: "Administration",
    icon: AdministrationIcon,
    mini: "ST",
    slug: "administration",
    component: AdministrationScreen,
    layout: "/adminLayout",
    allowedRoles: [
      ZoneUserRolesEnum.ADMIN,
      ZoneUserRolesEnum.MANAGER,
      ZoneUserRolesEnum.MANAGER_LIMITED,
      ZoneUserRolesEnum.OWNER,
    ],
  },
  {
    path: `${basePath}/profile`,
    name: "employee_profile",
    slug: "employee_profile",
    component: UserProfileScreen,
    layout: "/adminLayout",
    allowedRoles: [
      ZoneUserRolesEnum.ADMIN,
      ZoneUserRolesEnum.MANAGER,
      ZoneUserRolesEnum.MANAGER_LIMITED,
      ZoneUserRolesEnum.OWNER,
      ZoneUserRolesEnum.DATA_READER,
      ZoneUserRolesEnum.DATA_READER_LIMITED,
      ZoneUserRolesEnum.SERVICE_MONITOR,
    ],
  },
];

export default adminRoutes;

// {
//     path: `${vrsion2BasePath}/profile`,
//     name: i18n.t("employee_profile"),
//     exact: false,
//     component: UserProfileScreen
//   }
