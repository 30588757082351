import { Box } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import TextField, { textFieldClasses } from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useMemo } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";

import { DownArrowOutlineIcon } from "components/icons";
import { ErrorMessage } from "styled/StylesStyled";

interface Props<T> {
  field: ControllerRenderProps<any, any>;
  options: T[];
  fieldState: ControllerFieldState;
  placeholder?: string;
  onUpdate: () => void;
  freeSolo?: boolean;
  autoSelect?: boolean;
  disabled?: boolean;
  renderValueAsObject?: boolean;
}

const AutocompleteWrapper = styled("div")(
  ({ borderRed }: { borderRed: number }) => {
    const theme = useTheme();
    return {
      [`& .${autocompleteClasses.root}`]: {
        color: theme.palette.neutral[70],
        "& .Mui-disabled": {
          cursor: "not-allowed",
          background: theme.palette.neutral[5],
        },
        "& .MuiAutocomplete-endAdornment": {
          "& .MuiAutocomplete-popupIndicator": {
            position: "absolute",
            right: 20,
            top: 8,
            fontSize: 14,
          },
        },
        "& svg": {},
        "& ::after": {
          border: "none",
        },
        [`& .${textFieldClasses.root}`]: {
          "& .MuiInput-root": {
            outline: "none",
            border: !borderRed
              ? `1px solid ${theme.palette.neutral[5]}`
              : `1px solid ${theme.palette.red[50]}`,
            borderRadius: 4,
            paddingLeft: 8,
            height: 48,
            fontSize: 16,
            "& .MuiInput-input": {
              "&::placeholder": {
                //
              },
            },
          },
          "&:focus": {
            outline: "none",
          },
          "& ::before": {
            border: "none",
          },
        },
      },
    };
  },
);

function AutoSuggestFormDropDown<
  T extends { label: string; value: string } | number,
>({
  field,
  options,
  fieldState,
  onUpdate,
  placeholder,
  freeSolo = false,
  autoSelect = false,
  disabled,
  renderValueAsObject = false,
}: Props<T>) {
  const onChange = (event: any, data: any) => {
    if (data) {
      field.onChange(data);
      onUpdate();
    }
  };

  const { error } = fieldState;
  const memoizeOptions = useMemo(() => {
    return options;
  }, [options]);

  const getValue = () => {
    const val = (
      options as unknown as { id: string; label: string; value: string }[]
    ).find((item) => item.value === field.value);
    if (val) {
      return (val as { value: string; label: string; id: string }).label;
    }
    return field.value;
  };

  return (
    <>
      <AutocompleteWrapper borderRed={error?.message ? 1 : 0}>
        <Autocomplete
          {...field}
          freeSolo={freeSolo}
          value={renderValueAsObject ? getValue() : field.value}
          disablePortal
          disabled={!!disabled}
          autoSelect={autoSelect}
          options={memoizeOptions}
          getOptionLabel={(option) => {
            return option?.label?.toString() ?? option.toString();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={placeholder}
            />
          )}
          onChange={(event, data) => {
            onChange(event, data?.value ? data?.value : data);
          }}
          popupIcon={<DownArrowOutlineIcon />}
          clearIcon={null}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props} sx={{ fontSize: 16 }}>
                <div> {option?.label || option} </div>
              </Box>
            );
          }}
        />
      </AutocompleteWrapper>
      {error?.message ? <ErrorMessage>{error?.message}</ErrorMessage> : ""}
    </>
  );
}

export default AutoSuggestFormDropDown;
