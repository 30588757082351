import { ISession } from "interfaces/sleephistory.interface";

import * as types from "../../actionTypes/sleephistory/sleephistoryActionTypes";

export default function sleepHistoryReducer(
  state: { sleepHistory?: ISession[] } = {},
  action: types.sleepHistoryActionTypes,
) {
  switch (action.type) {
    case types.FETCH_SLEEP_HISTORY_SUCCESS:
      return {
        ...state,
        sleepHistory: action.payload,
      };
    case types.FETCH_SLEEP_HISTOR_BY_ID_SUCCESS:
      if (state.sleepHistory) {
        const found = state.sleepHistory.find(
          (item: ISession) => item.id === action.payload.id,
        );
        if (found) {
          return {
            ...state,
            sleepHistory: state.sleepHistory.map((item: ISession) =>
              item.id === action.payload.id ? action.payload : item,
            ),
          };
        }
        return {
          ...state,
          sleepHistory: [...state.sleepHistory, action.payload],
        };
      }
      return {
        ...state,
        sleepHistory: [action.payload],
      };

    case types.CLEAR_STATE:
      return {
        sleepHistory: undefined,
      };
    default:
      return state;
  }
}
