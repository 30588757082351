import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useCallback, useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

import { CheckMarkIcon, CrossIcon } from "components/icons";
import SMButtonNew from "components/SMButton/SMButtonNew";
import SMConfirmDialogNoLabel from "components/SMDialogs/SMConfirmDialogNoLabel";
import { IZone } from "interfaces/zone.interface";
import { ErrorMessage, StyledP } from "styled/StylesStyled";
import { flatten, makeZoneTree, searchTree } from "utils/zone.util";

import TreeBranchWithCheckbox from "./TreeBranchWithCheckbox";

const StyledDivWrapper = styled("div")(() => {
  const theme = useTheme();
  return {
    background: theme.palette.neutral[1],
    padding: 0,
    margin: 0,
  };
});

const TextFieldStyled = styled(TextField)(() => {
  const theme = useTheme();
  return {
    borderRadius: 4,
    border: `1px solid ${theme.palette.neutral[5]}`,
    width: "100%",
    padding: "14px 19px",
    marginBottom: 8,
    "& input": {
      padding: 0,
    },
  };
});

const SearchIconStyled = styled(SearchIcon)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.main[70],
  };
});

const TreeWrapper = styled("div")(
  ({ treeHeight }: { treeHeight?: string }) => ({
    maxHeight: treeHeight || "calc(100vh - 325px)",
    float: "left",
    overflow: "auto",
    background: "#FFFFFF",
    padding: 0,
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: 4,
    width: "calc(100% - 4px)",
    margin: 8,
    marginLeft: 2,
  }),
);

const InlineButtonWrapperStyled = styled("div")(() => {
  const theme = useTheme();
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    float: "right",
    marginRight: 2,
    marginTop: 2,
    marginBottom: 8,
    "& button": {
      background: `${theme.palette.neutral[1]}`,
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
      borderRadius: 4,
      height: 32,
      width: 32,
      marginLeft: 6,
    },
  };
});

interface TreeViewWithCheckboxProps {
  textsave?: string;
  onSave?: (selected: IZone[]) => void;
  defaultValues?: IZone[];
  placeholder?: string;
  items: IZone[];
  showSearchbox?: boolean;
  showActionButtons?: boolean;
  onChange?: (selected: IZone[]) => void;
  inheritSelected?: boolean;
  showInlineButtons?: boolean;
  errorMessage?: string;
  onResetTree?: () => void;
  treeHeight?: string;
  currentUserZones?: string[];
}
const TreeViewWithCheckbox = ({
  textsave = "Save",
  onSave,
  defaultValues,
  placeholder,
  items,
  showSearchbox = true,
  showActionButtons = true,
  inheritSelected = false, // inherit parent checked prop to child,
  showInlineButtons = false,
  errorMessage = "",
  onChange,
  onResetTree,
  treeHeight,
  currentUserZones,
}: TreeViewWithCheckboxProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<IZone[]>(defaultValues || []);
  const [inlineButtons, setInlineButtons] =
    useState<boolean>(showInlineButtons);

  const [showConfirmPopup, setsShowConfirmPopup] = useState<boolean>(false);
  const [checkedParentNodes, setCheckedParentNodes] = useState<IZone[]>(
    defaultValues || [],
  );
  const [search, setSearch] = useState<string>("");
  const [treeNodes, setTreeNodes] = useState<IZone[]>([]);

  useEffect(() => {
    setInlineButtons(showInlineButtons);
  }, [showInlineButtons]);

  useEffect(() => {
    setCheckedParentNodes(defaultValues || []);
  }, [defaultValues]);

  const blockSelection = useCallback(
    (node: IZone) => {
      const nodePath = node.path.split(".");
      for (let i = 0; i < checkedParentNodes.length; i += 1) {
        if (checkedParentNodes[i].id === node.id) {
          // clicked on parent so just allow it
          return false;
        }
        if (nodePath.indexOf(checkedParentNodes[i].id) !== -1) {
          // parent checked
          return true;
        }
      }
      return false;
    },
    [checkedParentNodes],
  );

  const selectedNodeAndChildren = useCallback(
    (node: IZone, checked: boolean, currentSelected?: IZone[]) => {
      if (inheritSelected && blockSelection(node)) return selected;

      const allNode: IZone[] = flatten(node) || [];
      const currentsSelectedIds = allNode.map((item) => item.id);
      const current = currentSelected ? [...currentSelected] : [...selected];
      let array: IZone[] = [];
      if (checked) {
        array = [...current, ...allNode];
      } else {
        array = current.filter(
          (value) => !currentsSelectedIds.includes(value.id),
        );
      }
      const uniqueIds = new Set();
      const unique = array.filter((element) => {
        const isDuplicate = uniqueIds.has(element.id);
        uniqueIds.add(element.id);
        return !isDuplicate;
      });
      return Array.from(unique);
    },
    [blockSelection, inheritSelected, selected],
  );

  useEffect(() => {
    if (!inheritSelected || checkedParentNodes.length === 0) return;

    let itemsToCheck: IZone[] = [...checkedParentNodes];
    checkedParentNodes.forEach((val) => {
      const nodesWithChilds = searchTree(treeNodes[0], val.id);
      if (nodesWithChilds === null) return;

      itemsToCheck = selectedNodeAndChildren(
        nodesWithChilds,
        true,
        itemsToCheck,
      );
    });
    setSelected((prev) =>
      JSON.stringify(prev) !== JSON.stringify(itemsToCheck)
        ? itemsToCheck
        : prev,
    );
  }, [treeNodes, checkedParentNodes, inheritSelected, selectedNodeAndChildren]);

  useEffect(() => setTreeNodes(makeZoneTree(items)), [items]);

  function updateCheckedParentList(node: IZone, checked: boolean) {
    let currentSelected: IZone[] = [...checkedParentNodes];
    if (checked) {
      currentSelected = currentSelected.filter(
        (item: IZone) => item.path.split(".").indexOf(node.id) === -1,
      );
      currentSelected.push(node);
    } else {
      currentSelected = currentSelected.filter(
        (item: IZone) => item.id !== node.id,
      );
    }
    return currentSelected;
  }

  function onChecked(checked: boolean, node: IZone) {
    setSelected(selectedNodeAndChildren(node, checked));

    if (!inheritSelected) return;

    const updated = updateCheckedParentList(node, checked);
    setCheckedParentNodes(updated);

    if (onChange) onChange(updated);
  }

  const isUserAllowed = useCallback(
    (node: IZone) => {
      if (!currentUserZones) return true;

      const nodePaths = node.path.split(".");
      return currentUserZones.some((item) => nodePaths.includes(item));
    },
    [currentUserZones],
  );

  const isDisableNode = (node: IZone) =>
    (inheritSelected && blockSelection(node)) || !isUserAllowed(node);

  return (
    <StyledDivWrapper>
      {showSearchbox && (
        <TextFieldStyled
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="standard"
          autoComplete="off"
          placeholder={placeholder}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIconStyled />
              </InputAdornment>
            ),
          }}
        />
      )}

      {!treeNodes?.length && <StyledP>{t("no_results_found")}</StyledP>}
      <TreeWrapper treeHeight={treeHeight}>
        {treeNodes.map((item: IZone) => (
          <TreeBranchWithCheckbox
            key={item.id}
            item={item}
            level={0}
            onChecked={onChecked}
            levelPadding={32}
            selected={selected.map((node) => node.id)}
            search={search}
            isDisableNode={isDisableNode}
            disabled_tooltip_message={t("parent_zone_already_selected")}
            no_permission_tooltip_message={t(
              "no_permission_to_change_this_zone",
            )}
          />
        ))}
      </TreeWrapper>
      {errorMessage && (
        <ErrorMessage sx={{ position: "relative", float: "left" }}>
          {errorMessage}
        </ErrorMessage>
      )}
      {showActionButtons && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ float: "right", marginTop: "22px" }}
        >
          <SMButtonNew
            text={textsave}
            onClick={() => {
              if (onSave) {
                onSave(selected);
              }
            }}
            submit
          />
        </Stack>
      )}

      {inlineButtons && !errorMessage && (
        <InlineButtonWrapperStyled>
          <IconButton
            aria-label="ok"
            onClick={() => {
              if (onSave) {
                setInlineButtons(false);
                onSave(selected);
              }
            }}
          >
            <CheckMarkIcon />
          </IconButton>
          <IconButton aria-label="reset" onClick={onResetTree}>
            <CrossIcon />
          </IconButton>
        </InlineButtonWrapperStyled>
      )}

      <SMConfirmDialogNoLabel
        title={t("you_have_no_longer_access_to_this_employee_title")}
        dialogDesc={`${t("you_have_no_longer_access_to_this_employee_text")}`}
        onDelete={() => {
          if (onSave) onSave(checkedParentNodes);
        }}
        buttonOk={t("ok")}
        buttonCancel={t("Cancel")}
        okButtonBg="green"
        open={showConfirmPopup}
        setOpen={setsShowConfirmPopup}
        onCancel={() => {
          if (onResetTree) onResetTree();
        }}
      />
    </StyledDivWrapper>
  );
};
export default TreeViewWithCheckbox;
